import React, { useState, useEffect } from "react";
import Menu from "./Menu";
import "./../../pages/admin/adminMenu.css";

const Orientation = ({ user, open, setOpen, transition, setTransition }) => {
  // Window Size State
  const [size, setSize] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  // Animation State
  const [menuAnimation, setMenuAnimation] = useState(null);

  // Automatically adjust window size & close menu on resize
  const autoAdjust = () => {
    const currentWindow = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    setSize({
      height: window.innerHeight,
      width: window.innerWidth,
    });
    if (
      currentWindow.width !== size.width ||
      currentWindow.height !== size.height
    ) {
      setMenuAnimation(null);
      setTransition(false);
      setOpen(false);
    }
  };

  // Handle animation sequence
  const handleAnimation = () => {
    if (!open) {
      setMenuAnimation("menu-animation");
      setOpen(true);
    } else {
      setMenuAnimation("menu-animation-after");
      const closeMenu = setTimeout(() => {
        setOpen(false);
        setMenuAnimation(null);
        setTransition(false);
      }, 200);
      return () => clearTimeout(closeMenu);
    }
  };

  // Check for screen rotation / resize
  useEffect(() => {
    window.addEventListener("resize", autoAdjust);
    return () => window.removeEventListener("resize", autoAdjust);
  });

  // Close menu on click
  useEffect(() => {
    window.addEventListener("click", handleAnimation);
    return () => window.removeEventListener("click", handleAnimation);
  });

  // cleanup
  useEffect(() => {
    return () => {
      setMenuAnimation(null);
      setTransition(false);
      setOpen(false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleAnimation();
    // eslint-disable-next-line
  }, [transition]);

  return <>{open ? <Menu user={user} menuAnimation={menuAnimation} /> : ""}</>;
};

export default Orientation;
