import React, { useReducer } from "react";
import ReleaseContext from "./ReleaseContext";
import releaseReducer from "./releaseReducer";
import {
  GET_RELEASES,
  ADD_RELEASE,
  RELEASE_ERROR,
  GET_RELEASE,
  CLEAR_CURRENT,
} from "./releaseTypes";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";

const ReleaseState = (props) => {
  const initalState = {
    releases: [],
    error: null,
    current: null,
    newest: [],
    loading: true,
  };

  const [state, dispatch] = useReducer(releaseReducer, initalState);

  // Get Releases
  const getReleases = async (query = null) => {
    try {
      setAuthToken(localStorage.token);

      const res = await axios.get(
        `/api/v2/releases${query ? `?query=${query}` : ""}`,
      );
      dispatch({ type: GET_RELEASES, payload: res.data.data });
    } catch (err) {
      let message;
      if (err.response) message = err.response.data.error;
      else message = err.msg;
      dispatch({ type: RELEASE_ERROR, payload: message });
    }
  };

  // Get Releases
  const getLatestRelease = async () => {
    try {
      setAuthToken(localStorage.token);

      const res = await axios.post(`/api/v2/releases/latest`);

      localStorage.app_version = res.data.data.version;

      dispatch({ type: GET_RELEASE, payload: res.data.data });
    } catch (err) {
      let message;
      if (err.response) message = err.response.data.error;
      else message = err.msg;
      dispatch({ type: RELEASE_ERROR, payload: message });
    }
  };

  // Add Release
  const addRelease = async (release) => {
    setAuthToken(localStorage.token);
    try {
      const res = await axios.post(`/api/v2/releases`, release);

      dispatch({ type: ADD_RELEASE, payload: res.data.data });
    } catch (err) {
      if (err.response)
        dispatch({ type: RELEASE_ERROR, payload: err.response.data.error });
      else dispatch({ type: RELEASE_ERROR, payload: err.msg });
    }
  };

  // Clear current
  const clearCurrent = () => {
    try {
      dispatch({ type: CLEAR_CURRENT });
    } catch (error) {
      dispatch({ type: RELEASE_ERROR, payload: error.msg });
    }
  };

  return (
    <ReleaseContext.Provider
      value={{
        releases: state.releases,
        error: state.error,
        loading: state.loading,
        current: state.current,
        newest: state.newest,
        getReleases,
        addRelease,
        getLatestRelease,
        clearCurrent,
      }}
    >
      {props.children}
    </ReleaseContext.Provider>
  );
};

export default ReleaseState;
