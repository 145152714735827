export default (dimensions = null, setDimensions = null) => {
  if (typeof dimensions !== "object" || typeof setDimensions !== "function")
    return 1;
  const currentScreen = {
    width: window.screen.width,
    height: window.screen.height,
  };
  if (
    currentScreen.width !== Number(dimensions.yAxis) ||
    currentScreen.height !== Number(dimensions.xAxis)
  ) {
    let portrait = window.screen.height > window.screen.width ? true : false;
    if (portrait) {
      let yAxis = window.screen.height;
      if (yAxis > 1920) {
        setDimensions({ xAxis: "1080", yAxis: "1920" });
      } else {
        setDimensions({ xAxis: "720", yAxis: "1280" });
      }
    } else {
      let xAxis = window.screen.width;
      if (xAxis > 1920) {
        setDimensions({ xAxis: "1080", yAxis: "1920" });
      } else {
        setDimensions({ xAxis: "720", yAxis: "1280" });
      }
    }
  }
  return 0;
};
