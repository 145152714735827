export default (roundNumber) => {
  try {
    const mediaContent = require(`../components/screen-page/media/2K/Rounds-0${roundNumber}.png`);
    return mediaContent;
  } catch (e) {
    console.error(
      `${e}: There was a problem loading the image, please refresh your screen.`
    );
    return 0;
  }
};
