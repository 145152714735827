import React, { useContext, useState, useEffect } from "react";
import AlertContext from "../../../context/alert/AlertContext";
import LocationContext from "../../../context/location/LocationContext";
import LockButton from "../../common/LockButton";
import BackButton from "../../common/BackButton";
import Spinner from "../../layout/Spinner";

const LocationSchedule = ({ match }) => {
  const alertContext = useContext(AlertContext);
  const locationContext = useContext(LocationContext);
  const { setAlert } = alertContext;
  const { current, getLocation, updateLocation, loading } = locationContext;

  const [updatedLocation, setUpdatedLocation] = useState(null);
  const [locked, setLocked] = useState(true);
  const [schedule, setSchedule] = useState([12, 12, 12, 12, 12, 12, 12]);
  const regex = /\b([5-9]|1[0-2])\b/;

  const url = match.url.split("/");

  const handleChange = (e) => {
    const valid = e.target.value.toString();
    if (regex.test(valid)) {
      let updatedSchedule = schedule;
      updatedSchedule[Number(e.target.name)] = Number(e.target.value);
      setSchedule([...updatedSchedule]);
    }
  };

  const handleSubmit = () => {
    let updated = current;
    updated.schedule = schedule;
    updateLocation(updated);
    setAlert({
      message: `Updated ${current.name} Location Schedule`,
      type: "success",
    });
    setLocked(true);
  };

  useEffect(() => {
    getLocation(url[3]);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (current && current.schedule) {
      setSchedule([...current.schedule]);
    }
    setUpdatedLocation(current);
  }, [current]);

  return (
    <div style={{ fontFamily: "Avenir Medium" }}>
      {!loading && updatedLocation ? (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h2>Schedule Controls</h2>
            <BackButton url={match.url} />
          </div>
          <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <LockButton locked={locked} setLocked={setLocked} />
            <button
              className="btn btn-success"
              style={{ marginLeft: "1%" }}
              onClick={handleSubmit}
              disabled={locked}
            >
              Update
            </button>
          </div>
          <p style={{ marginLeft: "5px", marginBottom: "30px" }}>
            * Set the time when a workout will update to the next day. Adjust
            this setting to align with your locations staffing. *
          </p>

          <p style={{ marginLeft: "5px", marginBottom: "30px" }}>
            <b style={{ textDecoration: "underline" }}>5PM-12AM ONLY</b> 12AM
            DEFAULT
          </p>
          <>
            <div
              style={{
                border: "3px solid black",
                display: "inline-block",
                padding: "10px",
                borderRadius: "10px",
                paddingTop: "15px",
                backgroundColor: "#343A40",
                color: "white",
                margin: "10px 0px 50px 0px",
              }}
            >
              <h6>Location: {current && current.name}</h6>
              <h6>9Round ID: {current && current.locationIdentifier}</h6>
              <h6>TimeZone: {current && current.timeZone}</h6>
            </div>
          </>
          <form
            onSubmit={handleSubmit}
            className="row row-cols-lg-auto g-3 align-items-center table-dark p-4"
            style={{
              borderRadius: "10px",
              margin: "0px",
            }}
          >
            <div className="form-group">
              <label
                htmlFor="sunday"
                style={{ textDecoration: "underline", marginRight: "2%" }}
              >
                Sunday
              </label>{" "}
              <input
                className="form-control-sm"
                type="number"
                name="0"
                onChange={handleChange}
                disabled={locked}
                value={schedule[0] || ""}
                style={
                  locked
                    ? { color: "white", fontWeight: "bold" }
                    : { color: "black", fontWeight: "bold" }
                }
              ></input>
            </div>
            <div className="form-group row-1">
              <label
                htmlFor="monday"
                style={{ textDecoration: "underline", marginRight: "2%" }}
              >
                Monday
              </label>
              <input
                className="form-control-sm"
                type="number"
                name="1"
                onChange={handleChange}
                disabled={locked}
                value={schedule[1] || ""}
                style={
                  locked
                    ? { color: "white", fontWeight: "bold" }
                    : { color: "black", fontWeight: "bold" }
                }
              ></input>
            </div>
            <div className="form-group row-1">
              <label
                htmlFor="tuesday"
                style={{ textDecoration: "underline", marginRight: "2%" }}
              >
                Tuesday
              </label>

              <input
                className="form-control-sm"
                type="number"
                name="2"
                onChange={handleChange}
                disabled={locked}
                value={schedule[2] || ""}
                style={
                  locked
                    ? { color: "white", fontWeight: "bold" }
                    : { color: "black", fontWeight: "bold" }
                }
              ></input>
            </div>
            <div className="form-group row-1">
              <label
                htmlFor="wednesday"
                style={{ textDecoration: "underline", marginRight: "2%" }}
              >
                Wednesday
              </label>

              <input
                className="form-control-sm"
                type="number"
                name="3"
                onChange={handleChange}
                disabled={locked}
                value={schedule[3] || ""}
                style={
                  locked
                    ? { color: "white", fontWeight: "bold" }
                    : { color: "black", fontWeight: "bold" }
                }
              ></input>
            </div>
            <div className="form-group row-1">
              <label
                htmlFor="thursday"
                style={{ textDecoration: "underline", marginRight: "2%" }}
              >
                Thursday
              </label>

              <input
                className="form-control-sm"
                type="number"
                name="4"
                onChange={handleChange}
                disabled={locked}
                value={schedule[4] || ""}
                style={
                  locked
                    ? { color: "white", fontWeight: "bold" }
                    : { color: "black", fontWeight: "bold" }
                }
              ></input>
            </div>
            <div className="form-group row-1">
              <label
                htmlFor="friday"
                style={{ textDecoration: "underline", marginRight: "2%" }}
              >
                Friday
              </label>

              <input
                className="form-control-sm"
                type="number"
                name="5"
                onChange={handleChange}
                disabled={locked}
                value={schedule[5] || ""}
                style={
                  locked
                    ? { color: "white", fontWeight: "bold" }
                    : { color: "black", fontWeight: "bold" }
                }
              ></input>
            </div>
            <div className="form-group row-1">
              <label
                htmlFor="saturday"
                style={{ textDecoration: "underline", marginRight: "2%" }}
              >
                Saturday
              </label>

              <input
                className="form-control-sm"
                type="number"
                name="6"
                onChange={handleChange}
                disabled={locked}
                value={schedule[6] || ""}
                style={
                  locked
                    ? { color: "white", fontWeight: "bold" }
                    : { color: "black", fontWeight: "bold" }
                }
              ></input>
            </div>
            {current && current.schedule.toString() !== schedule.toString() && (
              <p
                className="alert alert-dark"
                role="alert"
                style={{ padding: "10px", marginTop: "20px" }}
              >
                <b>You have unsaved changes!</b> Please click the Update button
                to save your changes.
              </p>
            )}
          </form>
          <br />
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default LocationSchedule;
