export const UPDATE_DWS_TIME = "UPDATE_DWS_TIME";
export const UPDATE_COLOR = "UPDATE_COLOR";
export const UPDATE_PLAYCARD = "UPDATE_PLAYCARD";
export const UPDATE_EXERCISE = "UPDATE_EXERCISE";
export const UPDATE_THIRTY_SECOND = "UPDATE_THIRTY_SECOND";
export const UPDATE_THREE_MINUTE = "UPDATE_THREE_MINUTE";
export const UPDATE_LICENSE_KEY = "UPDATE_LICENSE_KEY";
export const UPDATE_ITERATION = "UPDATE_ITERATION";
export const UPDATE_COUNTDOWN = "UPDATE_COUNTDOWN";
export const UPDATE_TIME = "UPDATE_TIME";
export const CLEAR_TIMER = "CLEAR_TIMER";
