import React, { useState, useEffect, useContext } from "react";
import ReleaseContext from "../../../context/release/ReleaseContext";
import { setVersion, updateVersion } from "../../../utils/releaseControls";
import "./styles.css";

const Notes = (props) => {
  //#region State & Context Management
  const { subComponent } = props;

  const releaseContext = useContext(ReleaseContext);
  const { getReleases, newest, loading } = releaseContext;

  const [releaseNotes, setReleaseNotes] = useState([]);

  const [card, setCard] = useState(0);

  const [expand, setExpand] = useState("");

  //#endregion

  //#region Lifecycle Methods

  useEffect(() => {
    if (getReleases) getReleases();
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if (newest && !subComponent) setReleaseNotes(newest);
    if (newest && subComponent) {
      setReleaseNotes(newest.slice(0, 1));
      handleVersion();
    }
    // eslint-disable-next-line
  }, [newest, loading]);

  //#endregion

  //#region Handlers

  const handleClick = (cardNo) => {
    setCard(cardNo);
    if (expand === "" || card !== cardNo) {
      handleExpand();
      return 0;
    } else if (cardNo === card) {
      handleRetract("");
    }
  };

  const handleExpand = () => {
    setExpand(" card-expand-before");
    const openCard = setTimeout(() => {
      setExpand(" card-expand");
    }, 150);
    return () => clearTimeout(openCard);
  };

  const handleRetract = () => {
    setExpand(" card-expand-after");
    const closeCard = setTimeout(() => {
      setExpand("");
    }, 150);
    return () => clearTimeout(closeCard);
  };

  const handleVersion = () => {
    const storedVersion = localStorage.getItem("app_version");
    const releaseVersion = newest && newest[0] && newest[0].version;
    if (!storedVersion && releaseVersion) {
      setVersion(releaseVersion);
    } else if (releaseVersion) {
      updateVersion(releaseVersion, storedVersion);
    }
  };

  //#endregion

  return (
    <>
      {subComponent && newest && newest.length > 0 && (
        <h6
          style={{
            textDecoration: "underline",
            marginBottom: "1.5rem",
            marginTop: "1rem",
          }}
        >
          Latest Release Notes
        </h6>
      )}
      <div
        className={
          !subComponent
            ? "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-5 border-bottom"
            : ""
        }
      >
        <h2>{!subComponent && "Release Notes"}</h2>
      </div>
      {newest && newest.length > 0 && releaseNotes && (
        <div>
          {releaseNotes.map((note, i) => (
            <div
              key={note._id}
              title={
                card === i && expand !== ""
                  ? "Click to close"
                  : "Click to expand"
              }
              className={
                card === i && expand !== ""
                  ? `card-v${expand} bg-light`
                  : "card-v bg-light"
              }
              onClick={() => handleClick(i)}
            >
              <div>
                <span className="card-title">{note.title}</span>
              </div>
              <div>
                <span className="card-version">
                  <i>
                    {note.type.toUpperCase()} {note.version}
                  </i>
                </span>
              </div>
              <div className="card-details">
                <b className="card-description">Description</b>
                <b>: </b>
                <p className="card-desc-details">
                  <i>{note.description}</i>
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Notes;
