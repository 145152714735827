import React, { useContext } from "react";
import AuthContext from "../../context/auth/AuthContext";

function LoginSSO({ match, history }) {
  const authContext = useContext(AuthContext);
  const { loginSSO } = authContext;

  loginSSO(match.params.token);
  history.push("/admin/dashboard");

  return <div></div>;
}

export default LoginSSO;
