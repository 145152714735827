/**
 * @function getNewestReleases
 * @param {Object[]} releases - Releases from state/context (unsorted)
 * @param {String} releases[].version - The version number of the release
 * @param {String} releases[].createdAt - The date the release was created
 * @param {Number} amount - Amount of sorted releases to return
 * @returns {Object[]} Returns newest releases
 * @description Sort (param:releases) array and then return (param:amount) from the array
 */
export const getNewestReleases = (releases, amount) => {
  if (releases && releases.length > 1) {
    const newestVersions = releases.sort((a, b) =>
      b.version > a.version
        ? 1
        : b.version === a.version
        ? b.createdAt > a.createdAt
          ? 1
          : -1
        : -1,
    );
    return newestVersions.slice(0, Number(amount));
  } else {
    return releases;
  }
};

/**
 * @function updateVersion
 * @param {String} releaseVersion - Most up to date release in state
 * @param {String} storedVersion - The release currently stored in local storage
 * @description Compares app version in local storage to the newest release in state then updates local storage.
 */
export const updateVersion = (releaseVersion, storedVersion) => {
  const versions = [
    storedVersion,
    releaseVersion,
    global.config.product.app.version,
  ];
  const newestVersions = versions.sort((a, b) => (b > a ? 1 : -1));
  let newestVersion = newestVersions[0];
  /**  If global version is greater than newest release in state: use release state variable */
  if (
    newestVersion === global.config.product.app.version &&
    newestVersion !== releaseVersion
  ) {
    newestVersion = releaseVersion;
  }
  localStorage.setItem("app_version", newestVersion);
};

/**
 * @function setVersion
 * @param {String} releaseVersion
 * @description Sets local storage to the newest release in state
 */
export const setVersion = releaseVersion => {
  localStorage.setItem("app_version", releaseVersion);
};
