import React, { useState } from "react";
import LoggerItem from "./LoggerItem";

const LoggerListItem = ({ log }) => {
  const [show, setShow] = useState(false);
  const [logDetails, setLogDetails] = useState([]);

  const handleClick = (dataLog) => {
    setLogDetails(dataLog);
    setShow(true);
  };
  return (
    <tr>
      <td>{log.date}</td>
      <td>{log.model}</td>
      <td>{log.action}</td>
      <td>{log.modifiedBy}</td>
      <td>{log.userId}</td>
      <td>
        {!show ? (
          <div className="button-group" onClick={() => handleClick(log.data)}>
            <span
              className="btn btn-outline-light"
              style={{ backgroundColor: "#696969" }}
            >
              <i className="fas fa-file-alt"></i>
            </span>
          </div>
        ) : (
          <div>
            <span className="btn btn-outline-dark">
              <i className="far fa-file"></i>
            </span>
            <LoggerItem
              show={show}
              setShow={setShow}
              logDetails={logDetails}
              uid={log.userId}
            />
          </div>
        )}
      </td>
    </tr>
  );
};

export default LoggerListItem;
