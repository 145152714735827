import React, { useContext, useEffect } from "react";
import ScreenContext from "../../context/screen/ScreenContext";
import "./screen.css";
import ScreenAuthentication from "../../components/screen-page/new-workout/ScreenAuthentication";
import Spinner from "../../components/layout/Spinner";
import ScreenLinkState from "../../context/screenLink/ScreenLinkState";
import ScreenError from "../../components/screen-page/new-workout/ScreenError";
import FlexDisplay from "../../components/screen-page/new-workout/FlexDisplay";

const FlexScreen = () => {
  const screenContext = useContext(ScreenContext);
  const {
    loading,
    error,
    getScreenByToken,
    screen,
    isScreenAuth,
    setIsScreenAuth,
    updateScreen,
    sendScreenStatus,
  } = screenContext;

  const getScreenByTokenHandler = () => {
    getScreenByToken();
  };

  useEffect(() => {
    if (!screen) {
      getScreenByTokenHandler();
    }

    if (!localStorage["screen_auth_token"]) {
      setIsScreenAuth(false);
    } else if (!isScreenAuth) {
      setIsScreenAuth(true);
    }

    if (screen && screen.hardRefreshFlag) {
      updateScreen(screen.id, { hardRefreshFlag: false, ...screen }).then(
        () => {
          window.location.reload();
        },
      );
    }
    // eslint-disable-next-line
  }, [loading, error, screen, isScreenAuth]);

  return (
    <ScreenLinkState>
      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <>
            {screen && isScreenAuth ? (
              screen.location && screen.round === 10 ? (
                <div className="screen-container">
                  <FlexDisplay
                    screen={screen}
                    sendScreenStatus={sendScreenStatus}
                  />
                </div>
              ) : screen.location && screen.location.isEnabled ? (
                <ScreenAuthentication setIsScreenAuth={setIsScreenAuth} />
              ) : (
                <ScreenError
                  isScreenAuth={isScreenAuth}
                  checkFunction={getScreenByTokenHandler}
                />
              )
            ) : !isScreenAuth ? (
              <ScreenAuthentication setIsScreenAuth={setIsScreenAuth} />
            ) : (
              <ScreenError
                isScreenAuth={isScreenAuth}
                checkFunction={getScreenByTokenHandler}
              />
            )}
          </>
        )}
      </div>
    </ScreenLinkState>
  );
};

export default FlexScreen;
