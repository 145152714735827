import React from "react";
import Modal, {
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "../../common/Modal";

const AddUserLocation = ({
  show,
  handleShow,
  handleAddLocation,
  locationInput,
  handleInput,
}) => {
  const locationDecider = show && show.locations && show.locations.length > 0;

  const handleChange = (event) => {
    handleInput({ ...locationInput, [event.target.name]: event.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAddLocation(show.user, locationInput);
    handleShow();
  };

  return (
    <>
      {show && (
        <Modal show={show} onHide={handleShow} overlay="modal-overlay-default">
          <ModalHeader>
            <ModalTitle>
              User:{" "}
              <span>
                {show.user.firstName || ""} {show.user.lastName || ""}
              </span>
            </ModalTitle>
          </ModalHeader>
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <div className="form-group">
                <label htmlFor="location">
                  Available Locations:{" "}
                  {locationDecider ? show.locations.length : "0"}
                </label>
                <select
                  name="location"
                  className="form-control"
                  value={locationInput.location || ""}
                  onChange={handleChange}
                  required={true}
                >
                  <option>
                    {locationDecider
                      ? locationInput.name
                        ? locationInput.name
                        : ""
                      : "No Locations Available"}
                  </option>
                  {show.locations
                    .filter((location) => location.name !== locationInput.name)
                    .map((location, i) => (
                      <option key={i} value={location.id}>
                        {location.name}
                      </option>
                    ))}
                </select>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                className="btn btn-primary"
                title="Submit"
                type="submit"
                disabled={locationDecider ? false : true}
              >
                Add Location
              </button>
            </ModalFooter>
          </form>
        </Modal>
      )}
    </>
  );
};

export default AddUserLocation;
