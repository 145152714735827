import React, { useEffect, useContext } from "react";
import Spinner from "../../layout/Spinner";
import ScreenContext from "../../../context/screen/ScreenContext";
import FlexVideo from "./FlexVideo";
import "./FlexDisplay.css";

const FlexDisplay = () => {
  const flexVideo =
    "https://player.vimeo.com/progressive_redirect/playback/1010004557/rendition/720p/file.mp4?loc=external&signature=06a57cd2464fe4cf1fd8a950e810520e00241bcb72bfb828fa56518cc382eb99";
  const screenContext = useContext(ScreenContext);

  const { loading, screen, sendScreenStatus } = screenContext;

  useEffect(() => {
    // set a 60 second timer
    const interval = setInterval(() => {
      // send screen status update & check if workout should update
      if (screen) sendScreenStatus(screen.id);
    }, 60 * 1000);

    return () => clearInterval(interval);

    // eslint-disable-next-line
  });

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="FlexDisplay">
          <FlexVideo flexVideo={flexVideo} />
        </div>
      )}
    </>
  );
};

export default FlexDisplay;
