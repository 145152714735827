export const convertFormat = (millis) => {
  let minutes = Math.floor(millis / 60000);
  let seconds = ((millis % 60000) / 1000).toFixed(0);
  return seconds === 60
    ? minutes + 1 + "00"
    : minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
};

export const convert = (milliseconds) => {
  const seconds = Math.floor(milliseconds / 1000);
  return seconds;
};

export const handleStart = (
  dwsTime,
  setCountDown,
  initiateGreen,
  initiateYellow,
  initiateRed,
  clearTimer,
) => {
  const dateThen = dwsTime;
  const dateNow = Date.now();
  const result = Number(dateNow) - Number(dateThen);
  const timeResult = 210000 - result - 180000;

  const newResult = 150000 + timeResult;
  const convertedTime = convertFormat(newResult);
  const converted = convert(newResult);

  const comparative1 = 150000 - result;
  const comparative2 = 180000 - result;
  const comparative3 = 210000 - result;

  const timeCounter =
    210000 - result > 0 && 210000 - result < 30000
      ? convertFormat(comparative3)
      : "0:30";
  const timeCalc =
    210000 - result > 0 && 210000 - result < 30000 ? convert(comparative3) : 30;
  setCountDown({
    convertedFormat: convertedTime,
    converted: converted,
    thirtyFormat: timeCounter,
    thirty: timeCalc,
  });

  if (comparative1 > 0) {
    initiateGreen(comparative1);
  } else if (comparative2 > 0) {
    initiateYellow(comparative2);
  } else if (comparative3 > 0) {
    initiateRed(comparative3);
  } else if (comparative1 < 1) {
    initiateGreen(0);
  } else {
    clearTimer();
  }
};
