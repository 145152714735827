import React from "react";

const AndroidExercise = ({ videoLink, videos, androidBtn }) => {
  return (
    <>
      <video
        className={videos === 2 ? "Timer-transition-2" : "Timer-transition-1"}
        autoPlay={true}
        loop={true}
        poster={androidBtn}
        muted={true}
        playsInline={true}
      >
        <source src={videoLink} type="video/mp4" />
      </video>
    </>
  );
};

export default AndroidExercise;
