import React from "react";
import RoutineList from "../../components/screen-page/stage-workout/RoutineList";
import InstructionList from "../../components/screen-page/stage-workout/InstructionList";
import contentFilter from "../../utils/contentFilter";
import Spinner from "../../components/layout/Spinner";
import "./screen.css";

const Round = ({ roundNumber, isLrVideo, workout }) => {
  return (
    <div className="main">
      {workout && workout.routines && workout.routines.length > 0 ? (
        <div className="screen-container">
          <div className={`videos-${workout.routines.length}`}>
            <div className="image-container">
              <h1 className="screen-round">
                <img
                  src={contentFilter(roundNumber)}
                  style={{
                    maxWidth: "70%",
                    marginTop: "-5%",
                    marginBottom: "-5.5%",
                  }}
                  alt={`Round ${roundNumber}`}
                ></img>
              </h1>
            </div>
            <RoutineList
              routines={workout.routines}
              isLrVideo={isLrVideo}
            ></RoutineList>
            <InstructionList
              routines={workout.routines}
              contentHeader={workout.subHeader}
            ></InstructionList>
          </div>
        </div>
      ) : (
        <div style={{ marginTop: "1rem" }}>
          <Spinner />
          {workout && (
            <p className="alert alert-danger text-center">
              Please ensure your workout has atleast one routine
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default Round;
