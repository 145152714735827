import React from "react";
import PropTypes from "prop-types";

const Routine = ({ routine, isLrVideo }) => {
  const { videoLink, lrVideoLink } = routine;
  return (
    <div className="video-container">
      <video
        src={isLrVideo ? lrVideoLink : videoLink}
        autoPlay={true}
        loop={true}
        muted={true}
      ></video>
    </div>
  );
};

Routine.protoTypes = {
  routine: PropTypes.object.isRequired,
};

export default Routine;
