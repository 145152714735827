import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import LoggerSubItem from "./LoggerSubItem";

const LoggerItem = ({ show, setShow, logDetails, uid }) => {
  const [showSub, setShowSub] = useState(false);
  const [subItems, setSubItems] = useState(null);

  const handleClose = () => setShow(false);
  const handleClick = (items) => {
    setSubItems(items[1]);
    setShowSub(items[0].toUpperCase());
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>{!showSub ? "DATA" : showSub}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="text-break"
            style={{
              fontSize: "1rem",
            }}
          >
            {!showSub ? (
              Object.keys(logDetails).map((log, i) => (
                <span key={i} style={{ paddingBottom: "1%", display: "block" }}>
                  <b>{log.toString()}: </b>
                  {typeof logDetails[log] !== "object" ? (
                    logDetails[log].toString()
                  ) : (
                    <button
                      onClick={() => handleClick([log, logDetails[log]])}
                      className={"btn btn-outline-danger btn-sm"}
                      style={{ marginLeft: "0.5%", padding: "0% 1% 0% 1%" }}
                    >
                      {logDetails[log] && logDetails[log].length > 1
                        ? `${logDetails[log].length} ITEMS`
                        : logDetails[log] && logDetails[log].length === 0
                        ? "N/A"
                        : "VIEW"}
                    </button>
                  )}
                </span>
              ))
            ) : (
              <LoggerSubItem
                subItems={subItems}
                showSub={showSub}
                setShowSub={setShowSub}
                userId={uid}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LoggerItem;
