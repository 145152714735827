import {
  UPDATE_DWS_TIME,
  UPDATE_COLOR,
  UPDATE_COUNTDOWN,
  UPDATE_EXERCISE,
  UPDATE_ITERATION,
  UPDATE_LICENSE_KEY,
  UPDATE_PLAYCARD,
  UPDATE_THIRTY_SECOND,
  UPDATE_THREE_MINUTE,
  UPDATE_TIME,
  CLEAR_TIMER,
} from "./timerTypes";

export default (state, action) => {
  switch (action.type) {
    case UPDATE_DWS_TIME:
      return {
        ...state,
        dwsTime: action.payload,
      };
    case UPDATE_COLOR:
      return {
        ...state,
        color: action.payload,
      };
    case UPDATE_PLAYCARD:
      return {
        ...state,
        playcard: action.payload,
      };
    case UPDATE_EXERCISE:
      return {
        ...state,
        exercise: action.payload,
      };
    case UPDATE_THIRTY_SECOND:
      return {
        ...state,
        thirtySecond: action.payload,
      };
    case UPDATE_THREE_MINUTE:
      return {
        ...state,
        threeMinute: action.payload,
      };
    case UPDATE_LICENSE_KEY:
      return {
        ...state,
        licenseKey: action.payload,
      };
    case UPDATE_ITERATION:
      return {
        ...state,
        iteration: action.payload,
      };
    case UPDATE_COUNTDOWN:
      return {
        ...state,
        countDown: action.payload,
      };
    case UPDATE_TIME:
      return {
        ...state,
        time: action.payload,
      };
    case CLEAR_TIMER:
      return {
        ...state,
        dwsTime: null,
        color: null,
        playcard: [],
        exercise: null,
        threeMinute: false,
        thirtySecond: false,
        iteration: 0,
        countDown: {
          convertedFormat: "",
          converted: 0,
          thirtyFormat: "0:30",
          thirty: 30,
        },
        time: {
          previousTime: null,
          currentTime: null,
        },
      };
    default:
      return state;
  }
};
