import React from "react";

const LoggerSubItem = ({ subItems, showSub, setShowSub, userId }) => {
  const items = [];
  const handleClick = () => setShowSub(false);

  const populateData = () => {
    for (let key in subItems) {
      if (typeof subItems[key] !== "object") {
        items.push(subItems[key]);
      } else {
        Object.keys(subItems[key]).map((field) =>
          items.push([<b key={key}>{field}: </b>, `${subItems[key][field]}`])
        );
      }
    }
  };

  if (subItems) {
    populateData();
  } else {
    items.push(
      <b style={{ color: "red", paddingBottom: "5%" }}>
        MISSING {showSub} DATA
        {userId ? (
          <div style={{ color: "black", paddingTop: "5%" }}>
            USER ID: {userId}
          </div>
        ) : (
          ""
        )}
      </b>
    );
  }

  return (
    <>
      {items.map((item, i) => (
        <span
          style={{
            paddingBottom: "1%",
            display: "block",
            fontSize: "1rem",
          }}
          key={i}
        >
          {item}
        </span>
      ))}
      <div style={{ paddingBottom: "5%", paddingTop: "5%" }}>
        <button
          onClick={() => handleClick()}
          className="btn btn-outline-danger btn-sm"
          style={{ width: "100%", textAlign: "center" }}
        >
          {" "}
          BACK
        </button>
      </div>
    </>
  );
};

export default LoggerSubItem;
