import React, { useReducer } from "react";
import AlertContext from "./AlertContext";
import alertReducer from "./alertReducer";
import { v4 as uuid } from "uuid";
import notificationTypes from "../../utils/notificationTypes";
import {
  SET_ALERT,
  REMOVE_ALERT,
  WIPE_ALERT,
  ADD_FLAG,
  REMOVE_FLAG,
  ALERT_ERROR,
} from "./alertTypes";

const AlertState = (props) => {
  const initialState = {
    message: "",
    border: "",
    type: null,
    alerts: [],
    flag: null,
  };

  const [state, dispatch] = useReducer(alertReducer, initialState);

  // Set an Alert to display
  const setAlert = (alert) => {
    try {
      dispatch({
        type: SET_ALERT,
        payload: {
          ...alert,
          border:
            alert.message !== ""
              ? notificationTypes[alert.type].notification.design.borderTop
              : "",
          notification: notificationTypes[alert.type].notification,
          id: uuid(),
        },
      });
    } catch (err) {
      dispatch({ type: ALERT_ERROR, payload: err.msg });
    }
  };

  // Remove an Alert from the array
  const removeAlert = (alert) => {
    try {
      dispatch({
        type: REMOVE_ALERT,
        payload: alert,
      });
    } catch (err) {
      dispatch({ type: ALERT_ERROR, payload: err.msg });
    }
  };

  // Empty the Alerts array
  const wipeAlert = () => {
    dispatch({
      type: WIPE_ALERT,
    });
  };

  // Set a flag for an Alert when adding new entries into db
  const setFlag = (validated, flagType) => {
    try {
      dispatch({
        type: ADD_FLAG,
        payload: {
          validated: validated,
          flagType: flagType,
        },
      });
    } catch (err) {
      dispatch({ type: ALERT_ERROR, payload: err.msg });
    }
  };

  // Remove the flag
  const removeFlag = () => {
    dispatch({
      type: REMOVE_FLAG,
    });
  };

  return (
    <AlertContext.Provider
      value={{
        message: state.message,
        border: state.border,
        type: state.type,
        alerts: state.alerts,
        flag: state.flag,
        setAlert,
        removeAlert,
        wipeAlert,
        setFlag,
        removeFlag,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};

export default AlertState;
