import React, { useContext, useEffect } from "react";
import AlertContext from "../../context/alert/AlertContext";
import Alert from "./Alert";
import { v4 as uuid } from "uuid";
import "./styles.css";

const AlertContainer = ({ location }) => {
  const alertContext = useContext(AlertContext);
  const { alerts, removeAlert, wipeAlert } = alertContext;

  // cleanup on unmount/route change
  useEffect(() => {
    wipeAlert();
    return () => {
      wipeAlert();
    };
    // eslint-disable-next-line
  }, [location]);

  return (
    <div className="alert-container">
      <ul className="alert-container-list" style={{ right: 0, bottom: -20 }}>
        {alerts.length > 0
          ? alerts.map((alert) => (
              <li className="btn-group mr-2" role="group" key={alert.id}>
                <Alert
                  notification={alert.notification}
                  message={" " + alert.message}
                  addBorder={alert.border}
                  closeAlert={removeAlert}
                  id={alert.id}
                  key={uuid}
                />
              </li>
            ))
          : ""}
      </ul>
    </div>
  );
};

export default AlertContainer;
