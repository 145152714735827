import React, { useContext, useEffect, useState } from "react";
import ScreenLinkContext from "../../../context/screenLink/ScreenLinkContext";
import generateAuthCode from "../../../utils/generateAuthCode";
import Footer from "../../layout/Footer";

const ScreenAuthentication = ({ setIsScreenAuth }) => {
  const screenLinkContext = useContext(ScreenLinkContext);
  const { loading, error, createScreenLink, getScreenLink, current } =
    screenLinkContext;

  const [authCode, setAuthCode] = useState(null);

  useEffect(() => {
    let code;
    if (!authCode) {
      code = generateAuthCode(6);
      setAuthCode(code);
      createScreenLink(code);
    }

    if (current && current.screen) {
      localStorage.setItem("screen_auth_token", current.screen.authToken);
      setIsScreenAuth(true);
    }

    // Update the auth code for security every hour
    const interval = setInterval(() => {
      if (!!current && !current.screen) {
        getScreenLink(current._id);
      }
    }, 15 * 1000);

    return () => clearInterval(interval);

    // eslint-disable-next-line
  }, [loading, error, current]);

  return (
    <>
      {current && (
        <div>
          <div className="center">
            <form className="form-signin">
              <img
                className="mb-4"
                src="/logo_black.png"
                alt=""
                width="150"
                height="150"
              />
              <h1 className="h3 mb-3 font-weight-bold">
                {global.config.product.app.name} Authorization
              </h1>
              <p>
                Please contact support to connect your screen with the
                authentication code below. <br />
                (code will update every hour)
              </p>
              <div className="screen-auth-code">{authCode}</div>

              <Footer />
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ScreenAuthentication;
