import {
  ROUND_ERROR,
  GET_ROUNDS,
  ADD_ROUND,
  DELETE_ROUND,
  UPDATE_ROUND,
  // SET_CURRENT,
  // CLEAR_CURRENT,
  // FILTER_ROUNDS,
  // CLEAR_FILTER,
  // CLEAR_ROUNDS,
} from "./roundTypes";
export default (state, action) => {
  switch (action.type) {
    case GET_ROUNDS:
      return {
        ...state,
        rounds: action.payload,
        loading: false,
      };
    case ROUND_ERROR:
      return { ...state, error: action.payload };
    case ADD_ROUND:
      return {
        ...state,
        loading: false,
        rounds: [action.payload, ...state.rounds],
      };
    case UPDATE_ROUND:
      return {
        ...state,
        rounds: state.rounds.map((round) =>
          round._id === action.payload._id ? action.payload : round
        ),
        loading: false,
      };
    case DELETE_ROUND:
      return {
        ...state,
        rounds: state.rounds.filter((round) => round._id !== action.payload),
        loading: false,
      };
    // case CLEAR_CONTACTS:
    //   return {
    //     ...state,
    //     contacts: null,
    //     filtered: null,
    //     error: null,
    //     current: null,
    //   };
    // case SET_CURRENT:
    //   return {
    //     ...state,
    //     current: action.payload,
    //   };
    // case CLEAR_CURRENT:
    //   return {
    //     ...state,
    //     current: null,
    //   };
    // case FILTER_CONTACTS:
    //   return {
    //     ...state,
    //     filtered: state.contacts.filter((contact) => {
    //       const regex = new RegExp(`${action.payload}`, "gi");
    //       return (
    //         contact.name.match(regex) ||
    //         contact.email.match(regex) ||
    //         contact.phone.match(regex)
    //       );
    //     }),
    //     loading: false,
    //   };
    // case CLEAR_FILTER:
    //   return {
    //     ...state,
    //     filtered: null,
    //   };
    default:
      return state;
  }
};
