import React, { useEffect, useState, useContext } from "react";
import LocationContext from "../../../context/location/LocationContext";
import LocationListItem from "../location/LocationListItem";
import notificationTypes from "../../../utils/notificationTypes";
import { checkScreenStatus } from "../../../utils/checkScreenStatus";
import AlertContext from "../../../context/alert/AlertContext";

const LocationStatus = ({ match, user, loadUser, loading, error }) => {
  //#region Context & State Management
  const locationContext = useContext(LocationContext);
  const alertContext = useContext(AlertContext);
  const { codes, getStatusCodes, clearStatusCodes, deleteLocation } =
    locationContext;
  const { setAlert } = alertContext;

  const { code } = notificationTypes;

  const [notification, setNotification] = useState(null);
  const [locationStatus, setLocationStatus] = useState([]);
  const [screenStatus, setScreenStatus] = useState([]);
  const [updating, setUpdating] = useState(true);
  //#endregion

  //#region Functions
  const getLocationStatus = (locationId) => {
    const statusCode = checkScreenStatus(locationId, locationStatus);
    if (statusCode === 0) return code.green;
    if (statusCode === 2) return code.red;
    return code.yellow;
  };

  const loadStatus = () => {
    user.locations.forEach((location) => {
      getStatusCodes(location.id);
    });
    setUpdating(false);
  };
  //#endregion

  //#region Handlers
  const handleNotification = () => {
    if (!screenStatus) return 0;
    const codeRed = screenStatus.find((codeColor) => codeColor === "red");
    if (codeRed) return setNotification(code.red);
    const codeYellow = screenStatus.find(
      (codeColor) => codeColor === "goldenrod"
    );
    if (codeYellow) return setNotification(code.yellow);
    return setNotification(code.green);
  };
  //#endregion

  //#region Lifecycle
  useEffect(() => {
    if (screenStatus.length < 1) loadStatus();
    // eslint-disable-next-line
  }, [loadUser]);

  useEffect(() => {
    setLocationStatus(codes);
    // eslint-disable-next-line
  }, [getStatusCodes]);

  useEffect(() => {
    handleNotification();
    // eslint-disable-next-line
  }, [screenStatus]);

  useEffect(() => {
    return () => clearStatusCodes();
    // eslint-disable-next-line
  }, []);
  //#endregion

  return (
    <>
      {!updating && (
        <>
          <h6>
            <p style={{ textDecoration: "underline" }}>Screen Status</p>
            {notification && (
              <small
                style={{
                  fontSize: "1rem",
                  color: notification.color,
                }}
              >
                {notification.message && notification.message}
              </small>
            )}
          </h6>
          <div className="table-responsive" style={{ marginTop: "1rem" }}>
            <table className="table table-striped table-sm">
              <thead>
                <tr>
                  <th>Connectivity</th>
                  <th>Name</th>
                  <th>9Round Number</th>
                  <th>Time Zone</th>
                  <th>Language</th>
                  <th>Active</th>
                  <th>Status</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {user.locations.map((location) => (
                  <LocationListItem
                    match={match}
                    key={location.id}
                    location={location}
                    deleteLocation={deleteLocation}
                    setAlert={setAlert}
                    user={user}
                    code={getLocationStatus(location.id)}
                    setStatus={setScreenStatus}
                    screenStatus={screenStatus}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default LocationStatus;
