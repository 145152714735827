import React from "react";
import Button from "./Button";

const LockButton = ({ locked, setLocked }) => {
  const onLockClick = (event) => {
    event.preventDefault();
    setLocked(!locked);
    event.currentTarget.blur();
  };

  return (
    <Button
      cssClasses="btn btn-secondary text-left"
      content={<i className={`fas fa-${locked ? "lock" : "unlock"}`}></i>}
      action={onLockClick}
    />
  );
};

export default LockButton;
