import React from "react";

const WorkoutSort = ({
  toggle,
  ordering,
  reorder,
  setOrdering,
  setPage,
  field,
}) => {
  const handleSort = (order, type) => {
    setPage(1);
    reorder(order, type);
    setOrdering({ [type]: order });
  };

  return (
    <div style={{ display: "inline-flex" }}>
      <i
        className={
          Object.values(ordering).toString() === "ascending" && toggle
            ? "fas fa-arrow-circle-up"
            : "fas fa-arrow-up"
        }
        onClick={() => handleSort("ascending", field)}
        type="button"
      ></i>{" "}
      <i
        className={
          Object.values(ordering).toString() === "descending" && toggle
            ? "fas fa-arrow-circle-down"
            : "fas fa-arrow-down"
        }
        type="button"
        onClick={() => handleSort("descending", field)}
      ></i>
    </div>
  );
};

export default WorkoutSort;
