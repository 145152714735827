module.exports = (
  content,
  page,
  perPage,
  setResults,
  setOrdering = null,
  col = null
) => {
  const saveOrder = col;
  if (content) {
    const idxLast = page * perPage;
    const idxFirst = idxLast - perPage;
    const currentContent = content.slice(idxFirst, idxLast);
    setResults(currentContent);
  }
  if (setOrdering && col) setOrdering(saveOrder);
};
