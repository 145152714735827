export const activeBreaks = {
  "Butt Kickers": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/BUTTKICKERS_ACTIVE_BREAK_LL_1.mp4?_t=1717615839",
    "en-usName": "Butt Kickers",
    esName: "Talones Atrás",
  },
  "Front Lunges": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/LUNGES_ACTIVE_BREAK_ED_2.mp4?_t=1717615585",
    "en-usName": "Front Lunges",
    esName: "Desplantes Frontales",
  },
  Pushups: {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/p_ups_active_break_jb%20(720p).mp4?_t=1717615732",
    "en-usName": "Push-Ups",
    esName: "Push-Ups",
  },
  "Jumping Jacks": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/jj_active_break_ed_1%20(720p).mp4?_t=1717615931",
    "en-usName": "Jumping Jacks",
    esName: "Jumping Jacks",
  },
  "Side Lunges": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/SIDE_LUNGES_ACTIVE_BREAK_LL.mp4?_t=1717615738",
    "en-usName": "Side Lunges",
    esName: "Sentadilla Lateral",
  },
  "High Plank Shoulder Taps": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/high_plank_shoulder_taps_active_breaks_ed%20(720p).mp4?_t=1717615579",
    "en-usName": "High Plank Shoulder Taps",
    esName: "Plancha Alta con Toque de Hombros",
  },
  "High Knees": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/high_knees_active_break_jb_1%20(720p).mp4?_t=1717616035",
    "en-usName": "High Knees",
    esName: "Rodillas Altas",
  },
  Squats: {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/SQUATS_ACTIVE_BREAK_ED_1.mp4?_t=1717616162",
    "en-usName": "Squats",
    esName: "Sentadilla",
  },
  Crunches: {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/crunches_active_break_ds_2%20(720p).mp4?_t=1717615834",
    "en-usName": "Crunches",
    esName: "Abdominales Crunches",
  },
  "Speed Skaters": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/speed_skaters_active_break_ed_v2_2%20(720p).mp4?_t=1717615848",
    "en-usName": "Speed Skaters",
    esName: "Patinadores Rápidos",
  },
  "Squat Kicks": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/SQUAT_KICKS_ACTIVE_BREAK_DS_1.mp4?_t=1717615914",
    "en-usName": "Squat Kicks",
    esName: "Sentadilla con Patada Frontal",
  },
  Burpees: {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/burpees_active_break_ed%20(720p).mp4?_t=1717615490",
    "en-usName": "Burpees",
    esName: "Burpees",
  },
  "Plank & Hold": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/low_plank_hold_active_break_ll_1%20(720p).mp4?_t=1717616169",
    "en-usName": "Plank & Hold",
    esName: "Plancha y Sostengo",
  },
  Surrenders: {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/SURRENDERS_ACTIVE_BREAK_JB.mp4?_t=1717615826",
    "en-usName": "Surrenders",
    esName: "Hincarse y Levantarse",
  },
  "Mountain Climbers": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/mtn_climbers_active_break_jb_1%20(720p).mp4?_t=1717616143",
    "en-usName": "Mountain Climbers",
    esName: "Escaladores",
  },
  "Bicycle Crunch": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/bicycle_crunches_active_break_ed_1%20(720p).mp4?_t=1717616014",
    "en-usName": "Bicycle Crunch",
    esName: "Abdominales Bicicleta",
  },
  "Squat Hold": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/squat_hold_active_break_ll_1%20(720p).mp4?_t=1717616150",
    "en-usName": "Squat Hold",
    esName: "Sentadilla isométrica contra Pared",
  },
  "Front to Back Hops": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/FRONT_TO_BACK_HOPS_ACTIVE_BREAK_LL_1.mp4?_t=1717616023",
    "en-usName": "Front to Back Hops",
    esName: "Saltos de Adelante y hacia Atrás",
  },
  "Squat Jumps": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/squat_jumps_active_break_jb_1%20(720p).mp4?_t=1717616156",
    "en-usName": "Squat Jumps",
    esName: "Sentadilla con Salto",
  },
  "Half Burpees": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/half_burpees_active_break_ed_1%20(720p).mp4?_t=1717616028",
    "en-usName": "Half Burpees",
    esName: "Medio Burpees",
  },
  "Reverse Lunges": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/reverse_lunges_active_break_ds_2%20(720p).mp4?_t=1717615926",
    "en-usName": "Reverse Lunges",
    esName: "Desplantes hacia atrás ",
  },
  "Push-Up & Punch": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/P_UP_PUNCH_ACTIVE_BREAK_JB.mp4?_t=1717615726",
    "en-usName": "Push-Up & Punch",
    esName: "Push-Up & Golpe al Aire",
  },
  "Ali Shuffle": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/ali_shuffle_active_break_ds_1%20(720p).mp4?_t=1717615920",
    "en-usName": "Ali Shuffle",
    esName: "Ali Shuffle",
  },
  "High Plank Jacks": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/HIGH_PLANK_JACKS_ACTIVE_BREAK_ED_1.mp4?_t=1717616135",
    "en-usName": "High Plank Jacks",
    esName: "Plancha Alta con Jumping Jacks",
  },
  "Squat-to-Lunge": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/SQUAT_TO_LUNGE_ACTIVE_BREAK_ED.mp4?_t=1717615744",
    "en-usName": "Squat-to-Lunge",
    esName: "Sentadilla y Desplante Alternando",
  },
  "Criss Cross Jacks": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/CRISS_CROSS_JACKS_ACTIVE_BREAK_JB.mp4?_t=1717615571",
    "en-usName": "Criss Cross Jacks",
    esName: "Jumping Jacks cruzando piernas",
  },
  "Flutter Kicks": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/flutter_kicks_active_break_ed_1%20(720p).mp4?_t=1717616018",
    "en-usName": "Flutter Kicks",
    esName: "Patadas de Nadador",
  },
  "High Plank Punch": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/planksgiving_screens_plank_and_punch%20(720p).mp4?_t=1717687340",
    "en-usName": "High Plank Punch",
    esName: "",
  },
  "Low Plank Hip Dips": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/planksgiving_screens_low_plank_hip_dips%20(720p).mp4?_t=1717687344",
    "en-usName": "Low Plank Hip Dips",
    esName: "",
  },
  "High To Low Plank": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/planksgiving_screens_high_to_low_plank%20(720p).mp4?_t=1717687405",
    "en-usName": "High To Low Plank",
    esName: "",
  },
  "Low Plank Heel Kicks": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/planksgiving_screens_low_plank_heel_kicks%20(720p).mp4?_t=1717687391",
    "en-usName": "Low Plank Heel Kicks",
    esName: "",
  },
  "Left Side Plank Hip Raise": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/planksgiving_screens_left_side_plank_hip_dips%20(720p).mp4?_t=1717687399",
    "en-usName": "Left Side Plank Hip Raise",
    esName: "",
  },
  "Right Side Plank Hip Raise": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/planksgiving_screens_right_side_plank_hip_dips%20(720p).mp4?_t=1717687335",
    "en-usName": "Right Side Plank Hip Raise",
    esName: "",
  },
  "Low Plank": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/low_plank_hold_active_break_ll_1%20(720p).mp4?_t=1717616169",
    "en-usName": "Low Plank",
    esName: "",
  },
  "High Plank Knee To Shoulder": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/planksgiving_screens_high_plank_knee_to_shoulder%20(720p).mp4?_t=1717687446",
    "en-usName": "High Plank Knee To Shoulder",
    esName: "",
  },
  "Left Side Plank": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/planksgiving_screens_left_side_plank_hold%20(720p).mp4?_t=1717687395",
    "en-usName": "Left Side Plank",
    esName: "",
  },
  "Right Side Plank": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/planksgiving_screens_right_side_plank_hold%20(720p).mp4?_t=1717687329",
    "en-usName": "Right Side Plank",
    esName: "",
  },
  "High Plank Bird Dog": {
    videoLink:
      "https://d2567ph5zp6ttk.cloudfront.net/public/storage/us/_Daily_Wourkout_Screen_System/active_breaks/planksgiving_screens_high_plank_bird_dog%20(720p).mp4?_t=1717687440",
    "en-usName": "High Plank Bird Dog",
    esName: "",
  },
};

export const findExercise = (exercise, type) => {
  return activeBreaks[exercise][type] ? activeBreaks[exercise][type] : "";
};
