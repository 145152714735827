import React from "react";
const ReleaseListItem = ({ release }) => {
  return (
    <tr>
      <td>{release.date}</td>
      <td>{release.title}</td>
      <td>{release.type}</td>
      <td>{release.description}</td>
      <td>{release.version}</td>
      <td>{release.createdAt}</td>
      <td>{release.updatedAt}</td>
      <td></td>
    </tr>
  );
};

export default ReleaseListItem;
