import React, { useContext, useState, useEffect } from "react";
import RoutineList from "./RoutineList";
import InstructionList from "./InstructionList";
import ScreenContext from "../../../context/screen/ScreenContext";
import contentFilter from "../../../utils/contentFilter";
import Spinner from "../../layout/Spinner";
import ScreenError from "./ScreenError";

const Workout = () => {
  const screenContext = useContext(ScreenContext);
  const {
    workout,
    isLrVideo,
    getCurrentRoundWorkout,
    loading,
    sendScreenStatus,
    screen,
    isScreenAuth,
  } = screenContext;

  const [roundImage, setRoundImage] = useState(null);

  const getCurrentWorkoutHandler = () => {
    getCurrentRoundWorkout(screen.round);
  };

  useEffect(() => {
    if (!workout) {
      getCurrentWorkoutHandler();
    }

    // eslint-disable-next-line
  }, [workout, loading, screen]);

  useEffect(() => {
    // set a 60 second timer
    const interval = setInterval(() => {
      // send screen status update & check if workout should update
      sendScreenStatus(screen._id);
    }, 60 * 1000);

    return () => clearInterval(interval);

    // eslint-disable-next-line
  });

  // Get the round image with correct resolution & round number
  useEffect(() => {
    const mediaContent = screen.round.toString();
    const mediaImage = contentFilter(mediaContent);
    setRoundImage(mediaImage);
  }, [screen.round]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : !workout || !workout.routines || workout.routines.length < 1 ? (
        <ScreenError
          isScreenAuth={isScreenAuth}
          checkFunction={getCurrentWorkoutHandler}
        />
      ) : (
        <div className={`videos-${workout.routines.length}`}>
          <div className="image-container">
            <h1 className="screen-round">
              <img
                src={roundImage}
                style={{
                  maxWidth: "70%",
                  marginTop: "-5%",
                  marginBottom: "-5.5%",
                }}
                alt={`Round ${screen.round}`}
              ></img>
            </h1>
          </div>
          <RoutineList
            routines={workout.routines}
            isLrVideo={isLrVideo}
          ></RoutineList>
          <InstructionList
            routines={workout.routines}
            contentHeader={workout.subHeader}
          ></InstructionList>
        </div>
      )}
    </>
  );
};

export default Workout;
