import React from "react";
import "../../../pages/screen/screen.css";

const InstructionList = ({ routines, contentHeader }) => {
  const specFormat = (routines) => {
    const verticalCenter =
      (routines.length === 1 && routines[0].text.length <= 100) ||
      routines.length === 2
        ? "spec-format"
        : "";
    return verticalCenter;
  };
  return (
    <>
      <div className="title">
        <div
          className={
            contentHeader && contentHeader.length > 24 ? "ticker" : "no-ticker"
          }
        >
          <span
            className={
              contentHeader && contentHeader.length > 1
                ? "sub-header"
                : "no-sub-header"
            }
          >
            {contentHeader ? contentHeader : ""}
          </span>
        </div>
      </div>
      <div className="instructions">
        <div className={specFormat(routines)}>
          <span
            className={
              routines.length < 2 && routines[0].text.length > 150
                ? "vertical-wrap"
                : ""
            }
          >
            <p>
              {routines.map(({ step, text, _id }) => (
                <span
                  className={
                    routines.length < 2
                      ? "step text-break"
                      : "step step-indent text-break"
                  }
                  key={_id}
                >
                  <span
                    className={
                      text.length > 150 && routines.length < 2
                        ? "vertical-scroll"
                        : ""
                    }
                  >
                    {routines.length > 1 ? `${step}: ` : ""} {text}
                  </span>
                </span>
              ))}
            </p>
          </span>
        </div>
      </div>
    </>
  );
};

export default InstructionList;
