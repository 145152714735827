import React, { useEffect } from "react";
import "./FlexDisplay.css";

const FlexVideo = ({ flexVideo }) => {
  const playVideo = () => {
    const video = document.getElementById("videoElement");
    video.play();
  };

  useEffect(() => {
    const video = document.getElementById("videoElement");
    video.addEventListener("ended", playVideo);
    return () => video.removeEventListener("ended", playVideo);
  });

  useEffect(() => {
    playVideo();
  }, []);

  return (
    <div className="FlexDisplay">
      <video
        src={flexVideo}
        autoPlay={false}
        muted={true}
        loop={false}
        className="FlexVideo"
        id="videoElement"
      ></video>
    </div>
  );
};

export default FlexVideo;
