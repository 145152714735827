import React from "react";
import { Link } from "react-router-dom";

const ContentStatusItem = ({ status }) => {
  return (
    <tr>
      <td>{status.round}</td>
      <td>{status.name}</td>
      <td>{status.startDate}</td>
      <td>{status.endDate}</td>
      <td style={{ color: "red" }}>Missing</td>
      <td className="btn-group-sm">
        <Link
          className="btn btn-sm btn-outline-warning"
          to={`rounds/${status.round}/workout/${status._id}`}
        >
          <i className="fas fa-pencil-alt"></i>
        </Link>
      </td>
    </tr>
  );
};

export default ContentStatusItem;
