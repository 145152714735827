import React, { useReducer } from "react";
import LoggerContext from "./LoggerContext";
import loggerReducer from "./loggerReducer";
import { GET_LOGS, DELETE_LOGS, LOGS_ERROR } from "./loggerTypes";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";

const LoggerState = (props) => {
  const initalState = {
    logs: [],
    error: null,
    current: null,
    loading: true,
  };

  const [state, dispatch] = useReducer(loggerReducer, initalState);

  // Get Logs
  const getLogs = async (query = null) => {
    try {
      setAuthToken(localStorage.token);

      const res = await axios.get(
        `/api/v2/logs${query ? `?query=${query}` : ""}`,
      );
      dispatch({ type: GET_LOGS, payload: res.data.data });
    } catch (err) {
      dispatch({ type: LOGS_ERROR, payload: err.response.msg });
    }
  };

  // Delete Logs
  const deleteLogs = async () => {
    try {
      setAuthToken(localStorage.token);

      await axios.delete("/api/v2/logs/");
      dispatch({ type: DELETE_LOGS, payload: [] });
    } catch (err) {
      dispatch({ type: LOGS_ERROR, payload: err.response.msg });
    }
  };

  return (
    <LoggerContext.Provider
      value={{
        logs: state.logs,
        error: state.error,
        loading: state.loading,
        current: state.current,
        getLogs,
        deleteLogs,
      }}
    >
      {props.children}
    </LoggerContext.Provider>
  );
};

export default LoggerState;
