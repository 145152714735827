import React from "react";

const Button = ({ cssClasses, action, content }) => {
  const onButtonClick = (event) => {
    event.preventDefault();

    event.currentTarget.blur();

    action(event);
  };

  return (
    <button className={cssClasses} onClick={onButtonClick}>
      {content}
    </button>
  );
};

export default Button;
