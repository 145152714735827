import React, { useEffect, useContext, useState } from "react";
import AuthContext from "../../../context/auth/AuthContext";
import WorkoutContext from "../../../context/workout/WorkoutContext";
import LocationStatus from "./LocationStatus";
import ContentStatus from "./ContentStatus";
import Notes from "../help/Notes";
import Spinner from "../../layout/Spinner";
import ScheduleStatus from "./ScheduleStatus";

const Dashboard = ({ props, match }) => {
  const authContext = useContext(AuthContext);
  const { user, loadUser, loading, error } = authContext;
  const workoutContext = useContext(WorkoutContext);
  const {
    workouts,
    currentContent,
    getWorkoutsByDate,
    getWorkoutsByRoutines,
    clearWorkouts,
    clearContent,
  } = workoutContext;

  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line
  }, [loading, error]);

  useEffect(() => {
    clearWorkouts();
    setUpdated(true);
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h2>Dashboard</h2>
      </div>
      {!loading &&
        user &&
        user.role !== "api-access" &&
        user.locations &&
        user.locations.length > 0 && (
          <LocationStatus
            loading={loading}
            match={match}
            user={user}
            loadUser={loadUser}
            error={error}
          />
        )}
      <div className="pb-5"></div>
      {!loading &&
        user &&
        (user.role === "admin" || user.role === "super-admin") && (
          <>
            <ContentStatus
              user={user}
              error={error}
              getWorkoutsByRoutines={getWorkoutsByRoutines}
              currentContent={currentContent}
              clearContent={clearContent}
            />
            {updated && (
              <ScheduleStatus
                workouts={workouts}
                getWorkoutsByDate={getWorkoutsByDate}
                clearWorkouts={clearWorkouts}
              />
            )}
          </>
        )}
      <div className="pb-2"></div>
      {!loading && <Notes subComponent={true} />}
      {loading && (
        <span>
          Loading dashboard data...
          <Spinner />
        </span>
      )}
    </>
  );
};

export default Dashboard;
