import React from "react";
// import VideoState from "../../context/videoContext/VideoState";
import Routine from "./Routine";

const RoutineList = ({ routines, isLrVideo }) => {
  return (
    <div className="row" style={{ backgroundColor: "black" }}>
      {routines.map((routine, i) => (
        <Routine
          key={routine._id}
          routine={routine}
          routineCount={routines.length}
          isLrVideo={isLrVideo}
        ></Routine>
      ))}
    </div>
  );
};

export default RoutineList;
