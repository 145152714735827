import React, { useContext, useEffect, useState } from "react";
import LocationContext from "../../../context/location/LocationContext";
import WorkoutContext from "../../../context/workout/WorkoutContext";
import UserContext from "../../../context/user/UserContext";
import ScreenContext from "../../../context/screen/ScreenContext";
import RoundContext from "../../../context/round/RoundContext";

const System = () => {
  const locationContext = useContext(LocationContext);
  const workoutContext = useContext(WorkoutContext);
  const userContext = useContext(UserContext);
  const screenContext = useContext(ScreenContext);
  const roundContext = useContext(RoundContext);
  const { locations, getLocations, updateLocation } = locationContext;
  const { workouts, getWorkoutsForRound } = workoutContext;
  const { users, getUsers } = userContext;
  const { screens, getScreens } = screenContext;
  const { rounds, getRounds } = roundContext;

  const exclusions = ["9RID0001"];

  const [selection, setSelection] = useState(null);

  const handlePrimary = () => {
    const confirm = window.prompt("Enter Password to continue: ", "");
    if (confirm === "SYSADMIN") {
      getLocations().then(() => {
        locations.forEach((location) => {
          if (!exclusions.includes(location.locationIdentifier)) {
            const updatedLocation = {
              ...location,
              lrVideo: false,
            };
            updateLocation(updatedLocation);
          }
        });
      });
    } else if (confirm === null) {
      return;
    } else {
      window.alert("Incorrect Password");
    }
  };

  const handleSecondary = () => {
    const confirm = window.prompt("Enter Password to continue: ", "");
    if (confirm === "SYSADMIN") {
      getLocations().then(() => {
        locations.forEach((location) => {
          const updatedLocation = {
            ...location,
            lrVideo: true,
          };
          updateLocation(updatedLocation);
        });
      });
    } else if (confirm === null) {
      return;
    } else {
      window.alert("Incorrect Password");
    }
  };

  const exportWorkoutData = () => {
    const confirm = window.prompt("Enter Password to continue: ", "");
    if (confirm === "Lt6r9FcT4N_`") {
      const roundConfirm = window.prompt("Enter Round to Export: ");
      const result = Number(roundConfirm);
      result > 0 && result < 10
        ? getWorkoutsForRound(Number(roundConfirm)).then(() => {
            setSelection(["workouts", roundConfirm]);
          })
        : window.alert("Invalid Round Number");
    } else if (confirm === null) {
      return;
    } else {
      window.alert("Incorrect Password");
    }
  };

  const exportUserData = () => {
    const confirm = window.prompt("Enter Password to continue: ", "");
    if (confirm === "Lt6r9FcT4N_`") {
      getUsers().then(() => {
        setSelection(["users"]);
      });
    } else if (confirm === null) {
      return;
    } else {
      window.alert("Incorrect Password");
    }
  };

  const exportLocationData = () => {
    const confirm = window.prompt("Enter Password to continue: ", "");
    if (confirm === "Lt6r9FcT4N_`") {
      getLocations().then(() => {
        setSelection(["locations"]);
      });
    } else if (confirm === null) {
      return;
    } else {
      window.alert("Incorrect Password");
    }
  };

  const exportScreenData = () => {
    const confirm = window.prompt("Enter Password to continue: ", "");
    if (confirm === "Lt6r9FcT4N_`") {
      getScreens().then(() => {
        setSelection(["screens"]);
      });
    } else if (confirm === null) {
      return;
    } else {
      window.alert("Incorrect Password");
    }
  };

  const exportRoundData = () => {
    const confirm = window.prompt("Enter Password to continue: ", "");
    if (confirm === "Lt6r9FcT4N_`") {
      getRounds().then(() => {
        setSelection(["rounds"]);
      });
    } else if (confirm === null) {
      return;
    } else {
      window.alert("Incorrect Password");
    }
  };

  const saveWorkoutFile = (roundConfirm) => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(workouts),
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `Round-${roundConfirm}-workoutData.json`;
    link.click();
  };

  const saveUserFile = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(users),
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "userData.json";
    link.click();
  };

  const saveLocationFile = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(locations),
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "locationData.json";
    link.click();
  };

  const saveScreenFile = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(screens),
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "screenData.json";
    link.click();
  };

  const saveRoundFile = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(rounds),
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "roundData.json";
    link.click();
  };

  useEffect(() => {
    if (selection && selection[0] === "workouts") {
      selection[1] && saveWorkoutFile(selection[1]);
    } else if (selection && selection[0] === "locations") {
      saveLocationFile();
    } else if (selection && selection[0] === "users") {
      saveUserFile();
    } else if (selection && selection[0] === "screens") {
      saveScreenFile();
    } else if (selection && selection[0] === "rounds") {
      saveRoundFile();
    }
    //eslint-disable-next-line
  }, [selection]);

  const mainFormat = {
    border: "1px solid black",
    borderRadius: "5px",
    padding: "20px",
    width: "40%",
    margin: "10px",
    backgroundColor: "whitesmoke",
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h2>System (ADMIN)</h2>
      </div>
      <br />
      <div style={{ display: "flex" }}>
        <div style={mainFormat}>
          <b>
            ACTIVATE{" "}
            <span style={{ textDecoration: "underline" }}>
              VIDEO HOST (VIMEO)
            </span>{" "}
            FOR ALL LOCATIONS:{" "}
          </b>{" "}
          <br />
          <br />
          <button
            className="btn btn-dark"
            onClick={handlePrimary}
            style={{ color: "white" }}
          >
            Activate
          </button>
          <br />
          <br />
          <small>
            <b>
              Note: This will not update locations where this streaming service
              is unavailable.
            </b>
          </small>
          <br />
        </div>
        <br />
        <div style={mainFormat}>
          <b>
            ACTIVATE{" "}
            <span style={{ textDecoration: "underline" }}>
              VIDEO HOST (AWS)
            </span>{" "}
            FOR ALL LOCATIONS:{" "}
          </b>{" "}
          <br />
          <br />
          <button
            className="btn btn-dark"
            onClick={handleSecondary}
            style={{ color: "white" }}
          >
            Activate
          </button>
          <br />
          <br />
        </div>
      </div>
      <div
        style={{
          border: "1px solid black",
          marginTop: "50px",
          marginBottom: "50px",
        }}
      ></div>
      <h2>Developer Tools</h2>
      <br />
      <br />
      <div style={{ display: "flex" }}>
        <div style={mainFormat}>
          <b>BACKUP WORKOUT DATA</b> <br />
          <br />
          <button className="btn btn-danger" onClick={exportWorkoutData}>
            Export
          </button>
          <br />
        </div>
        <br />
        <div style={mainFormat}>
          <b>BACKUP LOCATION DATA</b> <br />
          <br />
          <button className="btn btn-danger" onClick={exportLocationData}>
            Export
          </button>
          <br />
        </div>
        <br />
      </div>
      <div style={{ display: "flex" }}>
        <div style={mainFormat}>
          <b>BACKUP USER DATA</b> <br />
          <br />
          <button className="btn btn-danger" onClick={exportUserData}>
            Export
          </button>
          <br />
        </div>
        <br />
        <div style={mainFormat}>
          <b>BACKUP SCREEN DATA</b> <br />
          <br />
          <button className="btn btn-danger" onClick={exportScreenData}>
            Export
          </button>
          <br />
        </div>
      </div>
      <div style={mainFormat}>
        <b>BACKUP ROUND DATA</b> <br />
        <br />
        <button className="btn btn-danger" onClick={exportRoundData}>
          Export
        </button>
        <br />
      </div>
    </div>
  );
};

export default System;
