import React, { useContext, useState, useEffect } from "react";
import WorkoutContext from "../../../context/workout/WorkoutContext";
import BackButton from "../../common/BackButton";
import AlertContext from "../../../context/alert/AlertContext";
import WorkoutRoutineList from "./WorkoutRoutineList";
import Spinner from "../../layout/Spinner";

const WorkoutItem = ({ match, props }) => {
  const workoutId = match ? match.params.id : undefined;
  const roundNumber = match ? match.params.roundNumber : undefined;

  const workoutContext = useContext(WorkoutContext);
  const alertContext = useContext(AlertContext);
  const {
    getWorkout,
    addWorkout,
    error,
    updateWorkout,
    current,
    clearCurrent,
    loading,
    addWorkoutRoutine,
    updateWorkoutRoutine,
    deleteWorkoutRoutine,
  } = workoutContext;
  const { setAlert } = alertContext;

  const [update, setUpdate] = useState(false);
  const [updatedWorkout, setUpdatedWorkout] = useState(null);

  useEffect(() => {
    if (!current && workoutId) {
      getWorkout(workoutId);
    } else if (current && !workoutId && !updatedWorkout) {
      getWorkout(current.id);
      setUpdatedWorkout(current.id);
    }

    if (current) {
      handleWorkout(current);
    } else {
      handleWorkout({
        startDate: "",
        endDate: "",
        name: "",
        subHeader: "",
        round: roundNumber,
        routines: [],
      });
    }

    // eslint-disable-next-line
  }, [error, current, loading]);

  // On unmount remove current
  useEffect(() => {
    return () => clearCurrent();
    // eslint-disable-next-line
  }, []);

  const [workoutInput, setWorkoutInput] = useState({
    startDate: "",
    endDate: "",
    name: "",
    subHeader: "",
    round: roundNumber,
    routines: [],
  });

  const [formErrors, setFormErrors] = useState([]);

  const handleWorkout = (workoutInput) =>
    setWorkoutInput({ ...current, ...workoutInput });

  const { name, subHeader, startDate, endDate, routines } = workoutInput;

  const onSubmit = (event) => {
    event.preventDefault();

    if (formErrors.length > 0) {
      setAlert({
        message:
          "Please double-check the form requirements and try again. The Workout end date cannot be scheduled before the start date.",
        type: "error",
      });
    } else if (workoutId) {
      setUpdate(false);
      updateWorkout({
        ...workoutInput,
        endDate: `${endDate.split("T")[0]}T23:59:59.999Z`,
      });
      setAlert({
        message: `Updated Workout: ${workoutInput.name}`,
        type: "success",
      });
    } else if (updatedWorkout) {
      setUpdate(false);
      updateWorkout({
        ...workoutInput,
        endDate: `${endDate.split("T")[0]}T23:59:59.999Z`,
      });
      setAlert({
        message: `Updated Workout: ${workoutInput.name}`,
        type: "success",
      });
    } else {
      setUpdate(false);
      addWorkout({
        ...workoutInput,
        endDate: `${endDate.split("T")[0]}T23:59:59.999Z`,
      });
      setAlert({
        message: `Added Workout: ${workoutInput.name}`,
        type: "success",
      });
    }
  };

  const onChange = (event) => {
    if (current && event.target.value !== current[event.target.name]) {
      setUpdate(true);
    } else {
      update && setUpdate(false);
    }

    setWorkoutInput({
      ...workoutInput,
      [event.target.name]: event.target.value,
    });

    setFormErrors([...formErrors]);

    if (["startDate", "endDate"].includes(event.target.name)) {
      let startDateObj = new Date(workoutInput.startDate);
      let endDateObj = new Date(workoutInput.endDate);
      if (event.target.name === "startDate") {
        startDateObj = new Date(event.target.value);
      }
      if (event.target.name === "endDate") {
        endDateObj = new Date(event.target.value);
      }
      if (startDateObj > endDateObj) {
        setFormErrors(["endDate"]);
      } else {
        setFormErrors(
          formErrors.filter((err) => {
            return err !== "endDate";
          }),
        );
      }
    }
  };

  return (
    <>
      {workoutInput && !loading && (
        <>
          <form onSubmit={onSubmit}>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h2 className="text-break">
                {current ? current.name : "Schedule New Workout"}
              </h2>
              <div className="btn-group ">
                <BackButton url={`/admin/rounds/${roundNumber}/workout`} />
                <button type="submit" className="btn btn-primary">
                  <i className="fas fa-save"></i> {!current ? "Save" : "Update"}
                </button>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-6">
                <label htmlFor="">Round Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="roundName"
                  value={`Round ${roundNumber}`}
                  onChange={onChange}
                  disabled={true}
                  required={true}
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  maxLength={85}
                  className="form-control"
                  value={name || ""}
                  placeholder="85 characters (max)"
                  onChange={onChange}
                  required={true}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-sm-12 col-xl-6">
                <label htmlFor="subHeader">
                  Content Header
                  <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
                    {workoutInput.subHeader &&
                    workoutInput.subHeader.length <= 24
                      ? ` (remaining characters ${
                          24 - workoutInput.subHeader.length
                        })`
                      : workoutInput.subHeader &&
                        workoutInput.subHeader.length >= 24
                      ? " (text will scroll horizontal)"
                      : ""}
                  </span>
                </label>
                <textarea
                  rows={4}
                  style={{ resize: "none" }}
                  className="form-control"
                  name="subHeader"
                  value={subHeader || ""}
                  onChange={onChange}
                  required={false}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-6">
                <label htmlFor="startDate">Start Date</label>
                <input
                  className="form-control"
                  type="date"
                  name="startDate"
                  onChange={onChange}
                  value={startDate.split("T")[0] || ""}
                  required={true}
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="endDate">End Date</label>
                <input
                  className={`form-control ${
                    formErrors && formErrors.includes("endDate")
                      ? "is-invalid"
                      : ""
                  }`}
                  type="date"
                  name="endDate"
                  onChange={onChange}
                  value={endDate.split("T")[0] || ""}
                  required={true}
                />
                {formErrors && formErrors.includes("endDate") ? (
                  <div className="text-9r-red">
                    Please enter a valid end date.
                  </div>
                ) : null}
              </div>
            </div>
          </form>
          {update && (
            <p
              className="alert alert-warning"
              role="alert"
              style={{
                padding: "10px",
                marginTop: "20px",
                textAlign: "center",
              }}
            >
              <b>You have unsaved changes!</b> Please click the Update button to
              save your changes.
            </p>
          )}
          {routines && current && (
            <WorkoutRoutineList
              routines={routines}
              handleWorkout={handleWorkout}
              setAlert={setAlert}
              addWorkoutRoutine={addWorkoutRoutine}
              updateWorkoutRoutine={updateWorkoutRoutine}
              deleteWorkoutRoutine={deleteWorkoutRoutine}
            />
          )}
        </>
      )}

      {(!workoutInput || loading) && <Spinner />}
    </>
  );
};

export default WorkoutItem;
