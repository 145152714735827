// Add day to date
export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

// Create an array that holds 9 arrays where each arrays length equals 7
export const generateSchedule = () => {
  const schedule = [];
  const round1 = Array.from({ length: 7 }).map((day) => false);
  const round2 = Array.from({ length: 7 }).map((day) => false);
  const round3 = Array.from({ length: 7 }).map((day) => false);
  const round4 = Array.from({ length: 7 }).map((day) => false);
  const round5 = Array.from({ length: 7 }).map((day) => false);
  const round6 = Array.from({ length: 7 }).map((day) => false);
  const round7 = Array.from({ length: 7 }).map((day) => false);
  const round8 = Array.from({ length: 7 }).map((day) => false);
  const round9 = Array.from({ length: 7 }).map((day) => false);
  schedule.push(round1);
  schedule.push(round2);
  schedule.push(round3);
  schedule.push(round4);
  schedule.push(round5);
  schedule.push(round6);
  schedule.push(round7);
  schedule.push(round8);
  schedule.push(round9);
  return schedule;
};

// Create a rolling schedule of workouts
export const createSchedule = (workouts) => {
  let rollingSchedule = generateSchedule();
  const rollingDays = daysOfWeek();

  workouts.map((workout) => {
    const round = workout.round - 1;
    const formattedDate = workout.startDate.slice(0, 10).split("-").join("/");
    const workoutDate = new Date(formattedDate);
    const workoutDay = workoutDate.getDay();
    const idx = rollingDays.indexOf(workoutDay);
    rollingSchedule[round][idx] = workout;
    return rollingSchedule;
  });
  return rollingSchedule;
};

/**
 * @function daysOfWeek
 * @param {boolean} formatted return the rolling dates formatted as strings (sun-sat) or pass no param to return numbers (0-7)
 * @returns Array of dates
 */
export const daysOfWeek = (formatted = null) => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const todaysDate = new Date();
  const startingDay = todaysDate.getDay();
  const day2 = addDays(todaysDate, 1).getDay();
  const day3 = addDays(todaysDate, 2).getDay();
  const day4 = addDays(todaysDate, 3).getDay();
  const day5 = addDays(todaysDate, 4).getDay();
  const day6 = addDays(todaysDate, 5).getDay();
  const day7 = addDays(todaysDate, 6).getDay();

  const dayStrings = [
    daysOfWeek[startingDay],
    daysOfWeek[day2],
    daysOfWeek[day3],
    daysOfWeek[day4],
    daysOfWeek[day5],
    daysOfWeek[day6],
    daysOfWeek[day7],
  ];

  const dayNums = [startingDay, day2, day3, day4, day5, day6, day7];

  if (formatted) {
    return dayStrings;
  } else {
    return dayNums;
  }
};
