import axios from "axios";
import React, { useReducer } from "react";
import ScreenLinkContext from "./ScreenLinkContext";
import ScreenLinkReducer from "./ScreenLinkReducer";
import {
  SCREENLINK_ERROR,
  DELETE_SCREENLINK,
  SET_CURRENT,
} from "./screenLinkTypes";

const ScreenLinkState = (props) => {
  const initialState = {
    current: null,
    screenLinks: [],
    loading: true,
    error: null,
  };

  const [state, dispatch] = useReducer(ScreenLinkReducer, initialState);

  // Get a screenLink
  const getScreenLink = async (id) => {
    try {
      const res = await axios.get(`/api/v1/screenlinks/${id}`);

      dispatch({ type: SET_CURRENT, payload: res.data.data });
    } catch (error) {
      dispatch({ type: SCREENLINK_ERROR, payload: error });
    }
  };

  // Create a screenLink
  const createScreenLink = async (authCode) => {
    try {
      const res = await axios.post(`/api/v1/screenlinks`, { authCode });

      dispatch({ type: SET_CURRENT, payload: res.data.data });
    } catch (error) {
      dispatch({ type: SCREENLINK_ERROR, payload: error });
    }
  };

  // Update a screenLink
  const updateScreenLink = async (screenLink) => {
    try {
      const res = await axios.put(
        `/api/v1/screenlinks/${screenLink._id}`,
        screenLink
      );

      dispatch({ type: SET_CURRENT, payload: res.data.data });
    } catch (error) {
      dispatch({ type: SCREENLINK_ERROR, payload: error });
    }
  };

  // Delete a screenLink
  const deleteScreenLink = async (id) => {
    try {
      const res = await axios.delete(`/api/v1/screenlinks/${id}`);

      dispatch({ type: DELETE_SCREENLINK, payload: res.data.data });
    } catch (error) {
      dispatch({ type: SCREENLINK_ERROR, payload: error });
    }
  };

  return (
    <ScreenLinkContext.Provider
      value={{
        screenLinks: state.screenLinks,
        current: state.current,
        loading: state.loading,
        error: state.error,
        createScreenLink,
        updateScreenLink,
        deleteScreenLink,
        getScreenLink,
      }}
    >
      {props.children}
    </ScreenLinkContext.Provider>
  );
};

export default ScreenLinkState;
