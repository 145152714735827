import React from "react";

const Pagination = ({ perPage, total, paginate, currentPage }) => {
  const pages = [];
  const maxPage = 9;

  for (let i = 1; i <= Math.ceil(total / perPage); i++) {
    pages.push(i);
  }

  //#region Functions
  const onPreviousClick = (event) => {
    event.preventDefault();
    event.currentTarget.blur();
    paginate(currentPage && currentPage >= 2 ? currentPage - 1 : 1);
  };

  const onNextClick = (event) => {
    event.preventDefault();
    event.currentTarget.blur();
    paginate(
      currentPage && currentPage < pages.length ? currentPage + 1 : pages.length
    );
  };

  const onPageClick = (event) => {
    event.preventDefault();
    event.currentTarget.blur();
    paginate(Number(event.currentTarget.value));
  };

  const onMaxClick = (event) => {
    event.preventDefault();
    event.currentTarget.blur();
    paginate(Number(pages.length));
  };

  const onMinClick = (event) => {
    event.preventDefault();
    event.currentTarget.blur();
    paginate(Number(1));
  };

  //#endregion

  return (
    <nav aria-label="navigation">
      <ul
        className="pagination"
        style={{ listStyle: "none", marginLeft: ".5rem" }}
      >
        <li className="page-item">
          <button
            className="page-link btn-sm"
            aria-label="previous page"
            onClick={onPreviousClick}
          >
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
        {pages.length > maxPage && currentPage !== 1 && (
          <li className="page-item">
            <button
              className="page-link btn-sm"
              aria-label="first page"
              onClick={onMinClick}
            >
              <span aria-hidden="true">1...</span>
            </button>
          </li>
        )}
        {pages && currentPage < pages.length - maxPage + 1
          ? pages.slice(currentPage - 1, currentPage + maxPage).map((page) => (
              <li
                key={page}
                className="page-item"
                style={{ cursor: "pointer" }}
              >
                <button
                  className="page-link btn-sm"
                  aria-label={`page number ${page}`}
                  onClick={onPageClick}
                  style={
                    currentPage === page
                      ? {
                          color: "white",
                          backgroundColor: "lightgray",
                        }
                      : { color: "blue" }
                  }
                  value={page}
                >
                  {page}
                </button>
              </li>
            ))
          : pages
              .slice(
                pages.length < maxPage + 1 ? 0 : pages.length - maxPage - 1,
                pages.length
              )
              .map((page) => (
                <li
                  key={page}
                  className="page-item"
                  style={{ cursor: "pointer" }}
                >
                  <button
                    className="page-link btn-sm"
                    aria-label={`page number ${page}`}
                    onClick={onPageClick}
                    style={
                      currentPage === page
                        ? {
                            color: "white",
                            backgroundColor: "lightgray",
                          }
                        : { color: "blue" }
                    }
                    value={page}
                  >
                    {page}
                  </button>
                </li>
              ))}
        {pages.length > maxPage + 1 && currentPage !== pages.length && (
          <li className="page-item">
            <button
              className="page-link btn-sm"
              aria-label="last page"
              onClick={onMaxClick}
            >
              <span aria-hidden="true">...{pages.length}</span>
            </button>
          </li>
        )}
        <li className="page-item">
          <button
            className="page-link btn-sm"
            aria-label="next page"
            onClick={onNextClick}
          >
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
