import React from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuid } from "uuid";
import "../workout/z_overwrite.css";

export const WorkoutSchedule = ({ schedule, setCheck }) => {
  const history = useHistory();
  let checked = false;

  // Checkmark JSX
  const Valid = ({ scheduled }) => (
    <td
      title={
        scheduled &&
        `Name: ${scheduled.name} \n Start: ${scheduled.startDate.slice(
          0,
          10,
        )} \n End: ${scheduled.endDate.slice(0, 10)} \n ${
          scheduled.subHeader ? `Header: ${scheduled.subHeader}` : ""
        }`
      }
      className="workout-item-wrapper"
    >
      <i className="fa fa-check text-success"></i>
    </td>
  );

  // XMark JSX
  const Invalid = () => {
    if (!checked) {
      setCheck();
      checked = true;
    }
    return (
      <td title="Not Scheduled" className="workout-item-wrapper">
        <i className="fa fa-times text-danger"></i>
      </td>
    );
  };

  // Navigate to round
  const handleClick = (round) => {
    history.push(`/admin/rounds/${round}`);
  };

  return (
    <>
      {schedule && (
        <tbody>
          <tr
            className="text-center"
            role="button"
            aria-label="navigate to round 1"
            onClick={() => handleClick(1)}
          >
            <th scope="row">1</th>
            {schedule[0].map((scheduled) => {
              return scheduled ? (
                <Valid scheduled={scheduled} key={scheduled.id} />
              ) : (
                <Invalid key={uuid()} />
              );
            })}
          </tr>
          <tr
            className="text-center"
            role="button"
            aria-label="navigate to round 2"
            onClick={() => handleClick(2)}
          >
            <th scope="row">2</th>
            {schedule[1].map((scheduled) => {
              return scheduled ? (
                <Valid scheduled={scheduled} key={scheduled.id} />
              ) : (
                <Invalid key={uuid()} />
              );
            })}
          </tr>
          <tr
            className="text-center"
            role="button"
            aria-label="navigate to round 3"
            onClick={() => handleClick(3)}
          >
            <th scope="row">3</th>
            {schedule[2].map((scheduled) => {
              return scheduled ? (
                <Valid scheduled={scheduled} key={scheduled.id} />
              ) : (
                <Invalid key={uuid()} />
              );
            })}
          </tr>
          <tr
            className="text-center"
            role="button"
            aria-label="navigate to round 4"
            onClick={() => handleClick(4)}
          >
            <th scope="row">4</th>
            {schedule[3].map((scheduled) => {
              return scheduled ? (
                <Valid scheduled={scheduled} key={scheduled.id} />
              ) : (
                <Invalid key={uuid()} />
              );
            })}
          </tr>
          <tr
            className="text-center"
            role="button"
            aria-label="navigate to round 5"
            onClick={() => handleClick(5)}
          >
            <th scope="row">5</th>
            {schedule[4].map((scheduled) => {
              return scheduled ? (
                <Valid scheduled={scheduled} key={scheduled.id} />
              ) : (
                <Invalid key={uuid()} />
              );
            })}
          </tr>
          <tr
            className="text-center"
            role="button"
            aria-label="navigate to round 6"
            onClick={() => handleClick(6)}
          >
            <th scope="row">6</th>
            {schedule[5].map((scheduled) => {
              return scheduled ? (
                <Valid scheduled={scheduled} key={scheduled.id} />
              ) : (
                <Invalid key={uuid()} />
              );
            })}
          </tr>
          <tr
            className="text-center"
            role="button"
            aria-label="navigate to round 7"
            onClick={() => handleClick(7)}
          >
            <th scope="row">7</th>
            {schedule[6].map((scheduled) => {
              return scheduled ? (
                <Valid scheduled={scheduled} key={scheduled.id} />
              ) : (
                <Invalid key={uuid()} />
              );
            })}
          </tr>
          <tr
            className="text-center"
            role="button"
            aria-label="navigate to round 8"
            onClick={() => handleClick(8)}
          >
            <th scope="row">8</th>
            {schedule[7].map((scheduled) => {
              return scheduled ? (
                <Valid scheduled={scheduled} key={scheduled.id} />
              ) : (
                <Invalid key={uuid()} />
              );
            })}
          </tr>
          <tr
            className="text-center"
            role="button"
            aria-label="navigate to round 9"
            onClick={() => handleClick(9)}
          >
            <th scope="row">9</th>
            {schedule[8].map((scheduled) => {
              return scheduled ? (
                <Valid scheduled={scheduled} key={scheduled.id} />
              ) : (
                <Invalid key={uuid()} />
              );
            })}
          </tr>
        </tbody>
      )}
    </>
  );
};

export default WorkoutSchedule;
