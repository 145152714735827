import React from "react";

const Help = (props) => {
  const mainFormat = {
    maxHeight: "100%",
    maxWidth: "100%",
    textAlign: "center",
    overflow: "hidden",
  };
  const imageFormat = {
    maxHeight: "100%",
    maxWidth: "100%",
  };
  const blockFormat = {
    borderBottom: "1px solid lightgray",
    paddingBottom: "3%",
    paddingTop: "3%",
  };
  const paragraphFormat = {
    marginBottom: "2%",
    fontSize: "1.5rem",
    textAlign: "left",
  };
  const lineFormat = {
    marginBottom: "2vh",
    paddingBottom: "1vh",
    marginTop: "2vh",
  };
  const subjectHeader = {
    color: "#c8102e",
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
    paddingBottom: "2%",
    paddingTop: "2%",
    textAlign: "center",
  };
  const subjectContent = {
    color: "#c8102e",
    textAlign: "left",
    paddingTop: "1%",
  };
  const lineBreak = {
    borderBottom: "1px dashed lightgray",
  };
  const textAlign = {
    textAlign: "left",
  };
  const fontSize = {
    fontSize: "1.5rem",
  };
  return (
    <div style={mainFormat}>
      <div>
        <img
          src={require("./images/9r-logo.png")}
          alt="USE PDF FOR IMAGES"
          style={imageFormat}
        ></img>
      </div>
      <div style={textAlign}>
        <div style={lineFormat}>
          <h1 style={subjectHeader}>LOCKER ROOM SET-UP</h1>

          <blockquote style={blockFormat}>
            <p style={fontSize}>
              <b>STEP 1:</b> Log in to the 9Round Locker Room, select the{" "}
              <b>Services</b> tab, and then click the{" "}
              <b>{global.config.product.app.name}</b> option.
            </p>
          </blockquote>

          <blockquote style={blockFormat}>
            <p style={fontSize}>
              <b>STEP 2:</b> On the <b>{global.config.product.app.name}</b>{" "}
              page, click the <b>My Locations</b> button on the left-hand side
              of the screen. This will show all locations the Location Specific
              Email (LSE) has access to.
            </p>
            <p>
              <img
                src={require("./images/u.i_pencil.png")}
                alt="USE PDF FOR IMAGES"
                style={imageFormat}
              ></img>
            </p>
          </blockquote>

          <blockquote style={blockFormat}>
            <p style={fontSize}>
              <b>STEP 3:</b> Starting with <b>Round 1</b>, turn on all screens
              and computers. Please ensure that the <b>Source</b> is set to{" "}
              <b>HDMI</b>, otherwise the screen may show as black. An
              authentication code should appear on each screen.{" "}
              <b style={{ color: "#c8102e" }}>
                Document each round and its specific authentication code.
              </b>
            </p>
            <p>
              <img
                src={require("./images/dws_auth.png")}
                alt="USE PDF FOR IMAGES"
                style={imageFormat}
              ></img>
            </p>
          </blockquote>

          <blockquote style={blockFormat}>
            <p style={fontSize}>
              <b>STEP 4:</b> On the <b>My Locations</b> page, click on the
              <b> Edit</b> button (pencil icon) on the far right-hand side of
              the location you are wanting to set up. This will take you to the{" "}
              <b>Screens</b> page.
            </p>
            <p>
              <img
                src={require("./images/dws_pencil.png")}
                alt="USE PDF FOR IMAGES"
                style={imageFormat}
              ></img>
            </p>
          </blockquote>

          <blockquote style={blockFormat}>
            <p style={fontSize}>
              <b>STEP 5:</b> On the <b>Screens</b> page, enter the relevant
              authentication code for Round 1 in the <b>Auth Link Code</b> box.
              When it has been entered, click the <b>Link</b> button. Repeat the
              same steps on Round 2 and continue until all rounds authentication
              codes have been entered. Within 60-90 seconds, the workouts should
              appear on the correct screens.
            </p>
            <p>
              <img
                src={require("./images/dws_controls.png")}
                alt="USE PDF FOR IMAGES"
                style={imageFormat}
              ></img>
            </p>
          </blockquote>

          <blockquote>
            <p
              style={{
                ...fontSize,
                paddingTop: "3%",
                paddingBottom: "1%",
              }}
            >
              <b>STEP 6:</b> Once you are finished entering the codes, click the{" "}
              <b>Back</b> button. All screens are now set up.
            </p>
          </blockquote>
        </div>
      </div>

      <div>
        <h1 style={{ ...subjectHeader, color: "black" }}>
          <strong>IMPORTANT NOTES</strong>
        </h1>
        <br />
        <p style={paragraphFormat}>
          <span
            style={{
              ...subjectContent,
            }}
          >
            <strong style={{ fontSize: "1.5rem" }}>SCREEN ORIENTATION</strong>
          </span>
          <br />
          The TVs orientation should not be changed out of Landscape with the
          televisions remote control. The computers software will automatically
          rotate the display into Portrait mode. If the screen is not rotating,
          ensure that the HDMI cable is plugged into HDMI port 1 on the MINIPC.
        </p>
        <div style={lineBreak}></div>
        <br />
        <p style={paragraphFormat}>
          <span
            style={{
              ...subjectContent,
              color: "#c8102e",
            }}
          >
            <strong style={{ fontSize: "1.5rem" }}>
              ALWAYS USE HDMI PORT 1
            </strong>
          </span>
          <br />
          In order for the screens to function properly, you must ensure the
          HDMI cable is plugged into HDMI port 1 on the MINIPC. If you have the
          GK Series, the ports will be unlabeled. HDMI 1 is the port closest to
          the power adapter.
        </p>
        <div style={lineBreak}></div>
        <br />
        <p style={paragraphFormat}>
          <span
            style={{
              ...subjectContent,
              color: "#c8102e",
            }}
          >
            <strong style={{ fontSize: "1.5rem" }}>RESTART THE MINI PC</strong>
          </span>
          <br />
          Most issues will be resolved by resetting the MINI PC. If you are
          experiencing unexpected issues not covered in the troubleshooting
          section, please restart the MINIPC.
        </p>
        <div style={lineBreak}></div>
        <br />
        <p style={paragraphFormat}>
          <span
            style={{
              ...subjectContent,
              color: "black",
            }}
          >
            <strong style={{ fontSize: "1.5rem" }}>SUPPORT TICKETS</strong>
          </span>
          <br />
          If your screen is no longer linked or you are having issues that you
          cannot resolve, please email{" "}
          <strong>
            <a href="mailto:support@9roundhelp.zendesk.com">
              support@9roundhelp.zendesk.com
            </a>
          </strong>
          . Your support ticket will automatically be created from your email
          message and then sent to an agent.
        </p>
        <div style={lineBreak}></div>
        <br />
        <p style={paragraphFormat}>
          <span
            style={{
              ...subjectContent,
              color: "black",
            }}
          >
            <strong style={{ fontSize: "1.5rem" }}>
              PURCHASES/REPLACEMENTS
            </strong>
          </span>
          <br />
          If you need to purchase equipment, replacements or redeem a warranty
          for the Daily Workout Screens system, please email PTG for pricing and
          assistance -{" "}
          <strong>
            <a href="malito:ali.kazanowski@promotion.tech>">
              ali.kazanowski@promotion.tech
            </a>
          </strong>
        </p>
      </div>
      <br />
      <div style={lineFormat}>
        <h1 style={{ ...subjectHeader, color: "black" }} className="text-break">
          <strong>TROUBLESHOOTING</strong>
        </h1>

        <br />
        <h3 style={subjectContent}>
          <strong>My Screen is black, workouts are not appearing.</strong>
        </h3>
        <br />
        <p style={paragraphFormat}>
          <b>RESOLVE:</b> Please ensure that the computer and screen are both
          plugged into a power source, and that both have been turned on. If the
          screen is still black, check to make sure the HDMI cable is plugged
          securely into both the screen and the computer. If the screen is still
          black, check the Source to ensure it is set to HDMI.
        </p>
        <br />
        <div style={lineBreak}></div>
        <br />
        <h3 style={subjectContent}>
          <strong>
            My Screen is not showing the correct workout for the day.
          </strong>
        </h3>
        <br />
        <p style={paragraphFormat}>
          <b>RESOLVE #1:</b> Navigate to My Locations and refresh the screen
          that is not working correctly by using the
          <b style={{ color: "#c8102e" }}> REFRESH BUTTON</b>
          .
          <br />
          <br />
          <img
            src={require("./images/dws_refresh.png")}
            alt="USE PDF FOR IMAGES"
            style={imageFormat}
          ></img>
          <br />
          <br />
          <b>RESOLVE #2:</b> Please ensure the correct authentication code for
          that round has been entered, and the round has been linked.
          <br />
          <br />
          <b>RESOLVE #3:</b> Turn off the screen and turn on the screen again.
          If this does not resolve the issue please contact support for
          additional assistance.
        </p>
        <br />
        <div style={lineBreak}></div>
        <br />
        <h3 style={subjectContent}>
          <strong>The workout screen is white.</strong>
        </h3>
        <br />
        <p style={paragraphFormat}>
          <b>RESOLVE:</b> Turn the mini computer and screen off, and then power
          both back on.
        </p>
        <br />
        <div style={lineBreak}></div>
        <br />
        <h3 style={subjectContent}>
          <strong>
            The screen boots up to a Windows or Linux pc screen. (Thumb Drive
            User)
          </strong>
        </h3>
        <br />
        <p style={paragraphFormat}>
          <b>RESOLVE:</b> Please ensure that the USB in the mini computer is
          inserted all the way in to the port. Once you have done so, reboot the
          mini computer and it should boot to the correct screen. If this is not
          the case, please contact support.
        </p>
        <br />
        <h3 style={subjectContent}>
          <strong>
            The screen boots up to a Windows or Linux pc screen. (Non Thumb
            Drive User)
          </strong>
        </h3>
        <br />
        <p style={paragraphFormat}>
          <b>RESOLVE:</b> Please ensure the HDMI cable is in HDMI port 1 on the
          MINIPC. If you have the GK Series MINIPC, the ports are unlabeled.
          HDMI 1 will be the port closest to the Power Adapter. Restart the
          computer after switching the HDMI cord to HDMI port 1.
        </p>
        <br />
        <div style={lineBreak}></div>
        <br />
        <h3 style={subjectContent}>
          <strong>
            The workout is showing smaller than it should or the screen is not
            in the correct orientation.
          </strong>
        </h3>
        <br />
        <p style={paragraphFormat}>
          <b>RESOLVE:</b> Turn the SCREEN & MINIPC off and on again. It will
          automatically correct its orientation. (Please ensure the HDMI cable
          is in HDMI port 1 on the MINIPC. If you have the GK Series MINIPC, the
          ports are unlabeled. HDMI 1 will be the port closest to the Power
          Adapter. Restart the computer after switching to HDMI 1.)
        </p>
        <br />
        <div style={lineBreak}></div>
        <br />
        <h3 style={subjectContent}>
          <strong>
            A screen is showing the wrong round or a duplicate round.
          </strong>
        </h3>
        <br />
        <p style={paragraphFormat}>
          <b>RESOLVE:</b> Please contact Support.
        </p>
      </div>
      <h1 style={{ ...subjectHeader, color: "black" }}>
        <strong>CONTACT</strong>
      </h1>
      <br />
      <h4 className="lead">
        Do you have a question that wasn't covered here or need help
        troubleshooting? Email the address below to open a support ticket on
        Zendesk.
      </h4>
      <br />
      <h4>
        Contact:{" "}
        <strong>
          <a
            href="mailto:support@9roundhelp.zendesk.com"
            style={{ color: "black" }}
          >
            support@9roundhelp.zendesk.com
          </a>
        </strong>
      </h4>
    </div>
  );
};

export default Help;
