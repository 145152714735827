import React from "react";

const AndroidHeader = ({ round }) => {
  const mediaContent = require(`../../../media/2K/Rounds-0${round}.png`);
  return (
    <div className="image-container">
      <h1 className="screen-round">
        <img
          src={mediaContent}
          className="round-image"
          alt={`Round ${round}`}
        ></img>
      </h1>
    </div>
  );
};

export default AndroidHeader;
