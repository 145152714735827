/**
 *
 * @param {String} dwsTime - The timestamp of when VTS play button was pressed
 * @param {Function} setDwsTime - Timestamp state handler
 * @param {String} licenseKey - A DWS Locations VTS License Key
 * @param {Array} playcard - Array of objects of active break names & video links
 * @param {Function} setPlaycard - Playcard state handler
 * @param {Function} clearTimer - Clear timer state
 */
export const syncVts = (
  dwsTime,
  setDwsTime,
  licenseKey,
  playcard,
  setPlaycard,
  clearTimer,
) => {
  if (!dwsTime && licenseKey) {
    try {
      fetch(`https://www.9r-integrations.com/api/v1/licenses/key/${licenseKey}`)
        .then((response) => response.json())
        .then((data) => {
          if (!dwsTime) {
            const newBreaks =
              data.data.dwsActiveBreaks && data.data.dwsActiveBreaks.length > 0
                ? data.data.dwsActiveBreaks
                : playcard;
            setDwsTime(data.data.dwsTimeStamp);
            setPlaycard(newBreaks);
          }
        });
    } catch (error) {
      console.log("Error: ", error);
    }
  } else if (dwsTime && licenseKey) {
    try {
      fetch(`https://www.9r-integrations.com/api/v1/licenses/key/${licenseKey}`)
        .then((response) => response.json())
        .then((data) => {
          if (!data.data.dwsTimeStamp) clearTimer();
        });
    } catch (error) {
      console.log("Error: ", error);
    }
  } else {
    console.log("Could not find Timestamp or License");
  }
};

/**
 *
 * @param {Object} screen - The Locations Screen Object
 * @param {Function} setLicenseKey - License State Handler
 */
export const handleKey = async (screen, setLicenseKey) => {
  try {
    await fetch(
      `https://www.9r-integrations.com/api/v1/licenses/locationId/${screen.location.locationIdentifier}`,
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.data.licenseKey) setLicenseKey(data.data.licenseKey);
      });
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const checkTime = async (licenseKey, setTime, time) => {
  const newTime = time.currentTime;
  try {
    await fetch(
      `https://www.9r-integrations.com/api/v1/licenses/key/${licenseKey}`,
    )
      .then((response) => response.json())
      .then((data) =>
        setTime({ currentTime: data.data.dwsTimeStamp, previousTime: newTime }),
      );
  } catch (error) {
    console.log("Error: ", error);
  }
};
