import React, { useState } from "react";
import Button from "../../common/Button";

//#region Context & State
const ScreenListItem = ({
  screen,
  handleLinkScreen,
  setScreen,
  setAlert,
  handleShow,
  handleRefreshScreen,
  updateScreen,
  removeScreen,
  locked,
  user,
}) => {
  const screenStatus =
    screen.status &&
    new Date(screen.status.statusUpdatedAt).getTime() / 1000 + 90 >=
      new Date().getTime() / 1000;

  const [authLinkCode, setAuthLinkCode] = useState("");
  const [showAuthLink, setShowAuthLink] = useState(!screen.authToken);

  //#endregion

  const onLinkClick = (event) => {
    event.preventDefault();
    event.currentTarget.blur();
    if (!authLinkCode) {
      setAlert({
        message: "Invalid Authorization Code",
        type: "error",
      });
      return;
    }
    handleLinkScreen(screen, authLinkCode);
    //TODO: add alerts for both success and error messages on linking screen.
    // if (screen.status) {
    //   setAlert({ message: `${screen.name} has been linked!`, type: "success" });
    // } else {
    //   setAlert({
    //     message:
    //       "There was a problem linking your screen. Please try again using the correct Authorization code or contact support.",
    //     type: "error",
    //   });
    // }

    setShowAuthLink(false);
  };

  const onUnlinkClick = () => {
    updateScreen({ ...screen, authToken: null });
    setShowAuthLink(true);
    setAuthLinkCode("");
  };

  const onChange = (event) => {
    setAuthLinkCode(event.target.value);
  };

  const onUpdateClick = () => {
    handleShow();
    setScreen(screen);
  };

  const onDeleteClick = () => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete Screen: ${screen.name}?`
    );
    if (!confirmDelete) return;

    removeScreen(screen.id);
    setAlert({
      message: `Deleted Screen: ${screen.name}`,
      type: "success",
    });
  };

  const onRefreshClick = () => {
    handleRefreshScreen(screen);
    setAlert({
      message: `Screen: ${screen.name} is currently refreshing. Please wait at least 60 seconds while your screen updates.`,
      type: "success",
    });
  };

  return (
    <tr>
      <td>{screen.name}</td>
      <td>{screen.round}</td>
      {screen.location ? <td>{screen.location}</td> : <td></td>}
      <td>{screen.timeZone}</td>
      <td>{screen.language}</td>
      <td>
        <span style={{ color: screenStatus ? "green" : "red" }}>
          {screenStatus ? "Connected" : "Disconnected"}
        </span>
      </td>
      <td>{screen.createdAt}</td>
      <td>
        <div className="col-sm-8 ">
          <div className="input-group input-group-sm">
            {showAuthLink ? (
              <>
                <input
                  type="text"
                  name="authLinkCode"
                  title={`Insert Auth Link Code for Screen ${screen.round}`}
                  className="form-control"
                  placeholder="Auth Link Code"
                  value={authLinkCode}
                  onChange={onChange}
                  required={true}
                />
                <div className=" input-group-append">
                  <button
                    value="Link Screen"
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                    onClick={onLinkClick}
                  >
                    <i className="fas fa-link"></i> Link
                  </button>
                </div>
              </>
            ) : (
              !locked && (
                <Button
                  action={onUnlinkClick}
                  cssClasses={"btn btn-sm btn-danger"}
                  content={
                    <>
                      <i className="fas fa-unlink"></i> Unlink
                    </>
                  }
                />
              )
            )}
          </div>
        </div>
      </td>
      <td>
        <div className="button-group">
          {!locked && (
            <>
              <Button
                action={onUpdateClick}
                content={<i className="fas fa-pencil-alt"></i>}
                cssClasses={"btn btn-outline-warning btn-sm"}
              />
              {user && user.role === "super-admin" && (
                <Button
                  action={onDeleteClick}
                  content={<i className="fas fa-trash-alt"></i>}
                  cssClasses={"btn btn-outline-danger btn-sm"}
                />
              )}
            </>
          )}
          <Button
            action={onRefreshClick}
            content={<i className="fas fa-sync"></i>}
            cssClasses={"btn btn-outline-info btn-sm"}
          />
        </div>
      </td>
    </tr>
  );
};

export default ScreenListItem;
