import axios from "axios";

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.authorization;
  }
};

export const setHeader = (header, value) => {
  if (header && value) {
    axios.defaults.headers[header] = value;
  } else {
    throw new Error(`Missing header or value`);
  }
};

export default setAuthToken;
