import React, { useContext, useEffect } from "react";
import ReleaseContext from "../../context/release/ReleaseContext";

export const Appversion = () => {
  const releaseContext = useContext(ReleaseContext);
  const { getLatestRelease } = releaseContext;

  useEffect(() => {
    getLatestRelease();

    // eslint-disable-next-line
  }, []);

  const version =
    localStorage.getItem("app_version") || global.config.product.app.version;

  return <small>App Version {version}</small>;
};
