import React from "react";
import WorkoutList from "../workout/WorkoutList";
import BackButton from "../../common/BackButton";

const RoundItem = ({ match }) => {
  const roundNumber = match.params.roundNumber;

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h2>Round {roundNumber}</h2>
        <BackButton url={match.url} />
      </div>
      <WorkoutList match={match} roundNumber={roundNumber} />
    </>
  );
};

export default RoundItem;
