import React from "react";

const TimedRoutine = ({ routine, isLrVideo }) => {
  const { videoLink, lrVideoLink } = routine;
  return (
    <div className="video-container">
      <>
        <video
          src={isLrVideo ? lrVideoLink : videoLink}
          autoPlay={true}
          loop={true}
          muted={true}
          preload="metadata"
        ></video>
      </>
    </div>
  );
};

export default TimedRoutine;
