import React from "react";

const TimedExercise = ({ videoLink, videos }) => {
  return (
    <>
      <video
        className={videos === 2 ? "Timer-transition-2" : "Timer-transition-1"}
        src={videoLink}
        autoPlay={true}
        loop={true}
        muted={true}
      ></video>
    </>
  );
};

export default TimedExercise;
