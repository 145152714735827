module.exports = {
  success: {
    notification: {
      display: "alert alert-success",
      icon: "fas fa-info-circle",
      btn: "btn btn-success",
      design: {
        color: "darkgreen",
        type: "Success",
        borderTop: "1px solid darkgreen",
      },
    },
  },
  error: {
    notification: {
      display: "alert alert-danger",
      icon: "fas fa-exclamation-circle",
      btn: "btn btn-danger",
      design: {
        color: "darkred",
        type: "Error",
        borderTop: "1px solid darkred",
      },
    },
  },
  warning: {
    notification: {
      display: "alert alert-warning",
      icon: "fas fa-exclamation-triangle",
      btn: "btn btn-warning",
      design: {
        color: "goldenrod",
        type: "Warning",
        borderTop: "1px solid goldenrod",
      },
    },
  },
  code: {
    green: {
      icon: "fa fa-check-circle",
      color: "green",
      tooltip: "All screens are connected",
    },
    red: {
      icon: "fas fa-times-circle",
      color: "red",
      message: "Urgent: Action Needed",
      tooltip: "All screens are disconnected",
    },
    yellow: {
      icon: "fas fa-exclamation-triangle",
      color: "goldenrod",
      message: "Warning: Action Needed",
      tooltip: "One or more screens are disconnected",
    },
  },
};
