import React, { useEffect, useState } from "react";
import "./styles.css";

const Alert = ({ notification, message, id, closeAlert, addBorder }) => {
  const [animationEffect, setAnimationEffect] = useState("alert-animation");

  useEffect(() => {
    const autoExpire = setTimeout(() => {
      fadeOut(id);
    }, 9850);
    return () => clearTimeout(autoExpire);
    // eslint-disable-next-line
  }, []);

  const fadeOut = (id) => {
    setAnimationEffect("alert-animation-after");
    const fade = setTimeout(() => {
      closeAlert(id);
    }, 150);
    return () => clearTimeout(fade);
  };

  return (
    <div className="alert-box">
      <div
        className={`${notification.display} ${animationEffect}`}
        role="alert"
        id={id}
      >
        <button
          className={`alert-button alert-button-box ${notification.btn} btn-sm`}
          style={{ top: 2, right: 1 }}
          aria-label="Close"
          onClick={() => fadeOut(id)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <i className={`${notification.icon} alert-icon`}>
          <span className="alert-icon-message">
            {` ${notification.design.type}`}
          </span>
        </i>
        {addBorder !== "" ? (
          <div>
            <div
              className="alert-message"
              style={{ borderTop: addBorder }}
            ></div>
            <span style={{ fontSize: "15px" }}>{message}</span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Alert;
