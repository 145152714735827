import React, { useContext, useEffect } from "react";
import Navbar from "../../components/layout/Navbar";
import AuthContext from "../../context/auth/AuthContext";
import Sidebar from "../../components/layout/Sidebar";
import "./admin.css";
import { Route, Switch } from "react-router-dom";
import AdminRoute from "../../components/routing/AdminRoute";
import UserState from "../../context/user/UserState";
import RoundList from "../../components/admin-page/round/RoundList";
import RoundItem from "../../components/admin-page/round/RoundItem";
import RoundState from "../../context/round/RoundState";
import WorkoutState from "../../context/workout/WorkoutState";
import WorkoutItem from "../../components/admin-page/workout/WorkoutItem";
import Profile from "../../components/admin-page/profile/Profile";
import Help from "../../components/admin-page/help/Help";
import Footer from "../../components/layout/Footer";
import ScreenState from "../../context/screen/ScreenState";
import LocationState from "../../context/location/LocationState";
import LocationList from "../../components/admin-page/location/LocationList";
import MyLocationList from "../../components/admin-page/mylocation/MyLocationList";
import MyLocationItem from "../../components/admin-page/mylocation/MyLocationItem";
import LocationItem from "../../components/admin-page/location/LocationItem";
import AlertState from "../../context/alert/AlertState";
import AlertContainer from "../../components/layout/AlertContainer";
import UserList from "../../components/admin-page/user/UserList";
import UserDetail from "../../components/admin-page/user/UserDetail";
import LoggerList from "../../components/admin-page/logger/LoggerList";
import LoggerState from "../../context/logger/LoggerState";
import ReleaseList from "../../components/admin-page/release/ReleaseList";
import ReleaseState from "../../context/release/ReleaseState";
import Notes from "../../components/admin-page/help/Notes";
import Dashboard from "../../components/admin-page/dashboard/Dashboard";
import LocationSchedule from "../../components/admin-page/location/LocationSchedule";
import System from "../../components/admin-page/system/System";

function AdminPage({ match, ...props }) {
  const authContext = useContext(AuthContext);

  const { isAuthenticated, user, logout, loadUser } = authContext;

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <AlertState>
      <LoggerState>
        <ReleaseState>
          <UserState>
            <RoundState>
              <Navbar logout={logout} user={user} />
              <div className="container-fluid">
                <div className="row">
                  <Sidebar isAuthenticated={isAuthenticated} user={user} />
                  <main className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                    <AlertContainer location={props.location} />
                    <ScreenState>
                      <WorkoutState>
                        <LocationState>
                          <Switch>
                            <Route
                              exact
                              path={[
                                `${match.path}/dashboard`,
                                `${match.path}/`,
                              ]}
                              component={Dashboard}
                            />

                            <AdminRoute
                              exact
                              path={`${match.path}/rounds`}
                              component={RoundList}
                            />

                            <AdminRoute
                              exact
                              path={`${match.path}/rounds/:roundNumber`}
                              component={RoundItem}
                            />

                            <AdminRoute
                              exact
                              path={`${match.path}/rounds/:roundNumber/workout/:id`}
                              component={WorkoutItem}
                            />

                            <AdminRoute
                              exact
                              path={`${match.path}/rounds/:roundNumber/workout`}
                              component={WorkoutItem}
                            />

                            <AdminRoute
                              exact
                              path={`${match.path}/logs`}
                              component={LoggerList}
                            />

                            <AdminRoute
                              exact
                              path={`${match.path}/releases`}
                              component={ReleaseList}
                            />

                            <AdminRoute
                              exact
                              path={`${match.path}/system`}
                              component={System}
                            />

                            <AdminRoute
                              exact
                              path={`${match.path}/users`}
                              component={UserList}
                            />

                            <AdminRoute
                              exact
                              path={`${match.path}/users/:id`}
                              component={UserDetail}
                              match={match}
                            />

                            <AdminRoute
                              exact
                              path={`${match.path}/locations`}
                              component={LocationList}
                              match={match}
                            />

                            <AdminRoute
                              exact
                              path={`${match.path}/locations/:id`}
                              component={LocationItem}
                              match={match}
                            />

                            <AdminRoute
                              exact
                              path={`${match.path}/locations/:id/schedule`}
                              component={LocationSchedule}
                              match={match}
                            />

                            <AdminRoute
                              exact
                              path={`${match.path}/profile`}
                              component={Profile}
                              match={match}
                            />

                            <Route
                              exact
                              path={`${match.path}/help`}
                              render={(props) => <Help props={props} />}
                            />
                            <Route
                              exact
                              path={`${match.path}/release-notes`}
                              render={(props) => <Notes props={props} />}
                            />
                            <Route
                              exact
                              path={`${match.path}/my-locations`}
                              render={(props) => (
                                <MyLocationList
                                  props={props}
                                  match={props.match}
                                />
                              )}
                            />
                            <Route
                              exact
                              path={`${match.path}/my-locations/:id`}
                              render={(props) => (
                                <MyLocationItem
                                  props={props}
                                  match={props.match}
                                />
                              )}
                            />
                            <Route render={(props) => <h1>404</h1>} />
                          </Switch>
                        </LocationState>
                      </WorkoutState>
                    </ScreenState>
                    <Footer />
                  </main>
                </div>
              </div>
            </RoundState>
          </UserState>
        </ReleaseState>
      </LoggerState>
    </AlertState>
  );
}

export default AdminPage;
