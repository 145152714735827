import React, { useReducer } from "react";
import TimerContext from "./TimerContext";
import timerReducer from "./timerReducer";
import {
  UPDATE_DWS_TIME,
  UPDATE_COLOR,
  UPDATE_COUNTDOWN,
  UPDATE_EXERCISE,
  UPDATE_ITERATION,
  UPDATE_LICENSE_KEY,
  UPDATE_PLAYCARD,
  UPDATE_THIRTY_SECOND,
  UPDATE_THREE_MINUTE,
  UPDATE_TIME,
  CLEAR_TIMER,
} from "./timerTypes";

const TimerState = (props) => {
  const initialState = {
    dwsTime: null,
    color: null,
    playcard: [],
    exercise: null,
    threeMinute: false,
    thirtySecond: false,
    licenseKey: null,
    iteration: 0,
    countDown: {
      convertedFormat: "",
      converted: 0,
      thirtyFormat: "0:30",
      thirty: 30,
    },
    time: {
      previousTime: null,
      currentTime: null,
    },
  };

  const [state, dispatch] = useReducer(timerReducer, initialState);

  const setDwsTime = (dwsTime) => {
    dispatch({ type: UPDATE_DWS_TIME, payload: dwsTime });
  };

  const setColor = (color) => {
    dispatch({ type: UPDATE_COLOR, payload: color });
  };

  const setPlaycard = (playcard) => {
    dispatch({ type: UPDATE_PLAYCARD, payload: playcard });
  };

  const setExercise = (exercise) => {
    dispatch({ type: UPDATE_EXERCISE, payload: exercise });
  };

  const setThirtySecond = (sequence) => {
    dispatch({ type: UPDATE_THIRTY_SECOND, payload: sequence });
  };

  const setThreeMinute = (sequence) => {
    dispatch({ type: UPDATE_THREE_MINUTE, payload: sequence });
  };

  const setLicenseKey = (licenseKey) => {
    dispatch({ type: UPDATE_LICENSE_KEY, payload: licenseKey });
  };

  const setIteration = (iteration) => {
    dispatch({ type: UPDATE_ITERATION, payload: iteration });
  };

  const setCountDown = (countdown) => {
    dispatch({ type: UPDATE_COUNTDOWN, payload: countdown });
  };

  const setTime = (time) => {
    dispatch({ type: UPDATE_TIME, payload: time });
  };

  const clearTimer = () => {
    dispatch({ type: CLEAR_TIMER });
  };

  return (
    <TimerContext.Provider
      value={{
        dwsTime: state.dwsTime,
        color: state.color,
        playcard: state.playcard,
        exercise: state.exercise,
        threeMinute: state.threeMinute,
        thirtySecond: state.thirtySecond,
        licenseKey: state.licenseKey,
        iteration: state.iteration,
        countDown: state.countDown,
        time: state.time,
        setTime,
        setColor,
        setCountDown,
        setDwsTime,
        setExercise,
        setPlaycard,
        setIteration,
        setLicenseKey,
        setThirtySecond,
        setThreeMinute,
        clearTimer,
      }}
    >
      {props.children}
    </TimerContext.Provider>
  );
};

export default TimerState;
