import React from 'react';
import { useState } from "react";
import "./SearchBar.css";

const SearchBar = ({ subject, setSearchData = null, paginate = null }) => {
  const [query, setQuery] = useState("");

  const handleClick = async () => {
    if (setSearchData) {
      setSearchData(query);
      setQuery("");
    }
    if (paginate) paginate(1);
  };

  return (
    <div className="SearchBar">
      <div className="input-group">
        <div className="input-group-prepend">
          <button
            className="input-group-text"
            id="btnGroupAddon"
            aria-label="search-button"
            onClick={handleClick}
          >
            <i className="fas fa-search"></i>
          </button>
        </div>
        <input
          type="search"
          id="form1"
          className="form-control"
          onKeyDown={(e) => e.key === "Enter" && handleClick()}
          placeholder={`Search ${subject} ...`}
          onChange={e => setQuery(e.target.value)}
          value={query}
        />
      </div>
    </div>
  );
};
export default SearchBar;
