import React, { useLayoutEffect, useState } from "react";
import "./Modal.css";

export const ModalHeader = (props) => {
  return <div className="modal-header-9r">{props.children}</div>;
};
export const ModalTitle = (props) => {
  return <div className="modal-title-9r">{props.children}</div>;
};
export const ModalBody = (props) => {
  return <div className="modal-body-9r">{props.children}</div>;
};
export const ModalFooter = (props) => {
  return <div className="modal-footer-9r">{props.children}</div>;
};

/**
 * Modal Optional Props:
 * closeButton: boolean (Small close button on top right. Enabled by default);
 * overlay: string/CSS Class (To use classic bootstrap set overlay="modal-overlay-default");
 */

const Modal = (props) => {
  const [modalAnimation, setModalAnimation] = useState(
    "modal-show-9r modal-show-before-9r"
  );
  const [bodyScroll, setBodyScroll] = useState("");

  const { show, onHide, closeButton, overlay } = props;

  // Handle animation & modal on close
  const handleClick = () => {
    if (show && onHide) {
      setModalAnimation("modal-show-9r modal-show-after-9r");
      setTimeout(() => {
        setModalAnimation("modal-show-9r modal-show-before-9r");
        onHide();
      }, 140);
    }
  };

  // Handle spring animation when clicking outside of modal
  const handleBounds = () => {
    if (show) {
      setModalAnimation("modal-show-9r modal-spring-9r");
      const handleSpring = setTimeout(() => {
        setModalAnimation("modal-show-9r");
      }, 140);
      return () => clearTimeout(handleSpring);
    }
  };

  // Set body overflow to hidden on show, return original style on close
  useLayoutEffect(() => {
    if (show) {
      setBodyScroll(window.getComputedStyle(document.body).overflow);
      document.body.style.overflow = "hidden";
      return () => (document.body.style.overflow = bodyScroll);
    }
  }, [show, bodyScroll]);

  return (
    show && (
      <React.Fragment>
        <div className={modalAnimation} role="dialog" aria-modal="true">
          {closeButton ||
            (closeButton === undefined && (
              <button
                aria-label="Close"
                type="button"
                title="Close"
                className={"modal-button-9r"}
                onClick={handleClick}
              >
                &times;
              </button>
            ))}
          {props.children}
          <button
            type="button"
            title="Close"
            className="btn btn-secondary modal-close-9r"
            onClick={handleClick}
          >
            Close
          </button>
        </div>
        <div
          onClick={handleBounds}
          className={overlay ? overlay : "modal-overlay-9r"}
          data-backdrop="static"
          data-keyboard="false"
        ></div>
      </React.Fragment>
    )
  );
};

export default Modal;
