import React from "react";

const WorkoutRoutineItem = ({
  routine,
  handleRoutine,
  handleShow,
  deleteRoutine,
  setAlert,
  iFrameHandler,
}) => {
  const onEditClick = (e) => {
    e.preventDefault();
    handleRoutine(routine);
    handleShow();
  };

  const onDeleteClick = (e) => {
    e.preventDefault();
    const confirmDelete = window.confirm(
      `Are you sure you want to delete routine: "${routine.name}" ?`,
    );
    if (!confirmDelete) return;

    deleteRoutine(routine._id);
    setAlert({
      message: `Deleted Routine: ${routine.name}`,
      type: "success",
    });
  };

  const handleIframe = () => {
    if (!iFrameHandler.returnFrame()) {
      iFrameHandler.setFrame(true);
      iFrameHandler.setContent(routine);
    } else {
      iFrameHandler.setFrame(false);
      iFrameHandler.setContent(null);
    }
  };

  return (
    <>
      {routine && (
        <tr>
          <td>{routine.name}</td>
          <td>{routine.text}</td>
          <td>{routine.step}</td>
          <td>
            <a target={"blank"} href={routine.videoLink}>
              {routine.videoLink.substring(0, 30) + "..."}
            </a>
          </td>
          <td>{new Date(routine.createdAt).toLocaleString()}</td>
          <td>{new Date(routine.updatedAt).toLocaleString()}</td>
          <td>
            <div className="btn-group btn-group-sm">
              <button className="btn btn-outline-warning" onClick={onEditClick}>
                <i className="fas fa-pencil-alt"></i>
              </button>
              <button
                className="btn btn-outline-danger"
                onClick={onDeleteClick}
              >
                <i className="fas fa-trash-alt"></i>
              </button>
              <button
                className="btn btn-outline-dark"
                onClick={() => handleIframe()}
              >
                <i className="fas fa-eye"></i>
              </button>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default WorkoutRoutineItem;
