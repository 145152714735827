import React, { useContext, useState, useEffect } from "react";
import LocationContext from "../../../context/location/LocationContext";
import { languagesUSCA } from "../../../utils/languageCodes";
import { timeZonesUS } from "../../../utils/timeZones";
import BackButton from "../../common/BackButton";
import Spinner from "../../layout/Spinner";
import LocationScreenList from "../location/LocationScreenList";

const MyLocationItem = ({ match }) => {
  const locationId = match ? match.params.id : undefined;

  const locationContext = useContext(LocationContext);
  const { error, loading, current, getLocation, clearCurrent } =
    locationContext;

  const [location, setLocation] = useState({
    name: "",
    _id: undefined,
    language: "",
    timeZone: "",
    locationIdentifier: "",
    isActive: "",
    isEnabled: "",
    screens: [],
    vtsEnabled: "",
  });

  const handleLocation = (location) => {
    setLocation({ ...current, ...location });
  };

  useEffect(() => {
    if (!current) {
      getLocation(locationId);
    }

    handleLocation(current);

    // Update the auth code for security every hour
    const interval = setInterval(() => {
      getLocation(locationId);
    }, 45 * 1000);

    return () => clearInterval(interval);

    // eslint-disable-next-line
  }, [error, current, loading]);

  // On unmount clear current
  useEffect(() => {
    return () => clearCurrent();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {current && !loading && (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h2>{location.name}</h2>
            <div className="btn-group ">
              <BackButton url={match.url} />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-6">
              <label htmlFor="">Name</label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={location.name || ""}
                disabled={true}
                required={true}
              />
            </div>
            <div className="form-group col-6">
              <label htmlFor="">9Round Number</label>
              <input
                className="form-control"
                type="text"
                name="locationIdentifier"
                value={location.locationIdentifier || ""}
                disabled={true}
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-3">
              <label htmlFor="">Time Zone</label>
              <select
                type="text"
                name="timeZone"
                className="form-control"
                value={location.timeZone || ""}
                disabled={true}
                required={true}
              >
                <option></option>
                {timeZonesUS.map((tz, i) => (
                  <option key={i} value={tz}>
                    {tz}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group col-3">
              <label htmlFor="language">Language</label>
              <select
                className="form-control"
                name="language"
                disabled={true}
                required={true}
                value={location.language || ""}
              >
                <option></option>
                {languagesUSCA.map((lang, i) => (
                  <option key={i} value={lang}>
                    {lang}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <LocationScreenList locked={true} location={location} />
        </>
      )}
      {(!current || loading) && <Spinner />}
    </>
  );
};

export default MyLocationItem;
