import {
  USER_ERROR,
  GET_USERS,
  ADD_USER,
  DELETE_USER,
  UPDATE_USER,
  SET_CURRENT,
  CLEAR_CURRENT,
  // FILTER_USERS,
  // CLEAR_FILTER,
  // CLEAR_USERS,
} from "./userTypes";
export default (state, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
        error: null,
      };
    case USER_ERROR:
      return { ...state, error: action.payload };
    case ADD_USER:
      return {
        ...state,
        loading: false,
        current: action.payload,
        users: [action.payload, ...state.users],
        error: null,
      };
    case UPDATE_USER:
      return {
        ...state,
        error: null,
        current: action.payload,
        loading: false,
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((contact) => contact._id !== action.payload),
        loading: false,
        error: null,
      };
    case SET_CURRENT:
      return {
        ...state,
        error: null,
        current: action.payload,
        loading: false,
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        error: null,
        current: null,
        loading: false,
      };
    // case CLEAR_USERS:
    //   return {
    //     ...state,
    //     contacts: null,
    //     filtered: null,
    //     error: null,
    //     current: null,
    //   };
    // case FILTER_USERS:
    //   return {
    //     ...state,
    //     filtered: state.contacts.filter((contact) => {
    //       const regex = new RegExp(`${action.payload}`, "gi");
    //       return (
    //         contact.name.match(regex) ||
    //         contact.email.match(regex) ||
    //         contact.phone.match(regex)
    //       );
    //     }),
    //     loading: false,
    //   };
    // case CLEAR_FILTER:
    //   return {
    //     ...state,
    //     filtered: null,
    //   };
    default:
      return state;
  }
};
