import React, { useContext, useState, useEffect } from "react";
import Spinner from "../../layout/Spinner";
import SearchBar from "../../common/SearchBar";
import ReleaseContext from "../../../context/release/ReleaseContext";
import AlertContext from "../../../context/alert/AlertContext";
import ReleaseListItem from "./ReleaseListItem";
import Pagination from "../../common/Pagination";
import paginateResults from "../../../utils/paginateResults";
import AddRelease from "./AddRelease";
import { checkFlag } from "../../../utils/alertFlag";

const ReleaseList = () => {
  //#region State & Context Management

  const releaseContext = useContext(ReleaseContext);
  const alertContext = useContext(AlertContext);
  const { getReleases, addRelease, releases, loading, error } = releaseContext;
  const { setAlert, flag, setFlag, removeFlag } = alertContext;

  // Manage release in state
  const [release, setRelease] = useState({
    title: null,
    type: null,
    description: null,
    version: null,
    date: null,
  });

  // Manage show state for modal
  const [show, setShow] = useState(false);

  // Search bar state
  const [searchData, setSearchData] = useState([]);

  // Pagination state
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(null);
  const perPage = 15;

  //#endregion

  //#region Handlers

  // Handle creating a release & set flag for notifications
  const handleCreateRelease = (release) => {
    setFlag(release, "addRelease");
    addRelease(release);
  };

  // Validate flagged data and display an alert
  const handleFlag = () => {
    checkFlag(flag, setAlert, error);
    removeFlag();
  };

  const handleRelease = (release) => setRelease(release);

  const handleShow = () => {
    setRelease({
      title: null,
      type: null,
      description: null,
      version: null,
      date: null,
    });
    setShow(!show);
  };

  const handleClick = () => {
    setShow(!show);
  };

  //#endregion

  //#region Lifecyle

  useEffect(() => {
    getReleases();
    // eslint-disable-next-line
  }, [error, loading]);

  useEffect(() => {
    getReleases(searchData);
    // eslint-disable-next-line
  }, [searchData, loading]);

  // get current page & update
  useEffect(() => {
    updateResults();
    // eslint-disable-next-line
  }, [page, releases]);

  useEffect(() => {
    if (flag) handleFlag();
    //eslint-disable-next-line
  }, [addRelease]);

  //#endregion

  //#region Functions

  // Update pagination results
  const updateResults = () => {
    paginateResults(releases, page, perPage, setResults);
  };

  // paginate page
  const paginate = (newPage) => {
    setPage(newPage);
  };

  //#endregion

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h2>Releases (ADMIN)</h2>
        <button className="btn btn-success" onClick={handleClick}>
          Create
        </button>
      </div>
      <SearchBar
        subject={"releases"}
        setSearchData={setSearchData}
        paginate={paginate}
      />
      {show && (
        <AddRelease
          show={show}
          handleShow={handleShow}
          release={release}
          handleRelease={handleRelease}
          addRelease={handleCreateRelease}
          setAlert={setAlert}
          handleClose={handleClick}
        />
      )}
      <div className="table-responsive">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th>Date</th>
              <th>Title</th>
              <th>Type</th>
              <th>Description</th>
              <th>Version</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th></th>
            </tr>
          </thead>
          {results && !loading && (
            <tbody>
              {results.map((release) => (
                <ReleaseListItem key={release.id} release={release} />
              ))}
            </tbody>
          )}
        </table>
        {releases && !loading && releases.length > perPage && (
          <Pagination
            currentPage={page}
            perPage={perPage}
            total={releases.length}
            paginate={paginate}
          />
        )}
      </div>
      {(!releases || loading) && <Spinner />}
      {!loading && releases && releases.length < 1 && <p>No releases found</p>}
    </>
  );
};

export default ReleaseList;
