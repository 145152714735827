import React, { useEffect } from "react";
import Footer from "../../layout/Footer";

const ScreenError = ({ isScreenAuth, checkFunction }) => {
  useEffect(() => {
    const interval = setInterval(() => {
      if (isScreenAuth) {
        checkFunction();
      }
    }, 30 * 1000);

    return () => clearInterval(interval);

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="center">
        <div className="form-signin">
          <img
            className="mb-4"
            src="/logo_black.png"
            alt=""
            width="150"
            height="150"
          />
          <h1 className="h3 mb-3 font-weight-bold">
            9Round {global.config.product.app.name} Display System
          </h1>
          <h2 className="text-9r-red">Please Contact Support.</h2>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ScreenError;
