/**
 * @function checkScreenStatus
 * @param {String} locationId - The ID of the location to check
 * @param {Object} locationStatus - An Object Array of locations that each have an Object Array of Screens
 * @returns {Number} A number code to determine a locations screen status (0 = green, 1 = yellow, 2 = red)
 */
export const checkScreenStatus = (locationId, locationStatus) => {
  let screenList;
  locationStatus.forEach((location) => {
    if (location.id === locationId) screenList = location;
  });
  let count = 0;
  screenList &&
    screenList.screens.forEach((screen) => {
      const screenStatus =
        screen.status &&
        new Date(screen.status.statusUpdatedAt).getTime() / 1000 + 90 >=
          new Date().getTime() / 1000;
      if (!screenStatus) count++;
    });
  if (count < 1) return 0;
  if (screenList && screenList.screens && count === screenList.screens.length)
    return 2;
  return 1;
};
