import React, { useContext, useEffect } from "react";
import ScreenContext from "../../../../../context/screen/ScreenContext";
import TimerContext from "../../../../../context/timer/TimerContext";
import AndroidRoutineList from "./AndroidRoutineList";
import TimedInstructionList from "../TimedInstructionList";
import TimedSequence from "../TimedSequence";
import ScreenError from "../../ScreenError";
import AndroidExercise from "./AndroidExercise";
import Spinner from "../../../../layout/Spinner";
import androidBtn from "../media/Android.png";
import { syncVts, handleKey } from "../utils/syncVts";
import { findExercise } from "../utils/activeBreaks";
import "../Timer.css";
import AndroidHeader from "./AndroidHeader";

const AndroidWorkout = () => {
  const screenContext = useContext(ScreenContext);
  const {
    workout,
    isLrVideo,
    getCurrentRoundWorkout,
    loading,
    sendScreenStatus,
    screen,
    isScreenAuth,
  } = screenContext;

  const timerContext = useContext(TimerContext);
  const {
    dwsTime,
    color,
    playcard,
    exercise,
    licenseKey,
    time,
    setTime,
    setDwsTime,
    setPlaycard,
    setLicenseKey,
    clearTimer,
  } = timerContext;

  const getCurrentWorkoutHandler = () => {
    getCurrentRoundWorkout(screen.round);
  };

  useEffect(() => {
    if (!workout) getCurrentWorkoutHandler();
    if (!screen) clearTimer();
    // eslint-disable-next-line
  }, [workout, loading, screen]);

  // Get VTS license key from auth server
  useEffect(() => {
    handleKey(screen, setLicenseKey);
    return () => clearTimer();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      // send screen status update & check if workout should update
      sendScreenStatus(screen._id);
      // Check if license has DWS Enabled then get Active Breaks & Timestamp
      syncVts(
        dwsTime,
        setDwsTime,
        licenseKey,
        playcard,
        setPlaycard,
        clearTimer,
      );
    }, 30 * 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  });

  useEffect(() => {
    if (dwsTime && !time.currentTime && !time.previousTime)
      setTime({
        currentTime: Number(dwsTime),
        previousTime: Number(dwsTime),
      });
    //eslint-disable-next-line
  }, [dwsTime]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : !workout || !workout.routines || workout.routines.length < 1 ? (
        <ScreenError
          isScreenAuth={isScreenAuth}
          checkFunction={getCurrentWorkoutHandler}
        />
      ) : (
        <>
          {exercise && (
            <AndroidExercise
              videoLink={findExercise(exercise.name, "videoLink")}
              videos={workout.routines.length}
              androidBtn={androidBtn}
            />
          )}
          <div className={`videos-${workout.routines.length}`}>
            {screen && dwsTime && <TimedSequence />}
            {screen && screen.round && <AndroidHeader round={screen.round} />}
            <AndroidRoutineList
              routines={workout.routines}
              isLrVideo={isLrVideo}
              androidBtn={androidBtn}
            ></AndroidRoutineList>
            <TimedInstructionList
              routines={workout.routines}
              contentHeader={workout.subHeader}
              exercise={
                exercise
                  ? findExercise(exercise.name, screen.language + "Name")
                  : ""
              }
              color={color}
            ></TimedInstructionList>
          </div>
        </>
      )}
    </>
  );
};

export default AndroidWorkout;
