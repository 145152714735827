import React, { useContext, useEffect } from "react";
import ScreenContext from "../../context/screen/ScreenContext";
import "./screen.css";
import ScreenAuthentication from "../../components/screen-page/new-workout/ScreenAuthentication";
import Spinner from "../../components/layout/Spinner";
import ScreenLinkState from "../../context/screenLink/ScreenLinkState";
import Workout from "../../components/screen-page/new-workout/Workout";
import OperatingSystem from "./OperatingSystem";
import ScreenError from "../../components/screen-page/new-workout/ScreenError";
import TimerState from "../../context/timer/TimerState";

const NewScreen = () => {
  const screenContext = useContext(ScreenContext);
  const {
    loading,
    error,
    getScreenByToken,
    screen,
    isScreenAuth,
    setIsScreenAuth,
    updateScreen,
  } = screenContext;

  const getScreenByTokenHandler = () => {
    getScreenByToken();
  };

  useEffect(() => {
    if (!screen) {
      getScreenByTokenHandler();
    }

    if (!localStorage["screen_auth_token"]) {
      setIsScreenAuth(false);
    } else if (!isScreenAuth) {
      setIsScreenAuth(true);
    }

    if (screen && screen.hardRefreshFlag) {
      updateScreen(screen.id, { hardRefreshFlag: false, ...screen }).then(
        () => {
          window.location.reload();
        },
      );
    }
    // eslint-disable-next-line
  }, [loading, error, screen, isScreenAuth]);

  return (
    <ScreenLinkState>
      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <>
            {screen && isScreenAuth ? (
              screen.location &&
              screen.location.isEnabled &&
              screen.location.vtsEnabled ? (
                <div className="screen-container">
                  <TimerState>
                    <OperatingSystem screen={screen} />
                  </TimerState>
                </div>
              ) : screen.location && screen.location.isEnabled ? (
                <div className="screen-container">
                  <Workout screen={screen} />
                </div>
              ) : (
                <ScreenError
                  isScreenAuth={isScreenAuth}
                  checkFunction={getScreenByTokenHandler}
                />
              )
            ) : !isScreenAuth ? (
              <ScreenAuthentication setIsScreenAuth={setIsScreenAuth} />
            ) : (
              <ScreenError
                isScreenAuth={isScreenAuth}
                checkFunction={getScreenByTokenHandler}
              />
            )}
          </>
        )}
      </div>
    </ScreenLinkState>
  );
};

export default NewScreen;
