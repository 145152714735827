import React from "react";

const AndroidRoutine = ({ routine, androidBtn }) => {
  const { videoLink } = routine;
  return (
    <div className="video-container">
      <video
        autoPlay={true}
        loop={true}
        muted={true}
        poster={androidBtn}
        playsInline={true}
      >
        <source src={videoLink} type="video/mp4" />
      </video>
    </div>
  );
};

export default AndroidRoutine;
