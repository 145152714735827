import React, { useState, useEffect } from "react";
import WorkoutSchedule from "./WorkoutSchedule";
import Spinner from "../../layout/Spinner";
import { createSchedule, daysOfWeek } from "../../../utils/dateHandlers";

const ScheduleStatus = ({ workouts, getWorkoutsByDate, clearWorkouts }) => {
  const [schedule, setSchedule] = useState(null);

  const rollingDays = daysOfWeek(7);

  useEffect(() => {
    if (workouts && workouts.length < 1) getWorkoutsByDate();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (workouts && workouts.length > 0 && !schedule) {
      const rollingSchedule = createSchedule(workouts);
      setSchedule(rollingSchedule);
    }
    //eslint-disable-next-line
  }, [workouts]);

  useEffect(() => {
    return () => clearWorkouts();
    // eslint-disable-next-line
  }, []);

  const setCheck = () => {
    const element = document.querySelector("#verified");
    element.classList.remove("fa-check-circle");
    element.classList.remove("text-success");
    element.classList.add("fa-times-circle");
    element.classList.add("text-danger");
  };

  return (
    <>
      <h6 className="pb-1">
        <p>
          <span style={{ textDecoration: "underline" }}>
            Rolling Schedule Status
          </span>{" "}
          <span>
            <i id="verified" className="fa fa-check-circle text-success"></i>
          </span>
        </p>
      </h6>
      {schedule ? (
        <table className="table-bordered table table-striped table-responsive-sm">
          <>
            <thead className="thead-dark ">
              <tr className="text-center">
                <th scope="col">Round #</th>
                <th scope="col" style={{ backgroundColor: "darkgreen" }}>
                  {rollingDays[0]}
                </th>
                <th scope="col">{rollingDays[1]}</th>
                <th scope="col">{rollingDays[2]}</th>
                <th scope="col">{rollingDays[3]}</th>
                <th scope="col">{rollingDays[4]}</th>
                <th scope="col">{rollingDays[5]}</th>
                <th scope="col">{rollingDays[6]}</th>
              </tr>
            </thead>
            <WorkoutSchedule schedule={schedule} setCheck={setCheck} />
          </>
        </table>
      ) : (
        <>
          <Spinner />
        </>
      )}
    </>
  );
};

export default ScheduleStatus;
