import React from "react";
import { Link } from "react-router-dom";

const BackButton = ({ url }) => {
  const backUrl = url.split("/").slice(0, -1).join("/");

  return (
    <Link className="btn btn-secondary" to={backUrl}>
      <i className="fas fa-arrow-left"></i> Back
    </Link>
  );
};

export default BackButton;
