import React from "react";
import TimedRoutine from "./TimedRoutine";

const TimedRoutineList = ({ routines, isLrVideo }) => {
  return (
    <div className="row" style={{ backgroundColor: "black" }}>
      {routines.map((routine, i) => (
        <TimedRoutine
          key={routine._id}
          routine={routine}
          routineCount={routines.length}
          isLrVideo={isLrVideo}
        ></TimedRoutine>
      ))}
    </div>
  );
};

export default TimedRoutineList;
