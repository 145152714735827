export const GET_ROUNDS = "GET_ROUNDS";
export const ROUND_ERROR = "ROUND_ERROR";
export const ADD_ROUND = "ADD_ROUND";
export const UPDATE_ROUND = "UPDATE_ROUND";
export const DELETE_ROUND = "DELETE_ROUND";
export const CLEAR_ROUNDS = "CLEAR_ROUNDS";
export const FILTER_ROUNDS = "FILTER_ROUNDS";
export const SET_CURRENT = "SET_CURRENT";
export const CLEAR_CURRENT = "CLEAR_CURRENT";
export const CLEAR_FILTER = "CLEAR_FILTER";
