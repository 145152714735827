import React, { useContext, useState, useEffect } from "react";
import LocationContext from "../../../context/location/LocationContext";
import Spinner from "../../layout/Spinner";
import AddScreen from "../screen/AddScreen";
import ScreenListItem from "../screen/ScreenListItem";
import ScreenContext from "../../../context/screen/ScreenContext";
import AuthContext from "../../../context/auth/AuthContext";
import AlertContext from "../../../context/alert/AlertContext";

const LocationScreenList = ({ locked, location }) => {
  //#region Context & State Management

  // Alert Context
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  // Screen Context
  const screenContext = useContext(ScreenContext);
  const { updateScreen, linkScreen, refreshScreen, removeScreen } =
    screenContext;

  // Location Context
  const locationContext = useContext(LocationContext);
  const { loading, addLocationScreen, getLocation } = locationContext;

  // Auth Context
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  // Manage Location in state
  const [screen, setScreen] = useState({
    location: location.id,
    name: "",
    _id: undefined,
    round: "",
    contentId: undefined,
    language: location.language,
    status: undefined,
    timeZone: location.timeZone,
    authLinkCode: "",
    authToken: "",
  });

  // Manage show state for modal
  const [showAddModal, setShowAddModal] = useState(false);

  //#endregion

  //#region Handlers
  const handleUpdateScreen = (screen) => updateScreen(screen);

  const handleLinkScreen = (screen, authCode) => {
    linkScreen(screen, authCode);
  };

  const handleCreateScreen = (screen) => {
    addLocationScreen(screen);
  };

  const handleRefreshScreen = (screen) => {
    refreshScreen(screen);
  };

  const handleRemoveScreen = (screen) => removeScreen(screen);

  const handleScreen = (screen) => setScreen(screen);

  const handleShow = () => {
    setScreen({
      location: location.id,
      name: "",
      _id: undefined,
      round: "",
      contentId: undefined,
      language: location.language,
      status: undefined,
      timeZone: location.timeZone,
      authLinkCode: "",
      authToken: "",
    });

    setShowAddModal(!showAddModal);
  };
  //#endregion

  const onAddClick = (event) => {
    event.preventDefault();
    handleShow();
    event.currentTarget.blur();
  };

  useEffect(() => {
    if (location._id) getLocation(location._id);

    // eslint-disable-next-line
  }, [refreshScreen, updateScreen, linkScreen]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h3>Screens</h3>
        {!locked && (
          <button className="btn btn-success" onClick={onAddClick}>
            Add
          </button>
        )}
      </div>

      <AddScreen
        show={showAddModal}
        handleShow={handleShow}
        screen={screen}
        disableLocationFields={true}
        handleScreen={handleScreen}
        addScreen={handleCreateScreen}
        updateScreen={handleUpdateScreen}
        setAlert={setAlert}
      />

      {location.screens && location.screens.length > 0 && !loading ? (
        <div className="table-responsive">
          <table className="table table-striped table-sm">
            <thead>
              <tr>
                <th>Name</th>
                <th>Round</th>
                <th>Location</th>
                <th>Time Zone</th>
                <th>Language</th>
                <th>Status</th>
                <th>Created At</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {location.screens.map((screen) => (
                <ScreenListItem
                  key={screen.id}
                  locked={locked}
                  screen={{ ...screen, location: undefined }}
                  setScreen={setScreen}
                  handleLinkScreen={handleLinkScreen}
                  handleRefreshScreen={handleRefreshScreen}
                  handleShow={handleShow}
                  updateScreen={handleUpdateScreen}
                  removeScreen={handleRemoveScreen}
                  user={user}
                  setAlert={setAlert}
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : !!loading ? (
        <Spinner />
      ) : (
        "No Screens Found"
      )}
    </>
  );
};

export default LocationScreenList;
