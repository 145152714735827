import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const LocationListItem = ({
  match,
  location,
  deleteLocation = null,
  setAlert = null,
  user = null,
  code = null,
  setStatus = null,
  screenStatus = null,
}) => {
  useEffect(() => {
    code && setStatus([...screenStatus, code.color]);
    // eslint-disable-next-line
  }, [code]);

  const onDeleteClick = (location) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the Location: ${location.name}?`,
    );
    if (!confirmDelete) return;

    deleteLocation(location.id);
    setAlert({
      message: `Deleted Location: ${location.name}`,
      type: "success",
    });
  };

  return (
    <tr>
      {code && (
        <td>
          <i
            className={code.icon}
            style={{
              color: code.color,
              fontSize: "1.2rem",
              marginLeft: ".25rem",
              marginTop: ".25rem",
            }}
            title={code.tooltip}
          ></i>
        </td>
      )}
      <td>{location.name}</td>
      <td>{location.locationIdentifier}</td>
      <td>{location.timeZone}</td>
      <td>{location.language}</td>
      {location.isActive !== undefined && location.isEnabled !== undefined && (
        <>
          <td>{location.isActive ? "Active" : "Inactive"}</td>
          <td>{location.isEnabled ? "Enabled" : "Disabled"}</td>
        </>
      )}
      <td>{location.createdAt.split("T")[0]}</td>
      <td>{location.updatedAt.split("T")[0]}</td>
      <td></td>
      <td>
        <div className="btn-group btn-group-sm">
          <Link
            className="btn btn-sm btn-outline-warning"
            to={
              !code && user && user.role
                ? `${match.url}/${location._id}`
                : user.role === "admin" || user.role === "super-admin"
                ? `locations/${location._id}`
                : `my-locations/${location._id}`
            }
          >
            <i className="fas fa-pencil-alt"></i>
          </Link>
          {user && user.role === "super-admin" && !code && (
            <button
              className="btn btn-sm btn-outline-danger"
              onClick={() => onDeleteClick(location)}
            >
              <i className="fas fa-trash-alt"></i>
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};

export default LocationListItem;
