import React, { useState } from "react";
import AddWorkoutRoutine from "./AddWorkoutRoutine";
import WorkoutRoutineItem from "./WorkoutRoutineItem";
import Modal from "react-bootstrap/Modal";

const WorkoutRoutineList = ({
  routines,
  handleWorkout,
  setAlert,
  addWorkoutRoutine,
  updateWorkoutRoutine,
  deleteWorkoutRoutine,
}) => {
  //#region State management
  const [routine, setRoutine] = useState({
    step: 1,
    name: "",
    text: "",
    videoLink: "",
    lrVideoLink: "",
  });
  const [show, setShow] = useState(false);
  const [displayFrame, setDisplayFrame] = useState(false);
  const [routineContent, setRoutineContent] = useState(null);

  //#endregion

  //#region Handlers
  const handleRoutine = (routine) => setRoutine(routine);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setRoutine({
      step: 1,
      name: "",
      text: "",
      videoLink: "",
      lrVideoLink: "",
    });
    setShow(false);
  };

  // Custom iFrame handler object
  const iFrameHandler = {
    setFrame: (setFrame) => {
      return setDisplayFrame(setFrame);
    },
    setContent: (setContent) => {
      return setRoutineContent(setContent);
    },
    returnFrame: () => {
      return displayFrame;
    },
    returnContent: () => {
      return routineContent;
    },
  };

  const onAddClick = (event) => {
    event.preventDefault();
    handleShow();
  };

  const addRoutine = (routine) => addWorkoutRoutine(routine);
  const updateRoutine = (routine) => updateWorkoutRoutine(routine);
  const deleteRoutine = (routineId) => deleteWorkoutRoutine(routineId);
  //#endregion

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
        <h2>Routines</h2>
        <button
          className="btn btn-success"
          onClick={onAddClick}
          disabled={!(routines && routines.length < 3)}
        >
          Add
        </button>
      </div>
      <AddWorkoutRoutine
        show={show}
        handleClose={handleClose}
        routine={routine}
        handleRoutine={handleRoutine}
        addRoutine={addRoutine}
        updateRoutine={updateRoutine}
        setAlert={setAlert}
        newStep={
          routines && routines.length === 0
            ? 1
            : routines && routines.length === 1
            ? 2
            : 3
        }
        routines={routines}
      />
      <div className="table-responsive">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Text</th>
              <th>Step</th>
              <th>Video Link</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th></th>
            </tr>
          </thead>
          {routines && (
            <tbody>
              {routines.map((routine) => (
                <WorkoutRoutineItem
                  key={routine._id}
                  routine={routine}
                  iFrameHandler={iFrameHandler}
                  handleRoutine={handleRoutine}
                  handleShow={handleShow}
                  deleteRoutine={deleteRoutine}
                  setAlert={setAlert}
                />
              ))}
            </tbody>
          )}
        </table>
        {displayFrame && (
          <Modal show={displayFrame} backdrop="static" keyboard={false}>
            <Modal.Header
              style={{
                height: "3rem",
                padding: "1%",
                alignItems: "flex-start",
                marginLeft: "1%",
              }}
            >
              <span
                style={{
                  position: "relative",
                  textAlign: "center",
                  fontSize: "1.2rem",
                }}
              >
                NAME: {routineContent ? routineContent.name : null}
              </span>
              <button
                onClick={() => setDisplayFrame(false)}
                className="btn btn-danger btn-sm"
                style={{ right: "0" }}
              >
                X
              </button>
            </Modal.Header>
            {routineContent && routineContent.videoLink ? (
              <div style={{ textAlign: "center", marginTop: "-1.5%" }}>
                <div style={{ backgroundColor: "black" }}>
                  <video
                    height="495"
                    width="495"
                    src={routineContent.videoLink || null}
                    autoPlay={true}
                    loop={true}
                    muted={true}
                  ></video>
                </div>
              </div>
            ) : (
              ""
            )}
            {routineContent && routineContent.lrVideoLink ? (
              <div style={{ textAlign: "center", marginTop: "-25.5%" }}>
                <div style={{ backgroundColor: "black" }}>
                  <video
                    height="495"
                    width="495"
                    src={routineContent.lrVideoLink || null}
                    autoPlay={true}
                    loop={true}
                    muted={true}
                  ></video>
                </div>
              </div>
            ) : (
              ""
            )}
            <Modal.Footer style={{ justifyContent: "center", display: "flex" }}>
              <span
                style={{
                  padding: "1%",
                  display: "contents",
                  position: "relative",
                }}
              >
                {routineContent ? routineContent.text : null}
              </span>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </>
  );
};

export default WorkoutRoutineList;
