import React from "react";

const TimedVFX = () => {
  const nextRound = require("./media/NextRound.png");
  const activeBreak = require("./media/ActiveBreak.png");
  const thirtySeconds = require("./media/30Seconds.png");
  return (
    <span id="vfx-container" style={{ textAlign: "center" }}>
      <div className="screen-fx-hidden" id="screen-vfx">
        <img src={nextRound} className="screen-fx-image" alt="Next Round"></img>
      </div>
      <div className="screen-fx-hidden" id="screen-vfx-round">
        <img
          src={activeBreak}
          className="screen-fx-image"
          alt="Active Break"
        ></img>
      </div>
      <div className="screen-fx-hidden" id="screen-vfx-seconds">
        <img
          src={thirtySeconds}
          className="screen-fx-image"
          alt="Thirty Seconds"
        ></img>
      </div>
    </span>
  );
};

export default TimedVFX;
