export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_LOCATION = "GET_LOCATION";
export const LOCATION_ERROR = "LOCATION_ERROR";
export const CLEAR_LOCATIONS = "CLEAR_LOCATIONS";
export const ADD_LOCATION = "ADD_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const FILTER_LOCATIONS = "FILTER_LOCATIONS";
export const SET_CURRENT = "SET_CURRENT";
export const CLEAR_CURRENT = "CLEAR_CURRENT";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const GET_LOCATION_CODES = "GET_LOCATION_CODES";
export const CLEAR_LOCATION_CODES = "CLEAR_LOCATION_CODES";
