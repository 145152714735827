import React, { useReducer } from "react";
import UserContext from "./UserContext";
import userReducer from "./userReducer";
import {
  GET_USERS,
  ADD_USER,
  UPDATE_USER,
  USER_ERROR,
  DELETE_USER,
  CLEAR_CURRENT,
  SET_CURRENT,
} from "./userTypes";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";

const UserState = (props) => {
  const initalState = {
    users: null,
    roleTypes: ["location", "admin", "super-admin", "api-access"],
    error: null,
    current: null,
    loading: true,
  };

  const [state, dispatch] = useReducer(userReducer, initalState);

  //#region User Methods

  //#region Create

  // Create User
  const createUser = async (user) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    setAuthToken(localStorage.token);

    try {
      const res = await axios.post("/api/v1/users", user, config);

      dispatch({ type: ADD_USER, payload: res.data.data });
    } catch (err) {
      if (err.response)
        dispatch({ type: USER_ERROR, payload: err.response.data.error });
      else dispatch({ type: USER_ERROR, payload: err.msg });
    }
  };

  //#endregion

  //#region Read

  // Get all Users
  const getUsers = async () => {
    try {
      setAuthToken(localStorage.token);

      const res = await axios.get(`/api/v1/users`);
      dispatch({ type: GET_USERS, payload: res.data.data });
    } catch (err) {
      dispatch({ type: USER_ERROR, payload: err.response.msg });
    }
  };

  // Get User
  const getUser = async (id) => {
    try {
      setAuthToken(localStorage.token);

      const res = await axios.get(`/api/v1/users/${id}`);
      dispatch({ type: SET_CURRENT, payload: res.data.data });
    } catch (err) {
      dispatch({ type: USER_ERROR, payload: err.response.msg });
    }
  };

  const searchUsers = async (query) => {
    try {
      setAuthToken(localStorage.token);

      const res = await axios.get(
        `/api/v1/users?email=${query}&&firstName=${query}&&lastName=${query}`,
      );
      dispatch({ type: GET_USERS, payload: res.data.data });
    } catch (err) {
      dispatch({ type: USER_ERROR, payload: err.response.msg });
    }
  };

  //#endregion

  //#region Update

  // Update User
  const updateUser = async (user) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    setAuthToken(localStorage.token);

    try {
      const res = await axios.put(
        `/api/v1/users/${user._id}`,
        { ...user },
        config,
      );

      dispatch({ type: UPDATE_USER, payload: res.data.data });
    } catch (err) {
      if (err.response)
        dispatch({ type: USER_ERROR, payload: err.response.data.error });
      else dispatch({ type: USER_ERROR, payload: err.msg });
    }
  };

  //#endregion

  //#region Delete

  // Delete User
  const deleteUser = async (id) => {
    setAuthToken(localStorage.token);

    try {
      await axios.delete(`/api/v1/users/${id}`);

      dispatch({ type: DELETE_USER, payload: id });
    } catch (err) {
      if (err.response)
        dispatch({ type: USER_ERROR, payload: err.response.data.error });
      else dispatch({ type: USER_ERROR, payload: err.msg });
    }
  };

  //#endregion

  //#region User Location Methods

  // Add location to user
  const addUserLocation = async (id, location) => {
    try {
      console.log(id);

      const newLocation = await axios.post(`/api/v1/locations`, {
        ...location,
      });

      const res = await axios.patch(`/api/v1/users/${id}/locations`, {
        locationId: newLocation.data.data._id,
      });

      dispatch({ type: SET_CURRENT, payload: res.data.data });
    } catch (err) {
      if (err.response)
        dispatch({ type: USER_ERROR, payload: err.response.data.error });
      else dispatch({ type: USER_ERROR, payload: err.msg });
    }
  };

  // Add an existing location to an existing user
  const addLocationToUser = async (id, locationId) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      setAuthToken(localStorage.token);

      const user = await axios.get(`/api/v1/users/${id}`);

      const updatedLocations = [...user.data.data.locations, locationId];

      const res = await axios.put(
        `/api/v1/users/${id}`,
        { ...user, locations: updatedLocations },
        config,
      );

      dispatch({ type: UPDATE_USER, payload: res.data.data });
    } catch (err) {
      if (err.response)
        dispatch({ type: USER_ERROR, payload: err.response.data.error });
      else dispatch({ type: USER_ERROR, payload: err.msg });
    }
  };

  // Remove location from user
  const removeUserLocation = async (id, locationId) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      setAuthToken(localStorage.token);

      const user = await axios.get(`/api/v1/users/${id}`);

      const updatedLocations = user.data.data.locations.filter(
        (location) => location._id !== locationId,
      );

      const res = await axios.put(
        `/api/v1/users/${id}`,
        { locations: updatedLocations },
        config,
      );

      dispatch({ type: UPDATE_USER, payload: res.data.data });
    } catch (err) {
      if (err.response)
        dispatch({ type: USER_ERROR, payload: err.response.data.error });
      else dispatch({ type: USER_ERROR, payload: err.msg });
    }
  };

  //#endregion

  //#region Other Methods

  // Set Current Contact
  const setCurrent = (user) => {
    dispatch({ type: SET_CURRENT, payload: user });
  };

  // Clear Current Contact
  const clearCurrent = () => {
    dispatch({ type: CLEAR_CURRENT });
  };

  //#endregion

  //#endregion

  return (
    <UserContext.Provider
      value={{
        users: state.users,
        error: state.error,
        loading: state.loading,
        roleTypes: state.roleTypes,
        current: state.current,
        addUserLocation,
        addLocationToUser,
        removeUserLocation,
        clearCurrent,
        setCurrent,
        getUsers,
        getUser,
        createUser,
        updateUser,
        deleteUser,
        searchUsers,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
