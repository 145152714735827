import React, { useReducer } from "react";
import AuthContext from "./AuthContext";
import authReducer from "./authReducer";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import {
  AUTH_ERROR,
  CLEAR_ERRORS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  // REGISTER_FAIL,
  // REGISTER_SUCCESS,
  USER_LOADED,
} from "./authTypes";

const AuthState = (props) => {
  const initalState = {
    token: localStorage.getItem("token"),
    isAuthenticated: false,
    isAdmin: false,
    loading: true,
    error: null,
    user: null,
  };

  const [state, dispatch] = useReducer(authReducer, initalState);

  // Load Me
  const loadUser = async () => {
    if (!localStorage.token) {
      return;
    }

    setAuthToken(localStorage.token);

    try {
      const res = await axios.get("/api/v1/auth/me");

      dispatch({
        type: USER_LOADED,
        payload: res.data.data,
      });
    } catch (err) {
      if (err.response)
        dispatch({ type: AUTH_ERROR, payload: err.response.data.error });
      else dispatch({ type: AUTH_ERROR, payload: err.message });
    }
  };

  // Update Me
  const updateMe = async (user) => {
    setAuthToken(localStorage.token);

    try {
      const res = await axios.put("/api/v1/auth/me", user);

      dispatch({
        type: USER_LOADED,
        payload: res.data.data,
      });
    } catch (err) {
      if (err.response)
        dispatch({ type: AUTH_ERROR, payload: err.response.data.error });
      dispatch({ type: AUTH_ERROR });
    }
  };

  // // Register User
  // const register = async (formData) => {
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   try {
  //     const res = await axios.post("/api/v1/users", formData, config);
  //     dispatch({
  //       type: REGISTER_SUCCESS,
  //       payload: res.data,
  //     });

  //     loadUser();
  //   } catch (err) {
  //     dispatch({
  //       type: REGISTER_FAIL,
  //       payload: err.response.data.msg,
  //     });
  //   }
  // };

  // Login User
  const login = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.post("/api/v1/auth", formData, config);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });

      loadUser();
    } catch (err) {
      if (err.response)
        dispatch({ type: AUTH_ERROR, payload: err.response.data.error });
      else {
        dispatch({
          type: LOGIN_FAIL,
          payload: "Invalid email or password",
        });
      }
    }
  };

  // Login User
  const loginSSO = async (token) => {
    try {
      const res = await axios.get(`/api/v1/auth/sso?token=${token}`);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });

      loadUser();
    } catch (err) {
      if (err.response)
        dispatch({ type: AUTH_ERROR, payload: err.response.data.error });
      else {
        dispatch({
          type: LOGIN_FAIL,
          payload: "Invalid email or password",
        });
      }
    }
  };

  // Logout User
  const logout = () => {
    dispatch({
      type: LOGOUT,
    });
  };

  // Clear Errors
  const clearErrors = () => {
    dispatch({ type: CLEAR_ERRORS });
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        error: state.error,
        user: state.user,
        isAdmin: state.isAdmin,
        clearErrors,
        loginSSO,
        // register,
        loadUser,
        login,
        logout,
        updateMe,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
