import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import WorkoutContext from "../../../context/workout/WorkoutContext";
import AlertContext from "../../../context/alert/AlertContext";
import WorkoutListItem from "./WorkoutListItem";
import Pagination from "../../common/Pagination";
import paginateResults from "../../../utils/paginateResults";
import WorkoutPreview from "./WorkoutPreview";
import WorkoutSort from "./WorkoutSort";
import { v4 as uuid } from "uuid";
import Spinner from "../../layout/Spinner";
import SearchBar from "../../common/SearchBar";

const WorkoutList = ({ roundNumber, match, backUrl }) => {
  //#region Context & State Management
  const workoutContext = useContext(WorkoutContext);
  const alertContext = useContext(AlertContext);
  const {
    loading,
    workouts,
    getWorkoutsForRound,
    error,
    deleteWorkout,
    clearCurrent,
    clearWorkouts,
    reorder,
  } = workoutContext;
  const { setAlert } = alertContext;

  // Pagination state
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(null);
  const perPage = 15;

  // Reordering column state
  const [ordering, setOrdering] = useState({});

  // Video preview state
  const [showPreview, setShowPreview] = useState(false);

  //#endregion

  //#region Handlers
  // Flash save workout object to local storage
  const handleStage = (workout, videoHost) => {
    workout["round"] = roundNumber;
    workout["isLrVideo"] = videoHost;
    localStorage.setItem("stagedWorkout", JSON.stringify(workout));
  };

  // Handle preview modal
  const handleView = () => {
    setShowPreview(!showPreview);
  };

  //#endregion

  //#region Lifecycle
  useEffect(() => {
    getWorkoutsForRound(roundNumber);
    clearCurrent();

    // Clear workouts on unmount
    return () => {
      clearWorkouts();
    };

    // eslint-disable-next-line
  }, [error, loading]);

  // Get current page & update on reorder
  useEffect(() => {
    updateResults(ordering);
    // eslint-disable-next-line
  }, [page, workouts, ordering]);

  //#endregion

  //#region Functions
  const paginate = (newPage) => {
    setPage(newPage);
  };

  // update pagination results
  const updateResults = (col) => {
    paginateResults(workouts, page, perPage, setResults, setOrdering, col);
  };

  const setSearchData = (search) => {
    if (search !== undefined) {
      search = search.toLowerCase();
      getWorkoutsForRound(roundNumber, search).then(() => {
        setResults(workouts);
      });
    }
  };

  //#endregion

  // Column field types
  const fieldTypes = [
    { name: "Name" },
    { startDate: "Start Date" },
    { endDate: "End Date" },
    { routines: "Routines" },
    { createdAt: "Created At" },
    { updatedAt: "Updated At" },
  ];

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 border-bottom">
        <h3 className="pb-2 mt-0">Scheduled Workouts</h3>
        <SearchBar
          subject={`round ${roundNumber}`}
          setSearchData={setSearchData}
          paginate={paginate}
          className="pb-2"
        />
        <Link className="btn btn-success" to={`${match.url}/workout`}>
          Add
        </Link>
      </div>
      {workouts && workouts.length > 0 && !loading && (
        <div className="table-responsive">
          <table className="table table-striped table-sm">
            <thead>
              <tr>
                {fieldTypes.map((field) => (
                  <th key={uuid()}>
                    {Object.values(field)}{" "}
                    <WorkoutSort
                      toggle={
                        Object.keys(ordering).toString() ===
                        Object.keys(field).toString()
                          ? true
                          : false
                      }
                      setPage={setPage}
                      ordering={ordering}
                      setOrdering={setOrdering}
                      reorder={reorder}
                      field={Object.keys(field)[0]}
                    />
                  </th>
                ))}
                <th></th>
              </tr>
            </thead>
            {results && !loading && (
              <tbody>
                {results.map((workout) => (
                  <WorkoutListItem
                    match={match}
                    key={uuid()}
                    id={workout.id}
                    workout={workout}
                    setAlert={setAlert}
                    deleteWorkout={deleteWorkout}
                    handleStage={handleStage}
                    handleView={handleView}
                  />
                ))}
              </tbody>
            )}
          </table>
          {workouts && !loading && workouts.length > 15 && (
            <Pagination
              currentPage={page}
              perPage={15}
              total={workouts.length}
              paginate={paginate}
            />
          )}
        </div>
      )}
      {loading && <Spinner />}
      {!loading && results && results.length < 1 && (
        <p>
          <br />
          No workouts found
        </p>
      )}
      {showPreview && (
        <WorkoutPreview show={showPreview} setShow={setShowPreview} />
      )}
    </>
  );
};

export default WorkoutList;
