import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import autoScale from "../../../utils/autoScale";
import "./z_overwrite.css";

const WorkoutPreview = ({ show, setShow }) => {
  // Staging URL
  const URL = window.location.origin;

  // Screen dimension object
  const [dimensions, setDimensions] = useState({ xAxis: "720", yAxis: "1280" });

  // Handle modal show
  const handleClose = () => {
    setShow(false);
  };

  // Adjust workout preview to monitor resolution
  useEffect(() => {
    autoScale(dimensions, setDimensions);
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        contentClassName={"preview-content"}
        style={{
          maxHeight: "100%",
          maxWidth: "100%",
        }}
      >
        <iframe
          src={`${URL}/stage`}
          height={dimensions.yAxis}
          width={dimensions.xAxis}
          title="workout-preview"
          scrolling="no"
        ></iframe>
      </Modal>
    </>
  );
};

export default WorkoutPreview;
