import React, { useReducer } from "react";
import WorkoutContext from "./WorkoutContext";
import workoutReducer from "./workoutReducer";
import {
  DELETE_WORKOUT,
  WORKOUT_ERROR,
  GET_WORKOUTS,
  GET_CONTENT,
  SET_CURRENT,
  CLEAR_CURRENT,
  ADD_WORKOUT,
  UPDATE_WORKOUT,
  CLEAR_WORKOUTS,
  CLEAR_CONTENT,
  SORT_ORDER,
} from "./workoutTypes";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import { addDays } from "../../utils/dateHandlers";

const WorkoutState = (props) => {
  const initialState = {
    workouts: [],
    current: null,
    currentContent: [],
    loading: true,
  };

  const [state, dispatch] = useReducer(workoutReducer, initialState);

  //#region Workout Methods

  //#region Create

  // Add a Workout
  const addWorkout = async (workout) => {
    setAuthToken(localStorage.token);
    try {
      const res = await axios.post(`/api/v1/workouts`, workout);

      dispatch({ type: ADD_WORKOUT, payload: res.data.data });
    } catch (error) {
      dispatch({ type: WORKOUT_ERROR, payload: error.msg });
    }
  };

  //#endregion

  //#region Read

  // Get all Workouts for round
  const getWorkoutsForRound = async (roundNumber, query = null) => {
    setAuthToken(localStorage.token);

    query = query
      ? {
          round: { $eq: roundNumber },
          $or: [{ name: { $regex: query, $options: "i" } }],
        }
      : { round: { $eq: roundNumber } };

    try {
      const res = await axios.get(
        `/api/v1/workouts?q=${JSON.stringify(query)}`,
      );

      dispatch({ type: GET_WORKOUTS, payload: res.data.data });
    } catch (error) {
      dispatch({ type: WORKOUT_ERROR, payload: error.msg });
    }
  };

  // Get Workouts by Routine
  const getWorkoutsByRoutines = async () => {
    setAuthToken(localStorage.token);
    try {
      // creates a query to look for all routines that do not have a routine in index 0
      const query = {
        "routines.0": { $exists: false },
      };

      const res = await axios.get(
        `/api/v1/workouts?q=${JSON.stringify(query)}`,
      );

      dispatch({ type: GET_CONTENT, payload: res.data.data });
    } catch (error) {
      dispatch({ type: WORKOUT_ERROR, payload: error.msg });
    }
  };

  // Get Workouts by Date
  const getWorkoutsByDate = async () => {
    setAuthToken(localStorage.token);

    const rollingStart = new Date();
    const rollingStop = addDays(rollingStart, 6);

    try {
      const query = {
        startDate: {
          $gte:
            new Date(rollingStart).toISOString().split("T")[0] +
            "T00:00:00.000Z",
          $lt:
            new Date(rollingStop).toISOString().split("T")[0] +
            "T23:59:59.999Z",
        },
      };

      const res = await axios.get(
        `/api/v1/workouts?q=${JSON.stringify(query)}`,
      );

      dispatch({ type: GET_WORKOUTS, payload: res.data.data });
    } catch (error) {
      dispatch({ type: WORKOUT_ERROR, payload: error.msg });
    }
  };

  // Get all Workouts
  const getWorkouts = async () => {
    setAuthToken(localStorage.token);

    try {
      const res = await axios.get("/api/v1/workouts");

      dispatch({ type: GET_WORKOUTS, payload: res.data.data });
    } catch (error) {
      dispatch({ type: WORKOUT_ERROR, payload: error.msg });
    }
  };

  // Get a Workout
  const getWorkout = async (id) => {
    setAuthToken(localStorage.token);
    try {
      const res = await axios.get(`/api/v1/workouts/${id}`);

      dispatch({ type: SET_CURRENT, payload: res.data.data });
    } catch (error) {
      dispatch({ type: WORKOUT_ERROR, payload: error.msg });
    }
  };
  //#endregion

  //#region Update

  // Update a Workout
  const updateWorkout = async (workout) => {
    setAuthToken(localStorage.token);

    try {
      const res = await axios.put(`/api/v1/workouts/${workout._id}`, workout);
      dispatch({ type: UPDATE_WORKOUT, payload: res.data.data });
    } catch (error) {
      dispatch({ type: WORKOUT_ERROR, payload: error.msg });
    }
  };

  //#endregion

  //#region Delete

  // Delete a Workout
  const deleteWorkout = async (id) => {
    setAuthToken(localStorage.token);
    try {
      await axios.delete(`/api/v1/workouts/${id}`);

      dispatch({ type: DELETE_WORKOUT, payload: id });
    } catch (error) {
      dispatch({ type: WORKOUT_ERROR, payload: error.msg });
    }
  };

  //#endregion

  // Set Current Workout
  const setCurrent = (id) => {
    try {
      const workout = state.workouts.find((workout) => workout.id === id);

      dispatch({ type: SET_CURRENT, payload: workout });
    } catch (error) {
      dispatch({ type: WORKOUT_ERROR, payload: error.msg });
    }
  };

  // Clear current workout
  const clearCurrent = () => {
    try {
      dispatch({ type: CLEAR_CURRENT });
    } catch (error) {
      dispatch({ type: WORKOUT_ERROR, payload: error.msg });
    }
  };

  // Clear current workouts
  const clearWorkouts = () => {
    try {
      dispatch({ type: CLEAR_WORKOUTS });
    } catch (error) {
      dispatch({ type: WORKOUT_ERROR, payload: error.msg });
    }
  };

  const clearContent = () => {
    try {
      dispatch({ type: CLEAR_CONTENT });
    } catch (error) {
      dispatch({ type: WORKOUT_ERROR, payload: error.msg });
    }
  };

  //#endregion

  //#region Workout Routine Methods

  // Add workout routine to state
  const addWorkoutRoutine = async (routine) => {
    setAuthToken(localStorage.token);

    const payload = { routines: [...state.current.routines, routine] };

    try {
      const res = await axios.put(
        `/api/v1/workouts/${state.current._id}`,
        payload,
      );

      dispatch({ type: UPDATE_WORKOUT, payload: res.data.data });
    } catch (error) {
      dispatch({ type: WORKOUT_ERROR, payload: error.msg });
    }
  };

  // Update workout routine in state
  const updateWorkoutRoutine = async (routine) => {
    setAuthToken(localStorage.token);

    const payload = {
      routines: state.current.routines.map((r) =>
        r._id === routine._id ? routine : r,
      ),
    };

    try {
      const res = await axios.put(
        `/api/v1/workouts/${state.current._id}`,
        payload,
      );

      dispatch({ type: UPDATE_WORKOUT, payload: res.data.data });
    } catch (error) {
      dispatch({ type: WORKOUT_ERROR, payload: error.msg });
    }
  };

  // Delete workout routine from state
  const deleteWorkoutRoutine = async (routineId) => {
    setAuthToken(localStorage.token);

    const payload = {
      routines: state.current.routines.filter(
        (routine) => routine._id !== routineId,
      ),
    };

    try {
      const res = await axios.put(
        `/api/v1/workouts/${state.current._id}`,
        payload,
      );

      dispatch({ type: UPDATE_WORKOUT, payload: res.data.data });
    } catch (error) {
      dispatch({ type: WORKOUT_ERROR, payload: error.msg });
    }
  };

  //#endregion

  // Other Methods

  // Workout sorting
  const reorder = (direction, field) => {
    try {
      dispatch({
        type: SORT_ORDER,
        payload: { direction, field, exceptions: ["routines"] },
      });
    } catch (error) {
      dispatch({ type: WORKOUT_ERROR, payload: error.msg });
    }
  };

  return (
    <WorkoutContext.Provider
      value={{
        workouts: state.workouts,
        current: state.current,
        loading: state.loading,
        currentContent: state.currentContent,
        getWorkoutsForRound,
        getWorkoutsByRoutines,
        getWorkoutsByDate,
        getWorkouts,
        deleteWorkout,
        setCurrent,
        clearCurrent,
        addWorkoutRoutine,
        updateWorkoutRoutine,
        deleteWorkoutRoutine,
        updateWorkout,
        addWorkout,
        getWorkout,
        clearWorkouts,
        clearContent,
        reorder,
      }}
    >
      {props.children}
    </WorkoutContext.Provider>
  );
};

export default WorkoutState;
