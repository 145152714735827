import {
  SET_ALERT,
  REMOVE_ALERT,
  ADD_FLAG,
  REMOVE_FLAG,
  WIPE_ALERT,
  ALERT_ERROR,
} from "./alertTypes";

export default (state, action) => {
  switch (action.type) {
    case SET_ALERT:
      return {
        ...state,
        current: action.payload,
        alerts: [...state.alerts, action.payload],
        loading: false,
      };
    case REMOVE_ALERT:
      return {
        ...state,
        current: null,
        alerts: state.alerts.filter((alert) => alert.id !== action.payload),
      };
    case WIPE_ALERT:
      return {
        ...state,
        current: null,
        alerts: [],
      };
    case ADD_FLAG:
      return {
        ...state,
        flag: action.payload,
      };
    case REMOVE_FLAG:
      return {
        ...state,
        flag: null,
      };
    case ALERT_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
