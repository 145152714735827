module.exports = {
  languagesAll: [
    "en-us",
    "en-gb",
    "en-nz",
    "en-au",
    "fr-ca",
    "fr",
    "es",
    "es-mx",
    "es-cr",
    "es-ec",
    "es-co",
    "es-gt",
    "es-ar",
    "es-pa",
    "ja",
    "ar-qa",
    "ar-jo",
    "ar-lb",
    "ar-sa",
    "ar-ae",
    "ar-kw",
    "id",
    "ms",
  ],
  languagesUSCA: ["en-us", "fr-ca", "es"],
};
