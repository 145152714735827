import {
  GET_SCREEN,
  GET_SCREENS,
  GET_WORKOUT,
  SCREEN_ERROR,
  CREATE_SCREEN,
  REMOVE_SCREEN,
  CLEAR_SCREENS,
  CLEAR_SCREEN,
  SET_SCREEN_AUTH,
} from "./screenTypes";

export default (state, action) => {
  switch (action.type) {
    case GET_WORKOUT:
      return {
        ...state,
        workout: {
          ...action.payload.workout,
          routines:
            action.payload.workout && action.payload.workout.routines
              ? action.payload.workout.routines.sort((a, b) => a.step > b.step)
              : null,
        },
        isLrVideo: action.payload.lrVideo,
        loading: false,
      };
    case CLEAR_SCREENS:
      return {
        ...state,
        screens: action.payload,
        loading: false,
      };
    case GET_SCREENS:
      return {
        ...state,
        screens: action.payload,
        loading: false,
      };
    case GET_SCREEN:
      return {
        ...state,
        screen: action.payload,
        loading: false,
        isScreenAuth: true,
      };
    case CREATE_SCREEN:
      return {
        ...state,
        screens: [action.payload, ...state.screens],
        loading: false,
      };
    case REMOVE_SCREEN:
      return {
        ...state,
        screens: state.screens.filter((screen) => screen.id !== action.payload),
        loading: false,
      };
    case SET_SCREEN_AUTH:
      return {
        ...state,
        isScreenAuth: action.payload,
      };
    case SCREEN_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CLEAR_SCREEN:
      return {
        ...state,
        screen: null,
        workout: null,
        isScreenAuth: false,
        loading: false,
      };
    default:
      return state;
  }
};
