import React from "react";
import Footer from "../../../layout/Footer";

const ScreenRefresh = () => {
  return (
    <div>
      <div className="center">
        <div className="form-signin">
          <img
            className="mb-4"
            src="/logo_black.png"
            alt=""
            width="150"
            height="150"
          />
          <h1 className="h3 mb-3 font-weight-bold">
            9Round {global.config.product.app.name}
          </h1>
          <h2 className="text-9r-red">Content Refreshing. Please Wait.</h2>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ScreenRefresh;
