export const GET_WORKOUTS = "GET_WORKOUTS";
export const GET_WORKOUT = "GET_WORKOUT";
export const GET_CONTENT = "GET_CONTENT";
export const WORKOUT_ERROR = "WORKOUT_ERROR";
export const CLEAR_WORKOUTS = "CLEAR_WORKOUTS";
export const ADD_WORKOUT = "ADD_WORKOUT";
export const DELETE_WORKOUT = "DELETE_WORKOUT";
export const UPDATE_WORKOUT = "UPDATE_WORKOUT";
export const FILTER_WORKOUTS = "FILTER_WORKOUTS";
export const SET_CURRENT = "SET_CURRENT";
export const CLEAR_CURRENT = "CLEAR_CURRENT";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const CLEAR_CONTENT = "CLEAR_CONTENT";
export const SORT_ORDER = "SORT_ORDER";

export const ADD_ROUTINE = "ADD_ROUTINE";
export const UPDATE_ROUTINE = "UPDATE_ROUTINE";
export const DELETE_ROUTINE = "DELETE_ROUTINE";
