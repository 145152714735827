import React, { useContext, useEffect } from "react";
import Spinner from "../../layout/Spinner";
import RoundListItem from "./RoundListItem";
import RoundContext from "../../../context/round/RoundContext";

const RoundList = () => {
  const roundContext = useContext(RoundContext);
  const { getRounds, rounds, error, loading, clearRounds } = roundContext;

  useEffect(() => {
    getRounds();

    return () => {
      clearRounds();
    };
    // eslint-disable-next-line
  }, [loading, error]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-3 mb-3 border-bottom">
        <h2>Rounds</h2>
      </div>

      {rounds && rounds.length > 0 && !loading ? (
        <div className="row justify-content-center">
          {rounds.map((round) => (
            <RoundListItem key={round.roundNumber} round={round} />
          ))}
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default RoundList;
