import React, { useState, useRef, useEffect } from "react";

const ThirtySecondTimer = ({ countDown }) => {
  const Ref = useRef(null);
  const [timer, setTimer] = useState("0:00");

  const getRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds),
      );
    }
  };

  const clearTimer = (e) => {
    setTimer(countDown.thirtyFormat);
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const handleTime = () => {
    let time = new Date();
    time.setSeconds(time.getSeconds() + countDown.thirty);
    return time;
  };

  useEffect(() => {
    clearTimer(handleTime());
    // eslint-disable-next-line
  }, []);

  useEffect(() => () => setTimer(null), []);

  return (
    <div className="Timer-container">
      <h2
        className="Timer-display"
        style={{
          color: "#C8102E",
        }}
      >
        {timer}
      </h2>
    </div>
  );
};

export default ThirtySecondTimer;
