import React, { useReducer } from "react";
import axios from "axios";
import RoundContext from "./RoundContext";
import roundReducer from "./roundReducer";
import {
  GET_ROUNDS,
  ADD_ROUND,
  UPDATE_ROUND,
  DELETE_ROUND,
  ROUND_ERROR,
  CLEAR_ROUNDS,
} from "./roundTypes";
import setAuthToken from "../../utils/setAuthToken";

const RoundState = (props) => {
  const initalState = {
    rounds: [],
    error: null,
    loading: true,
  };

  const [state, dispatch] = useReducer(roundReducer, initalState);

  // Get Rounds
  const getRounds = async () => {
    try {
      setAuthToken(localStorage.token);

      const res = await axios.get("/api/v1/rounds");

      dispatch({ type: GET_ROUNDS, payload: res.data.data });
    } catch (err) {
      dispatch({ type: ROUND_ERROR, payload: err.response.msg });
    }
  };

  // Add a Round
  const addRound = async (round) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/v1/rounds", round, config);

      dispatch({ type: ADD_ROUND, payload: res.data.data });
    } catch (err) {
      dispatch({ type: ROUND_ERROR, payload: err.response.msg });
    }
  };

  // Delete Round
  const deleteRound = async (id) => {
    try {
      await axios.delete(`/api/v1/rounds/${id}`);

      dispatch({ type: DELETE_ROUND, payload: id });
    } catch (err) {
      dispatch({ type: ROUND_ERROR, payload: err.response.msg });
    }
  };

  // Update Round
  const updateRound = async (round) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    round.updatedAt = Date.now();
    try {
      const res = await axios.put(`/api/v1/rounds/${round._id}`, round, config);

      dispatch({ type: UPDATE_ROUND, payload: res.data.data });
    } catch (err) {
      dispatch({ type: ROUND_ERROR, payload: err.response.msg });
    }
  };

  // Clear Rounds
  const clearRounds = () => dispatch({ type: CLEAR_ROUNDS });

  // // Set Current Contact
  // const setCurrent = (contact) => {
  //   dispatch({ type: SET_CURRENT, payload: contact });
  // };

  // // Clear Current Contact
  // const clearCurrent = () => {
  //   dispatch({ type: CLEAR_CURRENT });
  // };

  // // Filter Contacts
  // const filterContacts = (text) => {
  //   dispatch({ type: FILTER_CONTACTS, payload: text });
  // };

  // // Clear Filter
  // const clearFilter = () => {
  //   dispatch({ type: CLEAR_FILTER });
  // };

  return (
    <RoundContext.Provider
      value={{
        rounds: state.rounds,
        error: state.error,
        loading: state.loading,
        getRounds,
        addRound,
        deleteRound,
        updateRound,
        clearRounds,

        // current: state.current,
        // filtered: state.filtered,
        // setCurrent,
        // clearCurrent,
        // clearFilter,
      }}
    >
      {props.children}
    </RoundContext.Provider>
  );
};

export default RoundState;
