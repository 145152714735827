import React, { useEffect } from "react";
import Round from "./StageScreen";
import Spinner from "../../components/layout/Spinner";

const Stage = () => {
  // Get and parse json workout object
  const stagedWorkout = localStorage.getItem("stagedWorkout") || null;
  const staged = stagedWorkout ? JSON.parse(stagedWorkout) : null;

  // Remove local storage on video load
  useEffect(() => {
    localStorage.removeItem("stagedWorkout");
    // eslint-disable-next-line
  }, [window.location]);

  return (
    <>
      {staged ? (
        <Round
          roundNumber={staged.round}
          isLrVideo={staged.isLrVideo}
          workout={staged}
        />
      ) : (
        <div>
          <Spinner />
          <p className="alert alert-warning text-center">
            Please ensure local storage is enabled in your web browser if
            content does not load.
          </p>
        </div>
      )}
    </>
  );
};

export default Stage;
