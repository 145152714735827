export const GET_WORKOUT = "GET_WORKOUT";

export const GET_SCREEN = "GET_SCREEN";
export const GET_SCREENS = "GET_SCREENS";
export const CLEAR_SCREENS = "CLEAR_SCREENS";
export const SCREEN_ERROR = "SCREEN_ERROR";

export const SCREEN_STATUS = "SCREEN_STATUS";

export const UPDATE_SCREEN = "UPDATE_SCREEN";
export const CREATE_SCREEN = "CREATE_SCREEN";
export const REMOVE_SCREEN = "REMOVE_SCREEN";
export const REFRESH_SCREEN = "REFRESH_SCREEN";

export const CLEAR_SCREEN = "CLEAR_SCREEN";

export const SET_SCREEN_AUTH = "SET_SCREEN_AUTH";
