import React, { useState } from "react";
import Spinner from "../../layout/Spinner";
import AddLocation from "../location/AddLocation";

const UserLocationList = ({
  locked,
  loading,
  locations,
  handleCreateLocation,
  removeUserLocation,
  setAlert,
  userId,
}) => {
  const [locationInput, setLocationInput] = useState({
    name: "",
    locationIdentifier: "",
    language: null,
    timeZone: null,
  });

  const [show, setShow] = useState(false);
  const handleShow = () => {
    setLocationInput({
      name: "",
      locationIdentifier: "",
      language: null,
      timeZone: null,
    });
    setShow(!show);
  };

  const onAddClick = (event) => {
    event.preventDefault();
    event.currentTarget.blur();

    handleShow();
  };

  const onDeleteClick = (location) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete Location: ${location.name} from this user?`
    );
    if (!confirmDelete) return;

    removeUserLocation(userId, location._id);
    setAlert({
      message: `Deleted Location: ${location.name} from user`,
      type: "success",
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h2>Locations</h2>
        {!locked && (
          <button className="btn btn-success" onClick={onAddClick}>
            Add
          </button>
        )}
      </div>

      <AddLocation
        show={show}
        handleShow={handleShow}
        location={locationInput}
        handleLocation={setLocationInput}
        addLocation={handleCreateLocation}
      />

      {locations && locations.length > 0 && !loading && (
        <div className="table-responsive">
          <table className="table table-striped table-sm">
            <thead>
              <tr>
                <th>Name</th>
                <th>Location Identifier</th>
                <th>Time Zone</th>
                <th>Language</th>
                <th>Active</th>
                <th>Status</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {locations.map((location) => (
                <tr key={location._id}>
                  <td>{location.name}</td>
                  <td>{location.locationIdentifier}</td>
                  <td>{location.timeZone}</td>
                  <td>{location.language}</td>
                  <td>{location.isActive ? "Active" : "Inactive"}</td>
                  <td>{location.isEnabled ? "Enabled" : "Disabled"}</td>
                  <td>{location.createdAt}</td>
                  <td>{location.updatedAt}</td>
                  <td>
                    {!locked && (
                      <div className="button-group">
                        <button
                          className="btn btn-sm btn-outline-danger"
                          onClick={() => onDeleteClick(location)}
                        >
                          <i className="fas fa-minus"></i>
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {!locations && loading && <Spinner />}
      {!loading && locations && locations.length < 1 && (
        <p>No locations found</p>
      )}
    </>
  );
};

export default UserLocationList;
