import React from "react";
import Modal, {
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "../../common/Modal";
import { timeZonesUS } from "../../../utils/timeZones";
import { languagesUSCA } from "../../../utils/languageCodes";

const AddLocation = ({
  show,
  location,
  handleLocation,
  handleShow,
  addLocation,
  updateLocation,
}) => {
  const onChange = (event) => {
    handleLocation({ ...location, [event.target.name]: event.target.value });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (!location._id) addLocation(location);
    else updateLocation(location);

    handleShow();
  };

  return (
    <>
      <Modal show={show} onHide={handleShow}>
        <ModalHeader>
          <ModalTitle>{location._id ? "Update" : "Add"} Location</ModalTitle>
        </ModalHeader>
        <form onSubmit={onSubmit}>
          <ModalBody>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                placeholder="85 characters (max)"
                value={location.name || ""}
                onChange={onChange}
                maxLength={85}
                className="form-control"
                required={true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="round">9Round Number</label>
              <input
                type="text"
                name="locationIdentifier"
                placeholder="ex 9R0001"
                value={location.locationIdentifier || ""}
                onChange={onChange}
                className="form-control"
                required={true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="timeZone">Time Zone</label>
              <select
                name="timeZone"
                className="form-control"
                onChange={onChange}
                required={true}
                value={location.timeZone || ""}
              >
                <option></option>
                {timeZonesUS.map((timeZone, i) => (
                  <option key={i} value={timeZone}>
                    {timeZone}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="language">Language</label>
              <select
                name="language"
                onChange={onChange}
                className="form-control"
                required={true}
                value={location.language || ""}
              >
                <option></option>
                {languagesUSCA.map((language, i) => (
                  <option key={i} value={language}>
                    {language}
                  </option>
                ))}
              </select>
            </div>
          </ModalBody>
          <ModalFooter>
            <button type="submit" className="btn btn-primary">
              {location._id ? "Update Location" : "Add Location"}
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default AddLocation;
