/**
 * @function locationMatch
 * @param {Object[]} locations - Locations from state/context (unsorted)
 * @param {Object} user - A user object
 * @returns {Object[]} An Object array of locations that can be added to the user
 * @description Compares the Object Array of locations from state to a users Array of location IDs
 */
export const locationMatch = (locations, user) => {
  // Returns a count object with ids and frequency counter as key value pair;
  const countLocations = function (locations, user) {
    let count = locations.map(function (location) {
      return user.locations.reduce(
        function (acc, val) {
          if (location.id === val) acc[location.id]++;
          return acc;
        },
        { [location.id]: 0 }
      );
    });
    return count;
  };

  // Get count object
  const matchLocations = countLocations(locations, user);

  // Declare an empty array for the result
  const availableLocations = [];

  // Build the array of available locations based on frequency counter
  for (let key in matchLocations) {
    if (Object.values(matchLocations[key]) < 1) {
      availableLocations.push(locations[key]);
    }
  }

  // Flatten and return the result
  return availableLocations.flat();
};
