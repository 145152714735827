import React, { useContext, useState, useEffect } from "react";
import Spinner from "../../layout/Spinner";
import LoggerContext from "../../../context/logger/LoggerContext";
import LoggerListItem from "./LoggerListItem";
import SearchBar from "../../common/SearchBar";
import Pagination from "../../common/Pagination";
import paginateResults from "../../../utils/paginateResults";

const LoggerList = () => {
  const loggerContext = useContext(LoggerContext);
  const { getLogs, deleteLogs, logs, loading } = loggerContext;

  const [searchData, setSearchData] = useState([]);

  // pagination state
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(null);
  const perPage = 15;

  useEffect(() => {
    getLogs();
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    getLogs(searchData);
    // eslint-disable-next-line
  }, [searchData, loading]);

  // get current page & update
  useEffect(() => {
    updateResults();
    // eslint-disable-next-line
  }, [page, logs]);

  // Update pagination results
  const updateResults = () => {
    paginateResults(logs, page, perPage, setResults);
  };

  // paginate page
  const paginate = (newPage) => {
    setPage(newPage);
  };

  const clearLogs = async () => {
    const confirmDelete = window.prompt("Enter Password to continue: ", "");
    if (confirmDelete === "CONFIRMDROP") {
      await deleteLogs();
      window.alert("Log Collection Deleted!");
    } else if (confirmDelete === null) {
      return;
    } else {
      window.alert("Incorrect Password");
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h2>Logs (ADMIN)</h2>
        <button
          onClick={clearLogs}
          className="btn-sm btn-danger"
          style={{ margin: "15px" }}
        >
          CLEAN LOGS
        </button>
      </div>
      <SearchBar
        subject={"logs"}
        setSearchData={setSearchData}
        paginate={paginate}
      />

      <div className="table-responsive">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th>Date</th>
              <th>Model</th>
              <th>Action</th>
              <th>ModifiedBy</th>
              <th>UserID</th>
              <th>Data</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          {results && !loading && (
            <tbody>
              {results.map((log) => (
                <LoggerListItem key={log.id} log={log} />
              ))}
            </tbody>
          )}
        </table>
        {logs && !loading && logs.length > perPage ? (
          <Pagination
            currentPage={page}
            perPage={perPage}
            total={logs.length}
            paginate={paginate}
          />
        ) : (
          ""
        )}
      </div>
      {(!logs || loading) && <Spinner />}
      {!loading && logs && logs.length < 1 && <p>No logs found</p>}
    </>
  );
};

export default LoggerList;
