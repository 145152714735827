import React, { useContext, useEffect, useRef } from "react";
import TimerContext from "../../../../context/timer/TimerContext";
import InitialTimer from "./InitialTimer";
import ThirtySecondTimer from "./ThirtySecondTimer";
import TimedVFX from "./TimedVFX";
import { checkTime } from "./utils/syncVts";
import { handleStart } from "./utils/handleTime";

const TimedSequence = () => {
  const timerContext = useContext(TimerContext);
  const {
    color,
    setThreeMinute,
    setThirtySecond,
    setColor,
    licenseKey,
    setTime,
    time,
    dwsTime,
    clearTimer,
    setExercise,
    setPlaycard,
    playcard,
    iteration,
    setIteration,
    setCountDown,
    thirtySecond,
    threeMinute,
    countDown,
  } = timerContext;

  const interval1 = useRef(null);
  const interval2 = useRef(null);
  const interval3 = useRef(null);
  const interval4 = useRef(null);
  const interval5 = useRef(null);
  const interval6 = useRef(null);
  const interval7 = useRef(null);
  const interval8 = useRef(null);
  const interval9 = useRef(null);
  const interval10 = useRef(null);
  const interval11 = useRef(null);

  const initiateGreen = (count) => {
    const latency = count;
    setThreeMinute(true);
    interval8.current = setTimeout(() => {
      setColor("#FBE100");
    }, latency);
  };

  const initiateYellow = (count) => {
    const latency = count;
    setThreeMinute(true);
    interval9.current = setTimeout(() => {
      setColor("#C8102E");
    }, latency);
  };

  const initiateRed = (count) => {
    const latency = count;
    setThirtySecond(true);
    interval10.current = setTimeout(() => {
      setColor("#00A651");
    }, latency);
  };

  // Initial Sync with VTS (30s delay)
  const initiateTimer = () => {
    setColor("#00A650");
    handleStart(
      dwsTime,
      setCountDown,
      initiateGreen,
      initiateYellow,
      initiateRed,
      clearTimer,
    );
  };

  // 2 Minutes & 30 Seconds
  const handleGreen = (
    setThreeMinute,
    setThirtySecond,
    setColor,
    licenseKey,
    setTime,
    time,
  ) => {
    interval1.current = setTimeout(() => {
      checkTime(licenseKey, setTime, time);
    }, 10000);
    // Set Timer State
    setThreeMinute(true);
    setThirtySecond(false);
    const VFX = document.getElementById("screen-vfx");
    if (VFX) {
      // Load Visual FX
      VFX.classList.add("flashingGreen");
      VFX.classList.remove("screen-fx-hidden");
      interval2.current = setTimeout(() => {
        // Unload Visual FX
        VFX.classList.remove("flashingGreen");
        VFX.classList.add("screen-fx-hidden");
      }, 3500);
    }
    interval3.current = setTimeout(() => {
      setColor("#FBE100");
    }, 150000);
  };

  // 1 Minute
  const handleYellow = (setIteration, setColor, iteration) => {
    const VFX = document.getElementById("screen-vfx-seconds");
    if (VFX) {
      // Load Visual FX
      VFX.classList.add("flashingYellow");
      VFX.classList.remove("screen-fx-hidden");
      interval4.current = setTimeout(() => {
        // Unload Visual FX
        VFX.classList.remove("flashingYellow");
        VFX.classList.add("screen-fx-hidden");
      }, 3500);
    }
    interval5.current = setTimeout(() => {
      const nextIteration = iteration + 1;
      setIteration(nextIteration);
      setColor("#C8102E");
    }, 30000);
  };

  // 30 Seconds
  const handleRed = (
    setThreeMinute,
    setThirtySecond,
    setExercise,
    setPlaycard,
    setColor,
    playcard,
    iteration,
    clearTimer,
  ) => {
    // Set Timer State
    setThreeMinute(false);
    setThirtySecond(true);

    // Set Active Break
    if (playcard && playcard.length > 0) setExercise(playcard[0]);

    const VFX = document.getElementById("screen-vfx-round");
    if (VFX) {
      // Load Visual FX
      VFX.classList.add("flashingRed");
      VFX.classList.remove("screen-fx-hidden");
      if (playcard && playcard.length > 0) {
        // Update VTS Playcard
        const updatedCard = playcard;
        const current = playcard[0];
        updatedCard.shift();
        updatedCard.push(current);
        setPlaycard(updatedCard);
      }
      interval6.current = setTimeout(() => {
        // Unload Visual FX
        VFX.classList.remove("flashingRed");
        VFX.classList.add("screen-fx-hidden");
      }, 3500);
    }
    interval7.current = setTimeout(() => {
      // Unload 30 Second Timer & Active Break
      setThreeMinute(false);
      setExercise(null);
      if (iteration >= 27) {
        clearTimer();
        return;
      } else {
        setColor("#00A651");
      }
    }, 30000);
  };

  // Cycle between round phases
  useEffect(() => {
    if (color === "#00A651") {
      const latency =
        Number(time.currentTime) - Number(time.previousTime) - 210000;
      interval11.current = setTimeout(
        () => {
          handleGreen(
            setThreeMinute,
            setThirtySecond,
            setColor,
            licenseKey,
            setTime,
            time,
          );
        },
        latency > 0 && latency < 20000 ? latency : 0,
      );
    } else if (color === "#FBE100") {
      handleYellow(setIteration, setColor, iteration);
    } else if (color === "#C8102E") {
      handleRed(
        setThreeMinute,
        setThirtySecond,
        setExercise,
        setPlaycard,
        setColor,
        playcard,
        iteration,
        clearTimer,
      );
    }
    //eslint-disable-next-line
  }, [color]);

  // Check if VTS is running & initialize timer if true
  useEffect(() => {
    if (playcard && playcard.length > 0 && dwsTime) initiateTimer();
    // eslint-disable-next-line
  }, [playcard, dwsTime]);

  useEffect(() => {
    return () => {
      clearTimeout(interval1.current);
      clearTimeout(interval2.current);
      clearTimeout(interval3.current);
      clearTimeout(interval4.current);
      clearTimeout(interval5.current);
      clearTimeout(interval6.current);
      clearTimeout(interval7.current);
      clearTimeout(interval8.current);
      clearTimeout(interval9.current);
      clearTimeout(interval10.current);
      clearTimeout(interval11.current);
      clearTimer();
    };
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {threeMinute && !thirtySecond && (
        <InitialTimer color={color} countDown={countDown} />
      )}
      {thirtySecond && !threeMinute && (
        <ThirtySecondTimer countDown={countDown} />
      )}
      <TimedVFX />
    </>
  );
};

export default TimedSequence;
