import React, { useEffect, useState, useContext } from "react";
import Spinner from "../../layout/Spinner";
import AuthContext from "../../../context/auth/AuthContext";
import LocationListItem from "../location/LocationListItem";
import Pagination from "../../common/Pagination";
import paginateResults from "../../../utils/paginateResults";

const MyLocationList = ({ props, match }) => {
  const authContext = useContext(AuthContext);
  const { user, loadUser, loading, error } = authContext;

  // pagination state
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(null);
  const perPage = 15;

  // Update pagination results
  const updateResults = () => {
    paginateResults(user.locations, page, perPage, setResults);
  };

  // paginate page
  const paginate = (newPage) => {
    setPage(newPage);
  };

  // get current page & update
  useEffect(() => {
    updateResults();
    // eslint-disable-next-line
  }, [page, user.locations]);

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line
  }, [loading, error]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h2>My Locations</h2>
      </div>
      {user && user.locations && user.locations.length > 0 && !loading ? (
        <div className="table-responsive">
          <table className="table table-striped table-sm">
            <thead>
              <tr>
                <th>Name</th>
                <th>Location Identifier</th>
                <th>Time Zone</th>
                <th>Language</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            {results && !loading && (
              <tbody>
                {results.map((location) => (
                  <LocationListItem
                    match={match}
                    key={location.id}
                    location={{
                      ...location,
                      isActive: undefined,
                      isEnabled: undefined,
                    }}
                    user={user}
                    // setScreen={setScreen}
                    // handleLinkScreen={handleLinkScreen}
                    // handleShow={handleShow}
                  />
                ))}
              </tbody>
            )}
          </table>
          {!loading &&
            user &&
            user.locations &&
            user.locations.length > perPage && (
              <Pagination
                currentPage={page}
                perPage={perPage}
                total={user.locations.length}
                paginate={paginate}
              />
            )}
        </div>
      ) : (
        <div>
          {!user || !user.locations || loading ? (
            <Spinner />
          ) : (
            !loading &&
            results &&
            results.length < 1 && <p>No locations found</p>
          )}
        </div>
      )}
    </>
  );
};

export default MyLocationList;
