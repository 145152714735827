import React from "react";
import Modal from "react-bootstrap/esm/Modal";

const AddWorkoutRoutine = ({
  show,
  handleClose,
  routine,
  handleRoutine,
  addRoutine,
  updateRoutine,
  setAlert,
  newStep,
  routines,
}) => {
  const { text, videoLink, lrVideoLink, step } = routine;

  const onChange = (event) => {
    const current = routines.find((r) => r._id === routine._id);
    const updated = Number(routines.indexOf(current) + 1);
    handleRoutine({
      ...routine,
      [event.target.name]: event.target.value,
      step: !routine._id ? newStep : updated,
      name: !routine._id ? `ITEM ${newStep}` : `ITEM ${updated}`,
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (!routine._id) {
      addRoutine(routine);
      setAlert({
        message: `Added Routine: ${routine.name}`,
        type: "success",
      });
    } else {
      updateRoutine(routine);
      setAlert({
        message: `Updated Routine: ${routine.name}`,
        type: "success",
      });
    }

    handleClose();
  };
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{routine._id ? "Update" : "Add"} Routine</Modal.Title>
        </Modal.Header>
        <form onSubmit={onSubmit}>
          <Modal.Body>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              value={!routine._id ? `ITEM ${newStep}` : `ITEM ${step}`}
              onChange={onChange}
              className="form-control"
              required={true}
              disabled={true}
            />
            <br />
            <label htmlFor="text">
              Text
              <span style={{ fontStyle: "italic" }}>
                {routine && routine.step === 1 && routine.text.length <= 150
                  ? ` (remaining characters ${150 - routine.text.length})`
                  : routine && routine.step === 1 && routine.text.length >= 150
                  ? " (text will scroll vertical)"
                  : ""}
              </span>
            </label>
            <textarea
              rows={4}
              style={{ resize: "none" }}
              name="text"
              value={text || ""}
              onChange={onChange}
              className="form-control"
              required={true}
            ></textarea>
            <br />
            <label htmlFor="step">Step</label>
            <input
              type="number"
              name="step"
              max={3}
              min={1}
              value={!routine._id ? newStep : step}
              onChange={onChange}
              className="form-control"
              required={true}
              disabled={true}
            />
            <br />
            <label htmlFor="videolink">Video Link</label>
            <input
              type="text"
              name="videoLink"
              value={videoLink || ""}
              onChange={onChange}
              className="form-control"
              required={true}
            />
            <br />
            <label htmlFor="lrVideoLink">Locker Room Video Link</label>
            <input
              type="text"
              name="lrVideoLink"
              value={lrVideoLink || ""}
              onChange={onChange}
              className="form-control"
              required={false}
            />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={handleClose}>
              Close
            </button>
            <button className="btn btn-primary" type="submit">
              Save Changes
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddWorkoutRoutine;
