import React from "react";
import Modal, {
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "../../common/Modal";

const AddUser = ({
  userInput,
  show,
  handleUser,
  handleShow,
  addUser,
  updateUser,
  roleTypes,
}) => {
  const onChange = (event) => {
    handleUser({ ...userInput, [event.target.name]: event.target.value });
  };

  const onSubmitClick = (event) => {
    event.preventDefault();
    if (!userInput._id) {
      addUser(userInput);
    } else {
      updateUser(userInput);
    }
    handleShow();
  };

  return (
    <>
      {userInput && (
        <Modal show={show} onHide={handleShow}>
          <ModalHeader>
            <ModalTitle>{userInput._id ? "Update" : "Add"} User</ModalTitle>
          </ModalHeader>
          <form onSubmit={onSubmitClick}>
            <ModalBody>
              <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={userInput.firstName || ""}
                  onChange={onChange}
                  className="form-control"
                  required={true}
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={userInput.lastName || ""}
                  onChange={onChange}
                  className="form-control"
                  required={true}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  value={userInput.email || ""}
                  onChange={onChange}
                  className="form-control"
                  required={true}
                />
              </div>
              {!userInput._id && (
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    name="password"
                    value={userInput.password || ""}
                    onChange={onChange}
                    className="form-control"
                    required={true}
                  />
                </div>
              )}
              <div className="form-group">
                <label htmlFor="role">Role</label>
                <select
                  name="role"
                  className="form-control"
                  value={userInput.role || ""}
                  onChange={onChange}
                  required={true}
                >
                  <option>{userInput.role ? userInput.role : ""} </option>
                  {roleTypes
                    .filter((role) => role !== userInput.role)
                    .map((role, i) => (
                      <option key={i} value={role}>
                        {role}
                      </option>
                    ))}
                </select>
              </div>
            </ModalBody>
            <ModalFooter>
              <button type="submit" className="btn btn-primary">
                {userInput._id ? "Update User" : "Add User"}
              </button>
            </ModalFooter>
          </form>
        </Modal>
      )}
    </>
  );
};

export default AddUser;
