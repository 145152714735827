import React from "react";
import Modal from "react-bootstrap/Modal";

const AddRelease = ({
  show,
  handleClose,
  release,
  handleRelease,
  handleShow,
  addRelease,
  setAlert,
}) => {
  const onChange = (event) => {
    handleRelease({ ...release, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!release._id) {
      addRelease(release);
    } else {
      setAlert({
        type: "error",
        message:
          "Sorry, the release could not be created. A duplicate id has been found.",
      });
    }

    handleShow();
  };

  return (
    <>
      {release && (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Release</Modal.Title>
          </Modal.Header>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="form-group">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  name="title"
                  value={release.title || ""}
                  onChange={onChange}
                  className="form-control"
                  required={true}
                />
              </div>
              <div className="form-group">
                <label htmlFor="type">Type</label>
                <select
                  type="text"
                  name="type"
                  value={release.type || ""}
                  onChange={onChange}
                  className="form-control"
                  required={true}
                >
                  <option></option>
                  <option value="Release">Release</option>
                  <option value="Hot-Fix">Hot-Fix</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea
                  type="text"
                  name="description"
                  rows="4"
                  cols="50"
                  value={release.description || ""}
                  onChange={onChange}
                  className="form-control"
                  required={true}
                />
              </div>
              <div className="form-group">
                <label htmlFor="version">Version</label>
                <input
                  name="version"
                  onChange={onChange}
                  className="form-control"
                  placeholder="ex 00.00.00 or 00.00.00-000"
                  required={true}
                  value={release.version || ""}
                />
              </div>
              <div className="form-group">
                <label htmlFor="date">Date</label>
                <input
                  name="date"
                  onChange={onChange}
                  className="form-control"
                  placeholder="ex 01/01/2031"
                  required={true}
                  value={release.date || ""}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="submit" className="btn btn-primary">
                Add
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
              >
                Close
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </>
  );
};

export default AddRelease;
