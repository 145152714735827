/**
 * @function handleValidationError
 * @param {String} error
 * @param {Function} setAlert
 * @param {String} customMsg - The data being referenced or a custom message
 * @description Customize the Alert message with specific data types and error messages
 */
export const handleValidationError = (error, setAlert, customMsg) => {
  error
    ? setAlert({
        type: "error",
        message: `Sorry, there was a problem adding ${customMsg}: ${error}.`,
      })
    : setAlert({
        type: "error",
        message: `Sorry, there was a problem adding ${customMsg}. Please check the form data requirements and try again.`,
      });
};

/**
 * @function checkFlag
 * @param {Object} flag - { flagType: The type of alert to display, validated: The user input to customize message }
 * @param {Function} setAlert
 * @param {String} error
 * @description Check error/validation to determine which alert to display
 */
export const checkFlag = (flag, setAlert, error) => {
  const { validated, flagType } = flag;
  if (flagType === "addUser") {
    !error
      ? setAlert({
          type: "success",
          message: `Added User: ${validated.firstName} to the ${global.config.product.app.name} system.`,
        })
      : handleValidationError(error, setAlert, "this User");
  } else if (flagType === "addRelease") {
    !error
      ? setAlert({
          type: "success",
          message: `Added ${validated.type}: ${validated.title} to the ${global.config.product.app.name} system.`,
        })
      : handleValidationError(error, setAlert, "this Release/Hot-fix");
  } else if (flagType === "addLocation") {
    !error
      ? setAlert({
          type: "success",
          message: `Added Location: ${validated.name} to the ${global.config.product.app.name} system.`,
        })
      : handleValidationError(error, setAlert, "this Location");
  }
};
