import React, { useState, useEffect } from "react";
import ContentStatusItem from "./ContentStatusItem";
import Pagination from "../../common/Pagination";
import paginateResults from "../../../utils/paginateResults";

const ContentStatus = ({
  user,
  error,
  getWorkoutsByRoutines,
  currentContent,
  clearContent,
}) => {
  const [contentStatus, setContentStatus] = useState(null);
  const [checkPassed, setCheckPassed] = useState(false);
  // pagination state
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(null);
  const perPage = 5;

  useEffect(() => {
    getWorkoutsByRoutines();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentContent) {
      setContentStatus(currentContent);
    }
    // eslint-disable-next-line
  }, [currentContent]);

  // indicator that displays if Rounds have routines
  useEffect(() => {
    contentStatus && contentStatus.length < 1
      ? setCheckPassed(true)
      : setCheckPassed(false);
    // eslint-disable-next-line
  }, [contentStatus]);

  // cleanup
  useEffect(() => {
    return () => clearContent();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateResults();
    // eslint-disable-next-line
  }, [page, contentStatus]);

  const updateResults = () => {
    paginateResults(contentStatus, page, perPage, setResults);
  };

  const paginate = (newPage) => setPage(newPage);

  return (
    <div className="pb-5">
      <h6>
        <p>
          <span style={{ textDecoration: "underline" }}>Content Status</span>{" "}
          {checkPassed ? (
            <span>
              <i
                className="fa fa-check-circle text-success"
                title="All workouts have routines"
              ></i>
            </span>
          ) : (
            <>
              <span>
                <i
                  className="fas fa-times-circle text-danger"
                  title="Missing routines detected"
                ></i>
              </span>
            </>
          )}
        </p>
      </h6>
      <h6>
        {contentStatus && contentStatus.length > 0 && (
          <span>(Missing Routines: {contentStatus.length})</span>
        )}
      </h6>
      {!checkPassed && (
        <div className="table-responsive pt-1">
          <table className="table table-striped table-sm">
            <thead>
              <tr>
                <th>Round</th>
                <th>Name</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Routines</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {results &&
                results.map((status, i) => (
                  <ContentStatusItem status={status} key={i} />
                ))}
            </tbody>
          </table>
        </div>
      )}
      {!checkPassed && contentStatus && contentStatus.length > perPage && (
        <Pagination
          currentPage={page}
          perPage={perPage}
          total={contentStatus.length}
          paginate={paginate}
        />
      )}
    </div>
  );
};

export default ContentStatus;
