import React, { useContext, useEffect } from "react";
import AuthContext from "../../context/auth/AuthContext";
import { Route, Redirect } from "react-router-dom";
import Spinner from "../layout/Spinner";

const AdminRoute = ({ component: Component, ...rest }) => {
  const authContext = useContext(AuthContext);
  const { isAdmin, loading, loadUser } = authContext;

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line
  }, [isAdmin]);

  return (
    <Route
      {...rest}
      render={(props) =>
        loading ? (
          <Spinner />
        ) : !isAdmin ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default AdminRoute;
