import React from "react";
import TimedWorkout from "../../components/screen-page/new-workout/timed-workout/TimedWorkout";
import AndroidWorkout from "../../components/screen-page/new-workout/timed-workout/android/AndroidWorkout";
import ScreenRefresh from "../../components/screen-page/new-workout/timed-workout/ScreenRefresh";

const OperatingSystem = ({ screen }) => {
  return (
    <>
      {screen && screen.location && !screen.location.androidOs ? (
        <TimedWorkout />
      ) : screen && screen.location && screen.location.androidOs ? (
        <AndroidWorkout />
      ) : (
        <ScreenRefresh />
      )}
    </>
  );
};

export default OperatingSystem;
